import React, { useState, useContext, useEffect } from "react";
import SheetsApi from "../api";
import useFields from "../Hooks/useFields";
import UserContext from "../Users/UserContext";
import { useParams } from "react-router-dom";
import SheetTypeCard from "./SheetTypeCard";
import {
    Box, useTheme, Tooltip, IconButton, Card, CardContent,
    CardHeader, Collapse,
    Button,
} from '@mui/material';
import { useHistory } from "react-router-dom/";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useNotification from "../Hooks/useNotification";
import AddIcon from '@mui/icons-material/Add';
import AddCustomerPopUp from "./AddCustomerPopUp";
import SearchableInput from '../Sheets/SheetComponents/SearchableInput';
import SheetsFilter from "./SheetsFilter";
import AddLocationPopUp from "./AddLocationPopUp";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useFilterStore from "../stores/filterStore";
import CustomerSettings from "./CustomerSettings";

function Customers() {
    const theme = useTheme()
    const background = theme.palette.background.main;
    const defaultColor = theme.palette.default.main;
    const history = useHistory();
    const messanger = useNotification();
    const { currentUser } = useContext(UserContext)
    const { company } = useParams()
    const [watchCustomer, setWatchCustomer] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [customers, setCustomers] = useState([])
    const [users, setUsers] = useState([])
    const [openCustomer, setOpenCustomer] = useState(false)
    const [openLocation, setOpenLocation] = useState(false)
    const [locations, setLocations] = useState([]);
    const [showCustomerSettings, setShowCustomerSettings] = useState(false)
    const [locationData, setLocationData, resetLocationdata] = useFields({})
    const [render, setRender] = useState(false)
    const [locationRender, setLocationRender] = useState(false);
    const [customer, setCustomer] = useFields({ customer: "" })
    const [filteredSheets, setFilteredSheets] = useState({});
    const [filter, setFilter] = useFilterStore(state =>
        [state.filter, state.setFilter]);

    useEffect(() => {
        async function getData() {
            const usersResult = await SheetsApi.getInactive()
            const customersResult = await SheetsApi.getAllCustomersByCompany({ companyName: company })
            let newUserArray = []
            if (currentUser.account_level === 'admin') newUserArray = usersResult
            else { // Filter by company
                newUserArray = usersResult.filter((user) => currentUser.company === user.company)
            }
            setUsers(newUserArray)
            setCustomers(customersResult)
        }
        getData();
        if (filter.customer) setCustomer("invalidInput", "customer", filter.customer)
        if (filter.location_name) setLocationData("invalidInput", "location_name", filter.location_name)
    }, []);

    useEffect(() => {
        if (customer.customer) setFilter("customer", customer.customer)
        if (locationData.location_name) setFilter("location_name", locationData.location_name)

    }, [customer, locationData])

    useEffect(() => {
        resetLocationdata()
        if (customer.customer.length > 0) {
            const getLocations = async function () {
                const result = await SheetsApi.getAllLocations({ customerName: customer.customer })
                setLocations(result)
            }
            getLocations()
            setCollapse(true)
        } else setCollapse(false)
    }, [customer]);

    useEffect(() => {
        if (locationData.id) setLocationRender(true);
    }, [locationData])

    const renderSheetCard = function (data) {
        setFilteredSheets(data)
        setRender(true);
    }

    const handleLocationAdd = function () {
        if (customer.customer !== "") return setOpenLocation(true)
        else return messanger("You must select a customer before adding a site.", "warning")
    }

    const updateWatchListHere = async () => {
        const response = await SheetsApi.updateWatchList({
            customer: customer.customer,
            user: currentUser.username,
            watch: watchCustomer,
        })
        setWatchCustomer(!watchCustomer)
        return messanger(response.message, response.error ? 'error' : 'success')
    }

    const locationSettingsRedirect = function (id) {
        history.push(`/locationSettings/${id}/${customer.customer}`)
    }
    const assessmentRedirect = function (id) {
        history.push(`/storageAssessment/${id}/${customer.customer}/${locationData.location_name}`)
    }

    return (<>
        <Card sx={{ width: '100%', bgcolor: background, paddingBottom: 2 }}>
            <Box display="flex">
                <Box ml={2} width='50%'>
                    <Box display="flex" justifyContent="space-between">
                        <CardHeader title="Customers" />
                        <Box mt={1}>
                            {customer.customer &&
                                <Tooltip title={!watchCustomer ? 'Notifications are turned off for this customer' :
                                    'You will be notified in the service viewer when any new sheets are created for this customer.'}>
                                    <IconButton
                                        id="watchCustomer"
                                        name="watchCustomer"
                                        onClick={updateWatchListHere}
                                        sx={{
                                            maxHeight: 37,
                                            mr: 2, ml: 1, mt: 1,
                                            backgroundColor: defaultColor,
                                            color: background,
                                            '&:hover': {
                                                backgroundColor: background,
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        {!watchCustomer ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title={'Add a new customer'}>
                                <IconButton onClick={() => setOpenCustomer(true)} sx={{ mr: 1.5 }}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ width: '98%' }}>
                        <SearchableInput
                            edit={true}
                            editData={filter.customer}
                            setState={setCustomer}
                            searchableArray={customers}
                            label="Search for a Customer"
                            variableName="customer"
                            fullwidth={true}
                        />
                    </Box>
                </Box>
                <Box width='50%'>
                    <Box sx={{ width: '96%' }}>
                        <Box display="flex" justifyContent="space-between">
                            <CardHeader title="Sites" />
                            <Tooltip title={'Add a new site'}>
                                <IconButton onClick={handleLocationAdd}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <SearchableInput
                            edit={true}
                            editData={filter.location_name}
                            setState={setLocationData}
                            searchableArray={locations}
                            label="Search for a Site"
                            variableName='location_name'
                            fullwidth={true}
                        />
                    </Box>
                </Box>
            </Box>
            <Box display="flex" justifyContent="left">
                <Collapse in={collapse} sx={{ flexBasis: '100%' }}>
                    <Box display="flex">
                        <CardHeader title="Customer Settings" />
                        <IconButton onClick={() => setShowCustomerSettings(!showCustomerSettings)}>
                            {!showCustomerSettings ?
                                <ArrowDropDownIcon style={{ fontSize: '2rem' }} /> :
                                <ArrowDropUpIcon style={{ fontSize: '2rem' }} />
                            }
                        </IconButton>
                    </Box>
                    <Collapse in={showCustomerSettings}>
                        <Box display="flex">
                            {showCustomerSettings &&
                                <CustomerSettings customer={customer} />
                            }
                        </Box>
                    </Collapse>
                </Collapse>
                <Collapse in={locationRender} sx={{ flexBasis: '100%' }}>
                    {locationData.id && <>
                        <Box m={2} justifyContent="space-between" display="flex">
                            <Button onClick={() => locationSettingsRedirect(locationData.id)}>Site settings</Button>
                            <Button onClick={() => assessmentRedirect(locationData.id)}>Assessment details</Button>
                        </Box>
                    </>}
                </Collapse>
            </Box>
            <Collapse in={collapse}>
                <CardContent>
                    <SheetsFilter
                        customer={customer.customer}
                        users={users}
                        locations={locations}
                        setFilteredSheets={renderSheetCard}
                        setRender={setRender}
                        locationData={locationData}
                    />
                </CardContent>
            </Collapse>
        </Card >
        <AddCustomerPopUp
            open={openCustomer}
            setOpen={setOpenCustomer}
            companyName={company}
        />
        <AddLocationPopUp
            open={openLocation}
            setOpen={setOpenLocation}
            customerName={customer.customer}
            locations={locations}
            setLocations={setLocations}
        />
        <Collapse in={render}>
            {render && Object.keys(filteredSheets).map((sheetType) => {
                return <SheetTypeCard
                    key={sheetType}
                    filteredSheets={filteredSheets}
                    sheetType={sheetType}
                />
            })}
        </Collapse>
    </>)
}

export default Customers