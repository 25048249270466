import React, { useEffect, useState } from "react";
import { Box, Grid } from '@mui/material';
import useFields from "../../Hooks/useFields";
import useStorageAssessmentStore from "../../stores/storageAssessmentStore";
import StorageAssessAirflow from "./StorageAssessAirflow";
import StorageAssessRefrigeration from "./StorageAssessRefrigeration";

function StorageAssessFoot({ edit }) {
    const [totals, storageAssessmentHead, refrigeration, setObj, getVarianceColor] = useStorageAssessmentStore(state =>
        [state.totals, state.storageAssessmentHead, state.refrigeration, state.setObj, state.getVarianceColor]);
    const [pageData, setPageData] = useState({});
    const [formData, handleLocal] = useFields({ ratio: 100, currRefTonage: 0 })

    const calculateDuctReq = function () {
        const ductSize = storageAssessmentHead.ductSize || 0
        let multiplier = 0;
        if (ductSize === 15) multiplier = 1.23
        else if (ductSize === 18) multiplier = 1.76
        else if (ductSize === 21) multiplier = 2.41
        else if (ductSize === 24) multiplier = 3.16
        return parseFloat((storageAssessmentHead.numberOfDucts * multiplier).toFixed(1));
    }

    const calculateRefSqFt = function () {
        let retunValue = 0;
        refrigeration.forEach((refrigeration) => {
            retunValue += ((refrigeration.height * refrigeration.width) / 144) * refrigeration.qty;
        });
        return parseFloat(retunValue.toFixed(1));
    }

    useEffect(() => {
        setPageData({});
        if (edit) handleLocal("replaceObj",
            {
                ratio: storageAssessmentHead.ratio,
                currRefTonage: storageAssessmentHead.currRefTonage
            }
        )
    }, []);

    useEffect(() => {
        setObj(formData, "storageAssessmentHead");
    }, [formData])

    useEffect(() => {
        const ductReq = calculateDuctReq();
        const refTotalSqFt = calculateRefSqFt();
        const ratioValue = parseFloat((storageAssessmentHead.tons / formData.ratio).toFixed(1))
        const totalSqFtReq = parseFloat((totals.totalCfm / 1000).toFixed(1))
        const plenumVariance = parseFloat((((storageAssessmentHead.plenumSqFt / totalSqFtReq) - 1) * 100).toFixed(1));
        const ductVariance = parseFloat((((ductReq / totalSqFtReq) - 1) * 100).toFixed(1));
        const refVariance = parseFloat((((formData.currRefTonage / ratioValue) - 1) * 100).toFixed(1));
        setObj({
            plenumVariance: plenumVariance,
            ductVariance: ductVariance,
            refVariance: refVariance
        }, "varianceTotals")
        setPageData({
            totalSqFtReq: totalSqFtReq,
            plenTotalSqFt: storageAssessmentHead.plenumSqFt,
            plenVariance: plenumVariance,
            ductTotalSqFt: ductReq,
            ductVariance: ductVariance,
            refTotalSqFtReq: parseFloat((totals.totalCfm / 650).toFixed(1)),
            ratioValue: ratioValue,
            refVariance: refVariance,
            refTotalSqFt: refTotalSqFt,
            maxFanSpeed: parseFloat((((refTotalSqFt * 650) / totals.totalCfm) * 100).toFixed(1))
        });
    }, [formData, totals, storageAssessmentHead.ductSize, storageAssessmentHead.plenumSqFt,
        storageAssessmentHead.tons, storageAssessmentHead.cwt]);

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <StorageAssessAirflow pageData={pageData} getVarianceColor={getVarianceColor} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StorageAssessRefrigeration
                        pageData={pageData}
                        formData={formData}
                        handleLocal={handleLocal}
                        getVarianceColor={getVarianceColor}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default StorageAssessFoot;