import React from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";

const CustomCheckbox = ({ label, name, checked, onChange, handleLocal }) => (
    <Box ml={2} style={{ display: 'inline-block' }}>
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    onChange={(e) => {
                        handleLocal(e)
                        onChange(e, true)
                    }}
                    name={name}
                    checked={checked || false}
                />
            }
        />
    </Box>
);

export default CustomCheckbox;