import React, { useState, useEffect, useContext } from "react";
import { Card, CardContent, CardHeader, Box, useTheme } from '@mui/material';
import LocationSettingsCard from './LocationSettingsCard';
import LocationCropCard from './LocationCropCard';
import LocationMerge from "./LocationMerge";
import UserContext from "../../Users/UserContext";
import { useParams } from "react-router-dom";
import SheetsApi from "../../api";

function LocationSettings() {
    const { id, customer } = useParams();
    const { currentUser } = useContext(UserContext);
    const theme = useTheme();
    const background = theme.palette.background.default;
    const redTheme = theme.palette.primary.main;
    const [locations, setLocations] = useState([]);
    const [locationData, setLocationData] = useState({});

    useEffect(() => {
        const getLocations = async function (customer) {
            return await SheetsApi.getAllLocations({ customerName: customer })
        }
        getLocations(customer).then(data => {
            setLocations(data)
            const thisLocation = data.find(location => location.id === Number(id))
            setLocationData(thisLocation)
        })
    }, []);

    return (
        <Card sx={{ width: '100%', bgcolor: background, paddingBottom: 2 }}>
            <CardHeader
                sx={{
                    bgcolor: redTheme,
                    color: 'primary.contrastText',
                    p: 2,
                }}
                title="Storage Settings"
            />
            <CardContent>
                <Box m={2} display={currentUser.mobile ? "block" : "flex"} justifyContent={currentUser.mobile ? "" : `space-between`}>
                    <LocationSettingsCard locationData={locationData} />
                    {locations.length > 0 &&
                        <LocationMerge locationData={locationData} locations={locations} id />
                    }
                </Box>
                <LocationCropCard />
            </CardContent>
        </Card>
    )
}

export default LocationSettings;