/**
 * Checks if a user has permissions to access a sheet in a specific stage
 * @param {string} userAccountLevel - User's account level (Technician, Admin, etc.)
 * @param {string} dept - User's department
 * @param {string} stage - Current stage of the sheet
 * @returns {Object} Permission result containing status and message
 */
export const checkPermissions = function (userAccountLevel, dept, stage) {
    const success = { message: "Permission granted", status: true };
    const denied = { message: "Permission denied.", status: false };
    const error = { message: "Invalid parameters provided", status: false };
    if (!userAccountLevel || !stage) return error;
    if (userAccountLevel === "Technician") {
        if (stage === "Preliminary Review" || stage === "Signature Pending") return success;
        else return denied;
    }
    if (userAccountLevel === 'Manager') {
        if (stage === "Ready for Invoicing") return denied;
        else return success;
    }
    if (stage === "Archive") {
        if (userAccountLevel !== "Admin" && dept !== "admin") return denied;
        else return success;
    } else return success;
};