import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    useTheme, Card, CardContent, CardHeader, Box, Fab,
} from '@mui/material';
import SheetsApi from '../../api';
import StorageAssessmentHead from './storageAssessmentHead';
import StorageAssessParts from './StorageAssessParts';
import StorageAssessFoot from './StorageAssessFoot';
import useStorageAssessmentStore from '../../stores/storageAssessmentStore';
import useNotification from '../../Hooks/useNotification';
import StorageAssessPrint from './StorageAssessPrint/StorageAssessPrint';
import UserContext from '../../Users/UserContext';

function StorageAssessmentBase() {
    const { id, customer, location } = useParams()
    const theme = useTheme()
    const background = theme.palette.background.main;
    const redTheme = theme.palette.primary.main;
    const messanger = useNotification()
    const { currentUser } = useContext(UserContext);
    const [locationData, setLocationData] = useState({});
    const [render, setRender] = useState(false);
    const [edit, setEdit] = useState(false);
    const [storageAssessmentHead, fan, motor, freqDrive, fad, returnAirOpening, actuator, climaCell, humidifier,
        damper, lightBlock, control, refrigeration, climaCellBase, setObj, setAllParts, replacePartArray,
        clearState, storageSettings, varianceTotals] = useStorageAssessmentStore(state => [state.storageAssessmentHead,
        state.fan, state.motor, state.freqDrive, state.fad, state.returnAirOpening, state.actuator, state.climaCell,
        state.humidifier, state.damper, state.lightBlock, state.control, state.refrigeration, state.climaCellBase,
        state.setObj, state.setAllParts, state.replacePartArray, state.clearState, state.storageSettings,
        state.varianceTotals]);
    const partsRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const lat = !storageSettings.lat ? 0 : parseFloat(storageSettings.lat);
        const long = !storageSettings.long ? 0 : parseFloat(storageSettings.long);
        if (Number.isNaN(lat) || Number.isNaN(long)) {
            return messanger("Please provide valid latitude and longitude values", "warning");
        } else if (lat < -90 || lat > 90) {
            return messanger("Latitude must be between -90 and 90 degrees", "warning");
        } else if (long < -180 || long > 180) {
            return messanger("Longitude must be between -180 and 180 degrees", "error");
        }
        try {
            const allPartsObj = {}
            const changes = partsRef.current?.getChanges();
            if (changes) {
                for (const partType in changes) {
                    replacePartArray(partType, changes[partType]);
                    allPartsObj[partType] = changes[partType];
                }
            }

            const saveObj = {
                locationId: id,
                ...storageSettings,
                ...storageAssessmentHead,
                ...allPartsObj,
                climaCellBase: climaCellBase,
                fan: fan,
                fad: fad,
                returnAirOpening: returnAirOpening,
                damper: damper,  // Use damper directly from state
                lightBlock: lightBlock,
                refrigeration: refrigeration,
            }

            console.log('Saving assessment data:', saveObj);
            const result = await SheetsApi.saveLocationSettings(saveObj)
            messanger(result.message, result.error ? "error" : "success")
        } catch (error) {
            console.error('Error in handleSave:', error);
        }
    };

    useEffect(() => {
        clearState()
        const getLocationData = async function () {
            return await SheetsApi.getLocationData({ locationId: id })
        }
        getLocationData().then((locationData) => {
            console.log(locationData)
            if (locationData.locationData) {
                setLocationData(locationData.locationData)
                setObj({
                    climaCellBaseDepth: locationData.assessmentBase.climaCellBaseDepth,
                    climaCellBaseEdgeCoat: locationData.assessmentBase.climaCellBaseEdgeCoat,
                    climaCellBaseHeight: locationData.assessmentBase.climaCellBaseHeight,
                    climaCellBaseQty: locationData.assessmentBase.climaCellBaseQty,
                    climaCellBaseWidth: locationData.assessmentBase.climaCellBaseWidth,
                }, "climaCellBase")
                setAllParts(locationData)
                setEdit(true)
                setRender(true)
            } else {
                setLocationData(locationData)
                setRender(true)
            }
        })
    }, [])

    return (<> {render && <>
        <Card sx={{ width: '100%', bgcolor: background, paddingBottom: 2 }}>
            <CardHeader
                sx={{
                    bgcolor: redTheme,
                    color: 'white',
                    p: 2,
                }}
                title="Storage Assessment"
            />
            <CardContent>
                <StorageAssessmentHead edit={edit} />
                <StorageAssessParts edit={edit} ref={partsRef} />
                <StorageAssessFoot edit={edit} locationData={locationData} />
            </CardContent>
        </Card>
        {currentUser && <StorageAssessPrint 
            location={location}
            customer={customer}
        />}
        <Fab
            color="primary"
            variant="extended"
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 175,
            }}
            onClick={handleSubmit}
        >
            Save
        </Fab>
    </>} </>)
}

export default StorageAssessmentBase;