import React, { useEffect, useState, useContext } from "react";
import SheetsApi from "../../api";
import StageCard from "../StageCard";
import UserContext from "../../Users/UserContext";
import {
    Card, CardContent, Box, Typography, Collapse, IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeptMenuCard from "./DeptMenuCard";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import dayjs from "dayjs";
import useNotification from "../../Hooks/useNotification";

function DeptComponent({ dept, company }) {
    const messanger = useNotification();
    const { currentUser } = useContext(UserContext);
    const companyPass = company;
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const [stages, setStages] = useState({})
    const [multipleSelectToggle, setMultipleSelectToggle] = useState(false);
    const [selectArray, setSelectArray] = useState([]);
    const [optionMenuToggle, setOptionMenuToggle] = useState(false);


    useEffect(() => {
        let sheetType = ['sheets', 'inspections', 'salesorders', 'refrigeration', 'preseason',
            'chemAppAgreement', 'disinfectantAgreement', 'sprayBarAgreement', 'freshPack',
            'chemAppService',];
        const getSheets = async function () {
            // Gets all active sheets (not in archive)
            return await SheetsApi.getSheets({ company, sheetType, dept })
        }
        getSheets().then((sheets) => {
            Object.keys(sheets).forEach(stage => {
                sheets[stage] = sheets[stage].map(sheet => ({
                    ...sheet,
                    dateServiced: dayjs(sheet.dateServiced).format('MM-DD-YYYY')
                }));
            });

            if (dept === 'sales' && currentUser.dept === 'sales') {
                // If not admin, filter all of the sheets by currentUser so that the sales can only see thier own sheets.
                const sheetsByUser = [];
                for (let stage in sheets) {
                    sheetsByUser[stage] = [];
                    sheets[stage].forEach((sheet) => {
                        if (sheet.creator === currentUser.username) {
                            sheetsByUser[stage].push(sheet);
                        }
                    })
                }
                setStages(sheetsByUser);
            } else setStages(sheets)
        })
    }, [company]);

    function removeSpaces(str) {
        //this function needs to the same as the one in settingsStore / createSettings()
        return str.replace(/[\s-']/g, '');
    };

    function camelCaseToTitle(string) {
        if (!string) return ""
        if (string === 'labs') return 'Storage Management';
        if (string === 'chemApps') return 'Custom Apps'
        const words = string.split(/(?=[A-Z])/);
        const readableString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return readableString;
    };

    function cardSelectManager(selectedCardId, selectState) {
        // Keeps track of which cards are selected
        if (selectState) {
            // Add to select aray
            setSelectArray([...selectArray, selectedCardId])
        } else if (!selectState) {
            // Remove from select array
            setSelectArray(selectArray.filter(id => id !== selectedCardId));
        }
    }

    const handleSelectMultipleSubmit = async function (e, stageSelection) {
        e.preventDefault();
        let formattedStage = removeSpaces(stageSelection);
        if (formattedStage === "") {
            return messanger("Please select a stage", "warning");
        }
        const targetStage = formattedStage[0].toLowerCase() + formattedStage.slice(1);
        // Find all selected cards across all stages
        const selectedCards = [];
        const updatedStages = { ...stages };

        // Loop through all stages to find matching cards
        Object.keys(stages).forEach(stageName => {
            // Find cards that match selectArray IDs
            const matchingCards = stages[stageName].filter(card =>
                selectArray.includes(card.cardId)
            );

            if (matchingCards.length > 0) {
                // Add matching cards to selected array
                selectedCards.push(...matchingCards);
                // Remove matching cards from their current stage
                updatedStages[stageName] = stages[stageName].filter(card =>
                    !selectArray.includes(card.cardId)
                );
            }
        });

        // Update stage property of selected cards
        const cardsWithUpdatedStage = selectedCards.map(card => ({
            ...card,
            stage: stageSelection.replace(/ For /g, ' for '), //Changed for proper storage in the DB.
            actionUser: currentUser.username
        }));

        // Add cards to target stage
        if (targetStage !== 'archive') {
            if (!updatedStages[targetStage]) {
                updatedStages[targetStage] = [];
            }
            updatedStages[targetStage].push(...cardsWithUpdatedStage);
        }
        setOptionMenuToggle(false);
        setMultipleSelectToggle(false);
        const response = await SheetsApi.batchUpdateStage(cardsWithUpdatedStage);
        setStages(updatedStages);
        return messanger(response.message, response.error ? "error" : "success");
    }

    return (
        <>
            <Box m={1} sx={{ minWidth: 275 }}>
                <Card sx={{ bgcolor: primary }} elevation={12}>
                    <React.Fragment>
                        <CardContent>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Box flex={1} />
                                <Typography
                                    variant="h3"
                                    component="div"
                                    style={{ color: defaultColor, textAlign: 'center' }}
                                >
                                    {camelCaseToTitle(dept)}
                                </Typography>
                                <Box flex={1} mr={2} mb={1} display="flex" justifyContent="flex-end">
                                    {currentUser.account_level !== "Technician" &&
                                        <IconButton
                                            selected={multipleSelectToggle}
                                            onClick={() => setOptionMenuToggle(!optionMenuToggle)}
                                        >
                                            {!optionMenuToggle ?
                                                <KeyboardDoubleArrowLeftIcon /> :
                                                <KeyboardDoubleArrowRightIcon />
                                            }
                                        </IconButton>
                                    }
                                </Box>
                            </Box>
                            <Box display="flex" width="100%">
                                <Box flex={7} pr={2}>
                                    {Object.keys(stages).length > 0 && Object.keys(stages).map((stageName) => {
                                        return <StageCard
                                            key={removeSpaces(stageName)}
                                            cardSelectManager={cardSelectManager}
                                            multipleSelectToggle={multipleSelectToggle}
                                            stageName={stageName}
                                            companyCode={removeSpaces(company)}
                                            toggleId={removeSpaces(stageName + dept)}
                                            stages={stages}
                                            setStages={setStages}
                                            stage={camelCaseToTitle(stageName)}
                                            company={companyPass}
                                        />
                                    })}
                                </Box>
                                <Collapse in={optionMenuToggle} orientation="horizontal">
                                    <DeptMenuCard
                                        handleSubmit={handleSelectMultipleSubmit}
                                        camelCaseToTitle={camelCaseToTitle}
                                        stages={stages}
                                        multipleSelectToggle={multipleSelectToggle}
                                        setMultipleSelectToggle={setMultipleSelectToggle}
                                    />
                                </Collapse>
                            </Box>
                        </CardContent>
                    </React.Fragment>
                </Card>
            </Box >
        </>

    )
}

export default DeptComponent;