import { create } from "zustand"
import SheetsApi from "../api";
import { v4 as uuid } from "uuid";
const useSettingsStore = create((set) => ({

    toggleSettings: false,
    notifications: [],
    home: "/",
    sortOrder: 'dateServiced',

    setSortOrder: function (sortValue) {
        set(prevState => ({
            ...prevState,
            sortOrder: sortValue,
        }))
    },

    createSettings: async function (user) {

        function removeSpaces(str) {
            //this needs to be the same as the remove spaceces funcion bleow.
            return str.replace(/[\s-']/g, '');
        }

        async function getAllCompanies() {
            try {
                const companies = await SheetsApi.getAllCompanies();
                return companies.companies;
            } catch (err) {
                console.log(err);
            }
        }

        async function asignSettings() {
            let toggleSettings;
            if (user.account_level === 'Admin') {
                const companies = await getAllCompanies();
                const updatedToggleSettings = {};

                companies.forEach((company) => {
                    const companyName = removeSpaces(company.name);
                    const companySettings = {
                        premReviewHome: false,
                        readyReviewHome: false,
                        readyInvoicingHome: false,
                    };
                    updatedToggleSettings[companyName] = companySettings;
                });

                toggleSettings = { ...toggleSettings, ...updatedToggleSettings };
            } else {
                const companyName = removeSpaces(user.company);
                const companySettings = {
                    [`premReviewHome`]: false,
                    [`readyReviewHome`]: false,
                    [`readyInvoicingHome`]: false,
                };
                toggleSettings = {
                    ...toggleSettings,
                    [companyName]: companySettings,
                    companyName: companyName
                };
            }
            return toggleSettings;
        }

        const toggleSettings = await asignSettings();
        set((prevState) => ({
            ...prevState,
            toggleSettings: toggleSettings,
        }));
    },

    setToggle: function (company, toggleId) {
        set((prevState) => {
            let toggleSettings = prevState.toggleSettings;
            const value = toggleSettings[company][toggleId];
            toggleSettings = {
                ...toggleSettings,
                [company]: {
                    ...toggleSettings[company],
                    [toggleId]: !value,
                },
            };
            return { ...prevState, toggleSettings }
        })
    },

    getNotifications: async function (currentUser) {
        const userData = {
            user: currentUser.username,
            accountLevel: currentUser.account_level,
            company: currentUser.company,
            dept: currentUser.dept,
        };
        const result = await SheetsApi.getNotifications(userData);
        return result.map((notification) => ({
            ...notification,
            keyId: uuid(),
        }));
    },

    removeSpaces: function (str) {
        //this function needs to the same as the one in the createSettings function above
        return str.replace(/[\s-']/g, '');
    },

    setNotifications: function (array) {
        set((prevState) => ({
            ...prevState,
            notifications: array,
        }))
    },

    removeNotification: function (id) {
        set((prevState) => {
            let notifications = [...prevState.notifications];
            notifications.splice(id, 1);
            return { ...prevState, notifications }
        })
    },

    setHome: function (newHome) {
        //Every time the user changes pages we check to see which home page they are using and set their home accordingly.
        //This is primarily for Admins since they toggle between different company home pages.
        //See routes.js for more details.
        set((prevState) => ({
            ...prevState,
            home: newHome
        }))
    }

}))

export default useSettingsStore;