import React, { useEffect } from "react";
import {
    FormControl, InputLabel, TextField,
    Select, MenuItem, useTheme, Card,
    CardContent, Button, CardActions
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import useNotification from "../../Hooks/useNotification";
import SheetsApi from "../../api";

function LocationSettingsCard({ locationData }) {
    const theme = useTheme();
    const inputBackground = theme.palette.default.secondary;
    const cardBackground = theme.palette.background.default;
    const mainColor = theme.palette.primary.main;
    const messanger = useNotification();
    const [formData, handleLocal] = useFields({
        site_name: "",
        lat: "",
        long: "",
        inactive: false,
    });

    const handleSubmit = async () => {
        const result = await SheetsApi.updateBasicSettings(formData)
        return messanger(result.message, result.error ? "error" : "success")
    }

    useEffect(() => {
        handleLocal("replaceObj", locationData)
    }, [locationData])

    return (<Card sx={{ bgcolor: cardBackground }}>
        <CardContent>
            <FormControl fullWidth margin="dense">
                <TextField
                    id="location_name"
                    name="location_name"
                    label="Site Name"
                    value={formData.location_name || ""}
                    onChange={handleLocal}
                    size="small"
                    InputLabelProps={{ style: { color: mainColor } }}
                    sx={{ bgcolor: inputBackground, color: mainColor }}
                />
            </FormControl>
            <FormControl fullWidth margin="dense">
                <TextField
                    id="lat"
                    name="lat"
                    label="Latitude"
                    value={formData.lat || ""}
                    onChange={handleLocal}
                    size="small"
                    InputLabelProps={{ style: { color: mainColor } }}
                    sx={{ bgcolor: inputBackground, color: mainColor }}
                />
            </FormControl>
            <FormControl fullWidth margin="dense">
                <TextField
                    id="long"
                    name="long"
                    label="Longitude"
                    value={formData.long || ""}
                    onChange={handleLocal}
                    size="small"
                    InputLabelProps={{ style: { color: mainColor } }}
                    sx={{ bgcolor: inputBackground, color: mainColor }}
                />
            </FormControl>
            <FormControl fullWidth margin="dense">
                <InputLabel sx={{ color: mainColor }}>Status</InputLabel>
                <Select
                    id="inactive"
                    name="inactive"
                    value={formData.inactive || false}
                    onChange={handleLocal}
                    size="small"
                    sx={{ bgcolor: inputBackground }}
                >
                    <MenuItem value={false}>Active</MenuItem>
                    <MenuItem value={true}>Inactive</MenuItem>
                </Select>
            </FormControl>
            <CardActions>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Save
                </Button>
            </CardActions>
        </CardContent>
    </Card>)
}

export default LocationSettingsCard;