import React, { useState, useEffect } from "react";
import {
    CardContent, Card, Box, Typography,
    ToggleButton, ToggleButtonGroup, Divider,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SheetCard2 from "./SheetCard2";
import useSettingsStore from "../stores/settingsStore";
import SheetCardSorting from "./SheetCardSorting";

function StageCard({ cardSelectManager, multipleSelectToggle, companyCode,
    toggleId, stages, setStages, stageName, stage }) {
    const togglePass = multipleSelectToggle;
    const theme = useTheme()
    const background = theme.palette.background.main
    const defaultColor = theme.palette.default.main
    const [mobile, setMobile] = useState(false);
    const [toggleSettings, setToggle] = useSettingsStore(state =>
        [state.toggleSettings, state.setToggle]);

    useEffect(() => {
        if (window.innerWidth < 500) setMobile(true)
        else setMobile(false)
    }, []);

    const setSortOrder = (sortOrderValue) => {
        //Set the order of the sheets according to selected filter.
        //Only sets order of sheets within specific stage.
        function sortArrayByKey(array, key) {
            return array.sort((a, b) => {
                // Handle dateServiced sorting
                if (key === 'dateServiced') {
                    return new Date(a[key]) - new Date(b[key]);
                }
                // Handle sheet_type or sheettype sorting
                if (key === 'sheet_type') {
                    const keyA = a.sheet_type ?? a.sheettype;
                    const keyB = b.sheet_type ?? b.sheettype;
                    if (keyA && keyB) return keyA.localeCompare(keyB);
                }
                // Default alphabetical sorting
                return a[key].localeCompare(b[key]);
            });
        }

        const sortedArray = sortArrayByKey(stages[stageName], sortOrderValue);
        setStages({ ...stages, [stageName]: sortedArray })
    };

    return (
        <>
            <Box mt={1} mb={1} sx={{ minWidth: '100%' }}>
                <Card
                    elevation={10}
                    sx={{
                        bgcolor: defaultColor,
                        borderRadius: '8px',
                        textAlign: 'center'
                    }}>
                    <CardContent>
                        <Box onClick={() => setToggle(companyCode, toggleId)} display="flex" alignItems="center">
                            <ToggleButtonGroup>
                                <ToggleButton
                                    value={toggleSettings[companyCode][toggleId] || false}>
                                    {toggleSettings[companyCode][toggleId] ? <MenuOpenOutlinedIcon
                                        sx={{ fontSize: 24, color: background }}
                                    /> : <MenuIcon
                                        sx={{ fontSize: 24, color: background }} />
                                    }
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Box ml={3} style={{ flex: 1 }}>
                                <Typography
                                    style={{ textAlign: 'left', color: background }}
                                    variant="h5">
                                    {stage}
                                </Typography>
                            </Box>
                            <Box display="flex">
                                <Divider color={background} orientation="vertical" flexItem />
                                <Typography
                                    sx={{ ml: 2, color: background }}
                                    variant="h5">
                                    Total: {stages[stageName].length}
                                </Typography>
                            </Box>
                        </Box>
                        {toggleSettings[companyCode][toggleId] && <>
                            {!mobile && <SheetCardSorting mobile={mobile} setSortOrder={setSortOrder} />}
                            <Box mt={2}>
                                {stages[stageName].length > 0 ? (
                                    stages[stageName].map((sheet) => {
                                        let type = sheet.sheettype;
                                        if (sheet.sheet_type) type = sheet.sheet_type
                                        return <SheetCard2
                                            cardSelectManager={cardSelectManager}
                                            multipleSelectToggle={togglePass}
                                            mobile={mobile}
                                            key={`${type}_${sheet.id}`}
                                            sheet={sheet}
                                        />
                                    })
                                ) : (
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <Typography variant="body1" color={background}>
                                            No cards available...
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </>}
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default StageCard;
