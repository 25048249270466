import React, { useState, useContext, useEffect } from 'react';
import {
    Card, CardContent, Box, Typography, ToggleButton,
    FormControl, InputLabel, Select, MenuItem,
    Button,
} from '@mui/material';
import { useTheme } from "@mui/material/styles"
import Usercontext from '../../Users/UserContext';

function DeptMenuCard({ multipleSelectToggle, setMultipleSelectToggle, stages,
    camelCaseToTitle, handleSubmit, }) {
    const { currentUser } = useContext(Usercontext)
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const background = theme.palette.background.main
    const [stageSelection, setStageSeletion] = useState('')

    useEffect(() => {
        const stageNames = Object.keys(stages);
        if (stageNames.length > 0) {
            setStageSeletion(camelCaseToTitle(stageNames[0]));
        }
    }, [stages]);

    return (
        <Card sx={{ mt: 1, maxHeight: 400, width: 300, bgcolor: background, height: '100%' }}>
            <CardContent>
                <Box display='flex' justifyContent='center'>
                    <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                        Options
                    </Typography>
                </Box>
                <Box display='flex' justifyContent='center'>
                    <ToggleButton
                        value="check"
                        selected={multipleSelectToggle}
                        onChange={() => setMultipleSelectToggle(!multipleSelectToggle)}
                        sx={{
                            width: '100%',
                            color: background,
                            backgroundColor: defaultColor,
                            border: `1px solid ${defaultColor}`, '&:hover': {
                                backgroundColor: '#4A4D50',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#3A7CA5',
                                color: defaultColor,
                                '&:hover': {
                                    backgroundColor: '#3A7CA5',
                                },
                            },
                        }}
                    >
                        Select Multiple
                    </ToggleButton>
                </Box>
                <FormControl sx={{ mt: 1 }} fullWidth>
                    <InputLabel id="stage">Stage</InputLabel>
                    <Select
                        labelId="stage"
                        label="Stage"
                        value={stageSelection}
                        onChange={(e) => setStageSeletion(e.target.value)}
                    >
                        {Object.keys(stages).length > 0 && Object.keys(stages).map((stageName) => {
                            return <MenuItem
                                key={stageName}
                                value={camelCaseToTitle(stageName)}>
                                {camelCaseToTitle(stageName)}
                            </MenuItem>
                        })}
                        {currentUser.account_level === 'Admin' &&
                            <MenuItem value="Archive">Archive</MenuItem>
                        }
                    </Select>
                    <Button
                        variant='outlined'
                        sx={{ mt: 1 }}
                        onClick={(e) => handleSubmit(e, stageSelection)}>
                        Submit
                    </Button>
                </FormControl>
            </CardContent>
        </Card>
    );
}

export default DeptMenuCard;