import React, { useEffect, useState } from "react";
import {
    Box, Divider, FormControl, Select,
    InputLabel, MenuItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useSettingsStore from "../stores/settingsStore";

function SheetCardSorting({ mobile, setSortOrder }) {
    const theme = useTheme()
    const background = theme.palette.background.main
    const [sortOrder, setSortOrderState] = useSettingsStore(state =>
        [state.sortOrder, state.setSortOrder]);

    useEffect(() => {
        setSortOrder(sortOrder)
    }, [sortOrder])

    return <>
        <Divider color={background} />
        <Box display='flex'>
            <Box
                mt={1}
                display="flex"
                alignItems="center"
            >

            </Box>
            <Box
                display="flex"
                justifyContent="center"
                sx={{ textAlign: 'center', flex: 1 }}
            >
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: 'center', mr: 1 }}
            >
                <Box mt={1} sx={{ minWidth: 120 }}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel sx={{ color: background }} variant="standard">
                            Sort By
                        </InputLabel>
                        <Select
                            onClick={(e) => setSortOrderState(e.target.dataset.value)}
                            value={sortOrder || 'dateServiced'}
                            sx={{ color: background }}
                            defaultValue="dateServiced"
                        >
                            <MenuItem style={{ color: 'black' }} value="customer">Customer</MenuItem>
                            <MenuItem style={{ color: 'black' }} value="dateServiced">Date</MenuItem>
                            <MenuItem style={{ color: 'black' }} value="creator">Creator</MenuItem>
                            <MenuItem style={{ color: 'black' }} value="sheet_type">Sheet Type</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box >
    </>
}

export default SheetCardSorting;