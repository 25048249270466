import React, { useContext, useEffect, useState } from "react";
import {
    CardContent, Card, Box, Button, Typography, Divider,
} from "@mui/material";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import UndoIcon from '@mui/icons-material/Undo';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import ShowerIcon from '@mui/icons-material/Shower';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useTheme } from '@mui/material/styles';
import UserContext from "../Users/UserContext";
import { Link, useHistory } from "react-router-dom";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

function SheetCard2({ cardSelectManager, multipleSelectToggle, sheet, mobile }) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const theme = useTheme()
    const background = theme.palette.background.main;
    const blue = theme.palette.secondary.alter;
    const yellow = theme.palette.secondary.alter2;
    const { currentUser } = useContext(UserContext)
    const history = useHistory()
    const [displaySettings, setDisplaySettings] = useState({
        rem: '1rem',
    })
    const [isSelected, setIsSelected] = useState(false);

    // Icon mapping configuration
    const SHEET_ICONS = {
        // Standard service sheets
        sheet: <WarehouseIcon />,
        refrigeration: <AcUnitIcon />,
        preseason: <UndoIcon />,
        sales: <ReceiptIcon />,
        inspections: <SearchIcon />,

        // Agreements and specialized sheets
        chemAppAgreement: <CloudSyncIcon />,
        chemAppService: <WarehouseIcon sx={{ color: yellow }} />,
        bid: <ReceiptLongIcon />,
        bidRef: <ReceiptLongIcon sx={{ color: blue }} />,
        freshPack: <PrecisionManufacturingIcon />,
        disinfectantAgreement: <SanitizerIcon />,
        sprayBarAgreement: <ShowerIcon />
    };

    // Simplified display icon function
    const displayIcon = () => {
        const sheetType = sheet.sheet_type || sheet.sheettype;
        return SHEET_ICONS[sheetType] || <WarehouseIcon />;  // Returns default icon if type not found
    };
    const ACCOUNT_LEVELS = {
        ADMIN: "Admin",
        MANAGER: "Manager",
        TECHNICIAN: "Technician",
        REGIONAL_ADMIN: "Regional Admin"
    };

    const SHEET_ROUTES = {
        sheet: (id) => `/sheets/edit/${id}`,
        refrigeration: (id) => `/refrigeration/edit/${id}`,
        preseason: (id) => `/preseason/edit/${id}`,
        sales: (id) => `/salesOrders/edit/${id}`,
        inspections: (id) => `/inspections/edit/${id}`,
        chemAppAgreement: (id, accLvl) => accLvl === ACCOUNT_LEVELS.TECHNICIAN ? '/' : `/chemApps/agreement/edit/${id}`,
        disinfectantAgreement: (id, accLvl) => accLvl === ACCOUNT_LEVELS.TECHNICIAN ? '/' : `/disinfectant/agreement/edit/${id}`,
        sprayBarAgreement: (id, accLvl) => accLvl === ACCOUNT_LEVELS.TECHNICIAN ? '/' : `/sprayBar/agreement/edit/${id}`,
        bidRef: (id) => `/bidRef/edit/${id}`,
        bid: (id) => `/bids/edit/${id}`,
        freshPack: (id) => `/freshPack/edit/${id}`,
        chemAppService: (id) => `/chemAppService/edit/${id}`,
    };

    const pageRouter = function () {
        const accLvl = currentUser.account_level;
        const sheetType = sheet.sheet_type || sheet.sheettype;

        if (multipleSelectToggle) return;

        if (!SHEET_ROUTES[sheetType]) {
            console.error(`Unknown sheet type: ${sheetType}`);
            return '/';
        }

        return SHEET_ROUTES[sheetType](sheet.id, accLvl);
    };

    const chemAppFormRoute = function (e) {
        e.preventDefault()

        if (sheet.sheettype === "chemAppAgreement") {
            return history.push(`/chemApps/form/edit/${sheet.id}`)
        } else if (sheet.sheettype === "disinfectantAgreement") {
            return history.push(`/disinfectant/form/edit/${sheet.id}`)
        } else if (sheet.sheettype === "sprayBarAgreement") {
            return history.push(`/sprayBar/form/edit/${sheet.id}`)
        }
    }

    const handleCardClick = (e) => {
        if (multipleSelectToggle) {
            e.preventDefault();
            setIsSelected(!isSelected);
            cardSelectManager(sheet.cardId, !isSelected);
        }
    };

    useEffect(() => {
        if (mobile) {
            setDisplaySettings({ ...displaySettings, rem: '0.7rem' })
        } else if (sheet.location_name) {
            if (sheet.location_name.length + sheet.customer.length > 55) {
                setDisplaySettings({ ...displaySettings, rem: '0.85rem' })
            }
        }
    }, []);

    useEffect(() => {
        if (!multipleSelectToggle) setIsSelected(false);
    }, [multipleSelectToggle]);

    return (
        <>
            <Box mt={1}>
                <Card
                    elevation={10}
                    sx={{
                        bgcolor: background,
                        textAlign: 'center',
                        border: isSelected ? `4px solid #3A7CA5` : 'none',
                        borderRadius: '4px'
                    }}>
                    <CardContent>
                        <Link
                            onClick={handleCardClick}
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                                display: 'block',
                            }}
                            to={multipleSelectToggle ? '#' : pageRouter()}
                        >
                            {mobile ? (
                                <Box width="100%">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb={1}
                                    >
                                        <Box display="flex" alignItems="center">
                                            {displayIcon()}
                                            <Typography
                                                sx={{
                                                    fontSize: '0.9rem',
                                                    ml: 1
                                                }}
                                                style={{ fontWeight: 'bold' }}>
                                                ID# {sheet.id}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: '0.85rem'
                                            }}
                                            style={{ fontWeight: 'bold' }}>
                                            {dayjs(sheet.dateServiced).format('MM/DD/YY')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                            borderRadius: 1,
                                            p: 1,
                                            mb: 1
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '0.9rem',
                                                mb: 0.5,
                                                lineHeight: 1.2
                                            }}
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {sheet.customer}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '0.85rem',
                                                color: 'text.secondary',
                                                lineHeight: 1.2
                                            }}
                                        >
                                            {sheet.location_name || sheet.jobName}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        sx={{
                                            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                                            pt: 1
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                color: 'text.secondary'
                                            }}
                                        >
                                            Created by: {sheet.creator}
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Box display="flex" width="100%">
                                    <Box
                                        width="20%"
                                        minWidth="150px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {displayIcon()}
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{ borderColor: 'gray', height: '25px', mx: '10px' }} />
                                        <Typography
                                            sx={{ fontSize: `${displaySettings.rem}` }}
                                            style={{ fontWeight: 'bold' }}>
                                            ID# {sheet.id}
                                        </Typography>
                                    </Box>
                                    <Box
                                        width="60%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        position="relative"
                                    >
                                        {!mobile && (
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    borderColor: 'gray',
                                                    height: '25px'
                                                }}
                                            />
                                        )}
                                        <Box
                                            width="45%"
                                            display="flex"
                                            justifyContent="flex-end"
                                            pr={2}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: `${displaySettings.rem}`,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {sheet.customer}
                                            </Typography>
                                        </Box>
                                        <Box
                                            width="45%"
                                            display="flex"
                                            justifyContent="flex-start"
                                            pl={2}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: `${displaySettings.rem}`,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {sheet.location_name || sheet.jobName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        width="20%"
                                        minWidth="150px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <Typography
                                            sx={{ fontSize: `${displaySettings.rem}` }}
                                            style={{ fontWeight: 'bold' }}>
                                            {sheet.creator}
                                        </Typography>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{ borderColor: 'gray', height: '25px', mx: '10px' }} />
                                        <Typography
                                            sx={{ fontSize: `${displaySettings.rem}` }}
                                            style={{ fontWeight: 'bold' }}>
                                            {sheet.dateServiced}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Link>
                        {(sheet.sheettype === "chemAppAgreement" ||
                            sheet.sheettype === "sprayBarAgreement" ||
                            sheet.sheettype === "disinfectantAgreement") && <>
                                <Link
                                    style={{ color: 'inherit', textDecoration: 'none' }}
                                    to={multipleSelectToggle ? '#' : pageRouter()}
                                >
                                    <Box display={mobile ? "" : "flex"} alignItems="center" mt={2}>
                                        {sheet.product1 &&
                                            <Typography
                                                sx={{ fontSize: `${displaySettings.rem}` }}
                                                style={{ fontWeight: 'bold' }}>
                                                {sheet.product1 + ' @ ' + sheet.rate1}
                                            </Typography>
                                        }
                                        {!mobile &&
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{ borderColor: 'gray', height: '25px', mx: '30px' }} />}
                                        <Box
                                            display={!mobile && 'flex'}
                                            justifyContent="left"
                                            sx={{ flex: 1 }}
                                        >
                                            {sheet.product2 &&
                                                <Typography
                                                    sx={{ fontSize: `${displaySettings.rem}` }}
                                                    style={{ fontWeight: 'bold' }}>
                                                    {sheet.product2 + ' @ ' + sheet.rate2}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                    <Box
                                        mt={mobile ? 1 : 0}
                                        display="flex"
                                        justifyContent="space-between"
                                        sx={{ textAlign: 'left', flex: 1 }}>
                                        <Button
                                            sx={{ fontSize: '0.7rem' }}
                                            variant="contained"
                                            type="submit">
                                            Edit Agreement
                                        </Button>
                                        <Box ml={2}>
                                            <Button
                                                onClick={(e) => chemAppFormRoute(e)}
                                                sx={{ fontSize: '0.7rem' }}
                                                variant="contained"
                                                type="submit">
                                                Edit Form
                                            </Button>
                                        </Box>
                                    </Box>
                                </Link>
                            </>}
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default SheetCard2;