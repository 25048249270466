import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Grid, TextField, ToggleButton, ToggleButtonGroup,
    Box, Typography, TextareaAutosize, Divider, Card, CardContent,
    FormControl, InputLabel, Select, MenuItem,
} from '@mui/material/'
import { Masonry } from "@mui/lab";
import { useParams, useHistory } from "react-router-dom"
import useFields from '../../Hooks/useFields'
import UserContext from '../../Users/UserContext'
import BaseSheet from '../SheetComponents/BaseSheet'
import PartsSheet from '../SheetComponents/PartsSheet'
import TechSheet from "../SheetComponents/TechSheet";
import useBaseStore from "../../stores/baseStore";
import usePartsStore from "../../stores/partsStore";
import useTechStore from "../../stores/techStore";
import useImageStore from "../../stores/imageStore";
import SheetsApi from '../../api'
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import { useTheme } from '@mui/material/styles';
import ESign from "../SheetComponents/images/ESign";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import StageSelector from "../SheetComponents/StageSelector";
import useNotification from "../../Hooks/useNotification";
import TechSelect from "../SheetComponents/TechSelect";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import DeptSelect from "../SheetComponents/DeptSelect";
import { checkPermissions } from "../../helpers/permissions";

function SalesOrderEdit() {
    const INITIAL_STATE = {
        name: "",
        address: "",
        terms: "",
        shippedVia: "",
        zipCode: "",
        state: "",
        city: "",
        stage: "",
        notes: "",
    }
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const componentRef = useRef()
    const history = useHistory()
    const messanger = useNotification()
    const { id } = useParams()
    const { currentUser } = useContext(UserContext)
    const [logs, setLogs] = useState([])
    const [toggle, setToggle] = useState(true)
    const [alignment, setAlignment] = useState(false)
    const [renderCheckTech, setRenderCheckTech] = useState(false)
    const [renderCheckPart, setRenderCheckPart] = useState(false)
    const [formData, handleLocal, resetFormData] = useFields(INITIAL_STATE)
    const [baseSheet, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj])
    const [partSheet, clearPartsForm, setPartsObj] = usePartsStore(state =>
        [state.parts, state.clearPartsForm, state.setPartsObj])
    const [techSheet, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj])
    const [images, addImage, signature, setSignature, clearImages,
        clearSignature] = useImageStore(state =>
            [state.images, state.addImage, state.signature, state.setSignature,
            state.clearImages, state.clearSignature])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [incomingStage, setIncomingStage] = useState("");

    const handleAlignment = (e, newAlignment) => {
        setAlignment(newAlignment)
    }
    const handleToggle = () => {
        setToggle(!toggle)
    }
    async function handleSubmit(e) {
        e.preventDefault()
        const permissionsResult = checkPermissions(currentUser.account_level, currentUser.dept, incomingStage);
        if (!permissionsResult.status) {
            return messanger(permissionsResult.message, "error")
        }
        setIsSubmitting(true);
        const sheetObj = CreateSubmitObj(
            formData, currentUser, messanger,
            baseSheet, partSheet, techSheet,
        )
        if (sheetObj.error) return setIsSubmitting(false);
        sheetObj.id = id
        sheetObj.sheet.sheetType = "sales"
        const response = await SheetsApi.editSalesOrderSheet(sheetObj)
        const allImages = [...images, signature]
        HandleImage.addAndRemove(allImages, sheetObj)
        messanger(response.message, "success")
        setRenderCheckPart(false)
        setRenderCheckTech(false)
        clearImages()
        clearBaseForm()
        resetFormData()
        clearTechForm()
        clearPartsForm()
        history.goBack()
        return setIsSubmitting(false);
    }

    useEffect(() => {
        clearSignature()
        clearImages()
        clearBaseForm()
        clearPartsForm()
        clearTechForm()
        resetFormData()

        async function getSalesOrder() {
            try {
                const salesOrder = await SheetsApi.getSalesOrder({ id })
                const images = salesOrder.images
                const parts = await SheetsApi.getPartsById({ id, type: 'sales_order_id' })
                const locationId = salesOrder.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                const techs = await SheetsApi.getTechsById({ id, type: 'salesOrderId' })
                salesOrder.location = locationName.location_name
                salesOrder.locationId = locationId
                setLogs(salesOrder.logs)
                return { salesOrder, parts, techs, images }
            } catch (err) {
                console.log(err)
                messanger(err.response.data.error.message, "error")
            }
        }
        getSalesOrder().then(async sheet => {
            console.log(sheet)
            for (let section in sheet) {
                if (section === 'salesOrder') {
                    const split = sheet[section]
                    let baseFormObject = {
                        creator: split.creator,
                        customerName: split.customer,
                        dateServiced: split.dateServiced,
                        locationName: split.location,
                        po: split.po,
                        requestedBy: split.request_by,
                        dateCreated: split.dateCreated,
                        notes: split.notes,
                        locationId: split.locationId,
                        dept: split.dept,
                    }
                    let salesSheetObj = {
                        stage: split.stage,
                        notes: split.notes,
                        terms: split.terms,
                        name: split.name,
                        address: split.address,
                        shippedVia: split.shipped_via,
                        zipCode: split.zip_code,
                        state: split.state,
                        city: split.city,
                        salesRep: split.salesRep,
                    }
                    setIncomingStage(split.stage)
                    setBaseFormObj(baseFormObject)
                    handleLocal("replaceObj", salesSheetObj)
                } else if (section === 'parts') {
                    let partsArray = []
                    for (let obj of sheet[section]) {
                        let partObj = {
                            qty: obj.quantity,
                            type: obj.quantity_type,
                            part: obj.part_number,
                            description: obj.description,
                            cost: obj.cost,
                            isMisc: obj.ismisc,
                            location: obj.location,
                            name: obj.name,
                            invoice: obj.invoice,
                            po: obj.po,
                            id: obj.id,
                        }
                        partsArray.push(partObj)
                    }
                    setPartsObj(partsArray)
                    setRenderCheckPart(true)
                } else if (section === 'techs') {
                    let id = -1
                    for (let obj of sheet[section]) {
                        id++
                        let techObj = {
                            technician: obj.technician,
                            truck: obj.truck,
                            labor: obj.labor,
                            travel: obj.travel,
                            nc: obj.nc,
                            notes: obj.notes,
                            timeStart: obj.timestart,
                            timeEnd: obj.timeend,
                        }
                        setTechObj(techObj, id)
                    }
                    setRenderCheckTech(true)
                } else if (section === 'images') {
                    for (let img of sheet[section]) {
                        const imageObj = await HandleImage.setImage(img)
                        if (img.imageType !== 'signature') {
                            addImage(imageObj)
                        } else if (img.imageType === 'signature') {
                            setSignature(imageObj)
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        //When switching between departments, we need to change the stage to a valid selection.
        const { dept } = baseSheet;
        const { stage } = formData;
        const nonSalesStages = ['Preliminary Review', 'Ready For Review', 'Ready For Invoicing'];
        const salesStages = ['Signature Pending', 'Down Payment', 'Progress Payment', 'Final Payment'];
        if (dept !== 'sales') {
            if (!nonSalesStages.includes(stage)) handleLocal("invalidInput", 'stage', "Preliminary Review");
            else return;
        }
        if (dept === 'sales') {
            if (!salesStages.includes(stage)) handleLocal("invalidInput", 'stage', "Final Payment");
        }
    }, [baseSheet.dept]);

    return (
        <div ref={componentRef} className="page">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <Grid container >
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={10} mt={5} mb={5}>
                        <BaseSheet id={id} title="Sales Order" />
                        <Grid
                            display="grid"
                            className='displayColumns3'
                            gridTemplateColumns='repeat(auto-fill, minmax(350px, 1fr))'
                        >
                            <Box display="flex" width="98%" mb={1} mr={1}>
                                <TechSelect
                                    name="Sales Rep"
                                    id="salesRep"
                                    edit={true}
                                    formData={formData}
                                    handleLocal={handleLocal}
                                />
                            </Box>
                            <Box display="flex" width="98%" mb={1} mr={1}>
                                <TextField
                                    fullWidth
                                    name='terms'
                                    id='terms'
                                    label='Terms'
                                    variant='outlined'
                                    value={formData.terms || ""}
                                    onChange={(e) => handleLocal(e)}
                                />
                            </Box>
                            <Box display="flex" width="98%" mb={1} mr={1}>
                                <ToggleButtonGroup
                                    value={alignment}
                                    onChange={handleAlignment}>
                                    <ToggleButton
                                        id='shipping'
                                        value={true}
                                    >
                                        Shipping Information
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Grid>
                        <Box mb={1}>
                            <Divider sx={{ borderBottomWidth: 5 }} />
                        </Box>
                        {alignment[0] && <>
                            <Masonry columns={2}>
                                <Box mr={1}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        value={formData.name || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        name="address"
                                        id="address"
                                        label="Address"
                                        variant="outlined"
                                        value={formData.address || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} >
                                    <TextField
                                        fullWidth
                                        name='shippedVia'
                                        id="shippedVia"
                                        label="Shipped Via"
                                        variant='outlined'
                                        value={formData.shippedVia || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name='zipCode'
                                        id='zipCode'
                                        label="Zip Code"
                                        variant='outlined'
                                        value={formData.zipCode || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name='state'
                                        id='state'
                                        label="State"
                                        variant='outlined'
                                        value={formData.state || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                                <Box ml={1} mb={1}>
                                    <TextField
                                        fullWidth
                                        name='city'
                                        id='city'
                                        label="City"
                                        variant='outlined'
                                        value={formData.city || ""}
                                        onChange={(e) => handleLocal(e)}
                                    />
                                </Box>
                            </Masonry>
                            <Box mb={1}>
                                <Divider sx={{ borderBottomWidth: 5 }} />
                            </Box>
                        </>}
                        <Box mb={1}>
                            {renderCheckPart &&
                                <PartsSheet toggle={toggle} calcOn={true}
                                />
                            }
                        </Box>
                        {renderCheckTech && <TechSheet
                            toggle={toggle}
                            edit={false}
                            timeTrack={true}
                        />}
                        {toggle && <>
                            <Box
                                style={{ width: "100%" }}
                                sx={{ backgroundColor: primary }}
                            >
                                <Typography
                                    variant="h5"
                                    ml={1}
                                    style={{ color: defaultColor }}>
                                    Comments:
                                </Typography>
                            </Box>
                            <Box mb={1} ml={0.25} >
                                <TextareaAutosize
                                    id="notes"
                                    name="notes"
                                    value={formData.notes || ""}
                                    onChange={handleLocal}
                                    minRows={4}
                                    style={{
                                        width: "99.0%",
                                        outline: '2px solid #000',
                                        fontSize: "16px",
                                    }}
                                />
                            </Box>
                        </>}
                        <Box mb={1} mt={3}>
                            <ESign
                                sheetType="sales"
                                customerName={baseSheet.customerName}
                                edit={true} />
                        </Box>
                        <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                            <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                <CardContent>
                                    <Box display="flex" justifyContent="left" mt={1}>
                                        <Box>
                                            {baseSheet.dept === 'sales' ?
                                                <FormControl style={{ width: '30ch' }}>
                                                    <InputLabel>Stage</InputLabel>
                                                    <Select
                                                        required
                                                        label="Stage"
                                                        onChange={handleLocal}
                                                        id="stage"
                                                        name="stage"
                                                        value={formData.stage || "Signature Pending"}
                                                        defaultValue=""
                                                    >
                                                        <MenuItem value="Signature Pending">Signature Pending</MenuItem>
                                                        <MenuItem value="Down Payment">Down Payment</MenuItem>
                                                        <MenuItem value="Progress Payment">Progress Payment</MenuItem>
                                                        <MenuItem value="Final Payment">Final Payment</MenuItem>
                                                        <MenuItem disabled={currentUser.account_level !== 'Admin' ? true : false} value="Archive">Archive</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                : <StageSelector
                                                    currentUser={currentUser}
                                                    handleChange={handleLocal}
                                                    value={formData.stage}
                                                />}
                                            <DeptSelect salesOrder={true} />
                                            <SubmitAndPrint
                                                isSubmitting={isSubmitting}
                                                componentRef={componentRef}
                                                showToggle={true}
                                                toggle={toggle}
                                                handleToggle={handleToggle}
                                            />
                                        </Box>
                                        {!currentUser.mobile && renderCheckTech ? <ImageDropZone sheetType="sales" /> : null}
                                    </Box>
                                    {currentUser.mobile && renderCheckTech ? <ImageDropZone sheetType="sales" /> : null}
                                </CardContent>
                            </Card>
                            {logs.length > 0 &&
                                <HistoryCard toggle={toggle} baseSheet={baseSheet} setLogs={setLogs} logs={logs} />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </div >
    )
}

export default SalesOrderEdit;