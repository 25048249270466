import React, { useContext, useEffect, useState } from "react";
import useFields from "../../Hooks/useFields";
import {
    Box, TextField, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import useStorageAssessmentStore from "../../stores/storageAssessmentStore";
import UserContext from "../../Users/UserContext";

const styles = {
    mobileBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%'
    },
    mobileInnerBox: {
        display: 'block',
        marginBottom: '10px'
    },
    mobileField: {
        width: '100%',
        marginBottom: '10px'
    },
    desktopBox: {
        display: 'inline',
        marginRight: '8px'
    }
};

function StorageAssessmentHead({ edit }) {
    const [editable, setEditable] = useState(true);
    const { currentUser } = useContext(UserContext);
    const [formData, handleLocal, clearState] = useFields({ staticPressure: 1.25, ductType: 'ducts', ventDesign: '', selectWeight: "cwt" });
    const [setObj, storageAssessmentHead] = useStorageAssessmentStore(state =>
        [state.setObj, state.storageAssessmentHead,]);
    const totalCfm = useStorageAssessmentStore(state => state.totals.totalCfm);

    useEffect(() => {
        if (formData.selectWeight === "cwt") setEditable(true)
        else if (formData.selectWeight === "tons") setEditable(false);
    }, [formData.selectWeight]);

    useEffect(() => {
        setObj(formData, "storageAssessmentHead")
    }, [formData]);

    const setCfmPerTon = function (value, tons) {
        if (!totalCfm) return;
        let cfmPerTon = 0;
        if (formData.selectWeight === 'cwt' && value) {
            cfmPerTon = totalCfm / (parseFloat(value) / 20);
        } else if (formData.selectWeight === 'tons' && tons) {
            cfmPerTon = totalCfm / parseFloat(tons);
        }
        if (!isNaN(cfmPerTon) && isFinite(cfmPerTon)) {
            cfmPerTon = parseFloat(cfmPerTon.toFixed(1));
            console.log(cfmPerTon)
            handleLocal("invalidInput", 'cfmPerTon', cfmPerTon)
        }
    }

    useEffect(() => {
        clearState();
        if (edit) handleLocal("replaceObj", storageAssessmentHead)
    }, [])

    useEffect(() => {
        setCfmPerTon();
    }, [totalCfm, formData.selectWeight]);

    const handleCwtChange = (e) => {
        const value = e.target.value;
        const tons = value ? (parseFloat(value) / 20).toFixed(2) : '';
        handleLocal("invalidInput", 'cwt', value)
        handleLocal("invalidInput", 'tons', tons)
        console.log(value)
        setCfmPerTon(value, tons);
    };

    const handleTonsChange = (e) => {
        const value = e.target.value;
        const cwt = value ? (parseFloat(value) * 20).toFixed(2) : '';
        handleLocal("invalidInput", 'tons', value)
        handleLocal("invalidInput", 'cwt', cwt)
        setCfmPerTon();
    };

    return (
        <Box justifyContent="space-between" display="flex">
            <Box m={1} sx={{ width: currentUser.mobile ? '100%' : '65%' }} >
                <Box m={1}>
                    <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                        <FormControl sx={currentUser.mobile ? styles.mobileField : { width: "20ch" }}>
                            <InputLabel>Select Weight</InputLabel>
                            <Select
                                name="selectWeight"
                                id="selectWeight"
                                label="Select Weight"
                                onChange={handleLocal}
                                value={formData.selectWeight || "cwt"}>
                                <MenuItem value="cwt">CWT</MenuItem>
                                <MenuItem value="tons">TONS</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                        <TextField
                            name="cwt"
                            id="cwt"
                            label="CWT"
                            type="number"
                            sx={currentUser.mobile ? styles.mobileField : { width: "20ch" }}
                            variant={!editable ? "standard" : "outlined"}
                            onChange={handleCwtChange}
                            disabled={!editable}
                            value={formData.cwt || ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                        <TextField
                            name="tons"
                            id="tons"
                            label="Tons"
                            type="number"
                            sx={currentUser.mobile ? styles.mobileField : { width: "20ch" }}
                            onChange={handleTonsChange}
                            variant={editable ? "standard" : "outlined"}
                            disabled={editable}
                            value={formData.tons || ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box mt={1}>
                        <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                            <FormControl sx={currentUser.mobile ? styles.mobileField : { width: "13ch" }}>
                                <InputLabel>Static Pressure</InputLabel>
                                <Select
                                    name="staticPressure"
                                    id="staticPressure"
                                    label="Static Pressure"
                                    onChange={handleLocal}
                                    value={formData.staticPressure || 1.25}>
                                    <MenuItem value={.5}>0.50"</MenuItem>
                                    <MenuItem value={.75}>0.75"</MenuItem>
                                    <MenuItem value={1}>1.00"</MenuItem>
                                    <MenuItem value={1.25}>1.25"</MenuItem>
                                    <MenuItem value={1.5}>1.50"</MenuItem>
                                    <MenuItem value={1.75}>1.75"</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                            <FormControl sx={currentUser.mobile ? styles.mobileField : { width: "11ch" }}>
                                <InputLabel>Voltage</InputLabel>
                                <Select
                                    name="voltage"
                                    id="voltage"
                                    label="Voltage"
                                    onChange={handleLocal}
                                    value={formData.voltage || 0}>
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    <MenuItem value={240}>240V</MenuItem>
                                    <MenuItem value={480}>480V</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                            <FormControl sx={currentUser.mobile ? styles.mobileField : { width: "16ch" }}>
                                <InputLabel>Ventilation Design</InputLabel>
                                <Select
                                    name="ventDesign"
                                    id="ventDesign"
                                    label="Ventilation Design"
                                    onChange={handleLocal}
                                    value={formData.ventDesign || ""}>
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="ivi">IVI</MenuItem>
                                    <MenuItem value="btu">BTU</MenuItem>
                                    <MenuItem value="gellert">Gellert</MenuItem>
                                    <MenuItem value="european">European</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box m={1}>
                    <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                        <TextField
                            name="cfmPerTon"
                            id="cfmPerTon"
                            label="CFM / Ton"
                            type="number"
                            sx={currentUser.mobile ? styles.mobileField : { width: "14ch" }}
                            variant="standard"
                            value={formData.cfmPerTon || ""}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                        <TextField
                            name="totalCfm"
                            id="totalCfm"
                            label="Total CFM"
                            variant="standard"
                            type="number"
                            sx={currentUser.mobile ? styles.mobileField : { width: "24ch" }}
                            value={totalCfm || 0}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                        <TextField
                            name="plenumSqFt"
                            id="plenumSqFt"
                            label="Plenum Sq Ft"
                            type="number"
                            sx={currentUser.mobile ? styles.mobileField : { width: "14ch" }}
                            variant="outlined"
                            value={formData.plenumSqFt || ""}
                            onChange={handleLocal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box mt={1}>
                        <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                            <FormControl sx={currentUser.mobile ? styles.mobileField : { width: "13ch" }}>
                                <InputLabel>Duct Type</InputLabel>
                                <Select
                                    name="ductType"
                                    id="ductType"
                                    label="Duct Type"
                                    onChange={handleLocal}
                                    value={formData.ductType || "ducts"}>
                                    <MenuItem value="ducts">Ducts</MenuItem>
                                    <MenuItem value="airFloor">Air Floor</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {formData.ductType === "ducts" ? <>
                            <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                                <FormControl sx={currentUser.mobile ? styles.mobileField : { width: "13ch" }}>
                                    <InputLabel>Duct Size</InputLabel>
                                    <Select
                                        name="ductSize"
                                        id="ductSize"
                                        label="Duct Size"
                                        onChange={handleLocal}
                                        value={formData.ductSize || ""}>
                                        <MenuItem value={15}>15"</MenuItem>
                                        <MenuItem value={18}>18"</MenuItem>
                                        <MenuItem value={21}>21"</MenuItem>
                                        <MenuItem value={24}>24"</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                                <TextField
                                    name="numberOfDucts"
                                    id="numberOfDucts"
                                    label="Number of Ducts"
                                    type="number"
                                    sx={currentUser.mobile ? styles.mobileField : { width: "14ch" }}
                                    variant="outlined"
                                    value={formData.numberOfDucts || ""}
                                    onChange={handleLocal}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </> : formData.ductType === 'airFloor' ? <>
                            <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                                <TextField
                                    name="airFloorQty"
                                    id="airFloorQty"
                                    label="Air Floor Qty"
                                    type="number"
                                    sx={currentUser.mobile ? styles.mobileField : { width: "14ch" }}
                                    variant="outlined"
                                    value={formData.airFloorQty || ""}
                                    onChange={handleLocal}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                                <TextField
                                    name="airFloorLength"
                                    id="airFloorLength"
                                    label="Air Floor Length"
                                    type="number"
                                    sx={currentUser.mobile ? styles.mobileField : { width: "14ch" }}
                                    variant="outlined"
                                    value={formData.airFloorLength || ""}
                                    onChange={handleLocal}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            <Box mr={1} style={currentUser.mobile ? styles.mobileInnerBox : styles.desktopBox}>
                                <TextField
                                    name="airFloorWidth"
                                    id="airFloorWidth"
                                    label="Air Floor Width"
                                    type="number"
                                    sx={currentUser.mobile ? styles.mobileField : { width: "14ch" }}
                                    variant="outlined"
                                    value={formData.airFloorWidth || ""}
                                    onChange={handleLocal}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        </> : null}
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default StorageAssessmentHead;