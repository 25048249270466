import React, { useEffect, useContext, useState } from "react";
import {
    Box, FormControl, Select, InputLabel,
    MenuItem, TextField, IconButton, Tooltip,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import useFields from "../../../Hooks/useFields";
import useStorageAssessmentStore from "../../../stores/storageAssessmentStore";
import SearchableInput from "../../SheetComponents/SearchableInput";
import UserContext from "../../../Users/UserContext";
import StorageAssessVariance from "./StorageAssessVariance";

function StorageAssessPartOther({ partType, indexId, partList, hideTotals, edit, part }) {
    const [formData, handleLocal] = useFields({});
    const [localTotals, setLocalTotals] = useFields({});
    const { currentUser } = useContext(UserContext);
    const [parts, removeLine] = useStorageAssessmentStore(state => [state[partType], state.removeLine]);

    const isLastItem = parts && indexId === parts.length - 1;

    useEffect(() => {
        if (edit) handleLocal("replaceObj", part);
    }, [])

    const styles = {
        mobile: {
            mt: 1,
            display: 'block',
        },
        mobileDesc: {
            mt: 1,
            width: '100%'
        },
        desktop: {
            display: "inline",
        }
    }

    return (
        <Box m={1}>
            <Box ml={1} sx={{ display: "inline" }}>
                <TextField
                    name="qty"
                    id="qty"
                    label="Quantity"
                    type="number"
                    sx={{ width: currentUser.mobile ? '80%' : '10ch' }}
                    value={formData.qty || ""}
                    onChange={handleLocal}
                />
            </Box>
            {partType === 'fad' && <Box sx={currentUser.mobile ? styles.mobile : styles.desktop}>
                <Box ml={1} sx={{ display: "inline" }}>
                    <FormControl>
                        <InputLabel>Master / Volume</InputLabel>
                        <Select
                            name="doorType"
                            id="doorType"
                            label="Master / Volume"
                            sx={{ width: "18ch" }}
                            value={formData.doorType || ""}
                            onChange={handleLocal}
                        >
                            <MenuItem value=""> <em>None</em> </MenuItem>
                            <MenuItem value="master"> Master </MenuItem>
                            <MenuItem value="volume"> Volume </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box ml={1} display="inline">
                    <FormControl>
                        <InputLabel>Hinge Type</InputLabel>
                        <Select
                            name="hingeType"
                            id="hingeType"
                            label="Hinge Type"
                            sx={{ width: "16ch" }}
                            value={formData.hingeType || ""}
                            onChange={handleLocal}
                        >
                            <MenuItem value=""> <em>None</em> </MenuItem>
                            <MenuItem value="top"> Top </MenuItem>
                            <MenuItem value="side"> Side </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>}
            {partList &&
                <Box ml={1} sx={currentUser.mobile ? styles.mobile : styles.desktop}>
                    <SearchableInput
                        edit={true}
                        editData={part.partNumber}
                        setState={handleLocal}
                        searchableArray={partList}
                        label="Part Number"
                        variableName="partNumber"
                        fullwidth={currentUser.mobile ? true : false}
                    />
                    <TextField
                        name="description"
                        id="description"
                        label="Description"
                        variant={formData.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                        sx={currentUser.mobile ? styles.mobileDesc : { ml: 1 }}
                        value={formData.description || ""}
                        onChange={handleLocal}
                        InputProps={{
                            readOnly: formData.partNumber !== 'SERPART'
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Box>
            }
            <Box sx={currentUser.mobile ? styles.mobile : styles.desktop} >
                <Box ml={1} display="inline">
                    <TextField
                        name="width"
                        id="width"
                        label="Width"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.width || ""}
                        onChange={handleLocal}

                    />
                </Box>
                <Box ml={1} display="inline">
                    <TextField
                        name="height"
                        id="height"
                        label="Height"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.height || ""}
                        onChange={handleLocal}

                    />
                </Box>
            </Box>
            <Box ml={1} display="inline">
                <TextField
                    name="sqFtPerPart"
                    id="sqFtPerPart"
                    label="Sq. Ft. Per Item"
                    type="number"
                    variant="standard"
                    value={localTotals.sqFtPerPart || ""}
                    sx={{ width: "14ch" }}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
            <Box display="inline">
                <TextField
                    name="totalSqFt"
                    id="totalSqFt"
                    label="Total Sq. Ft."
                    type="number"
                    variant="standard"
                    value={localTotals.totalSqFtParts || ""}
                    sx={{ width: "14ch", ml: 1 }}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
            <Tooltip title="Remove line">
                <IconButton onClick={() => removeLine(partType, indexId)} sx={{ mt: 1 }}>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
            {
                isLastItem && !hideTotals && (
                    <StorageAssessVariance
                        localTotals={localTotals}
                        setLocalTotals={setLocalTotals}
                        formData={formData}
                        partType={partType}
                        indexId={indexId}
                    />
                )
            }
        </Box >
    )
}

export default StorageAssessPartOther;