import { create } from "zustand"
import dayjs from "dayjs"
const newPart = {
    partNumber: "",
    description: "",
    price: 0,
    category: "",
    additionalData: "",
    indexId: 0,
}
const useStorageAssessmentStore = create((set) => ({
    storageSettings: {
        site_name: "",
        lat: "",
        long: "",
        inactive: false,
    },
    storageAssessmentHead: {
        staticPressure: 1.25,
        cwt: "",
        tons: "",
        selectWeight: "cwt",
        plenumSqFt: "",
        ductSize: "",
        numberOfDucts: "",
        voltage: 0,
        ventDesign: "",
    },
    crop: {
        crop: "",
        startDate: dayjs().format("MM/DD/YYYY"),
        endDate: dayjs().format("MM/DD/YYYY"),
    },
    varianceTotals: {
        penumnVariance: 0,
        ductVariance: 0,
        refVariance: 0,
    },
    fan: [newPart],
    motor: [newPart],
    freqDrive: [newPart],
    fad: [newPart],
    returnAirOpening: [newPart],
    actuator: [newPart],
    humidifier: [newPart],
    control: [newPart],
    climaCell: [newPart],
    damper: [newPart],
    lightBlock: [newPart],
    refrigeration: [newPart],
    climaCellBase: {
        qty: 0,
        width: 0,
        height: 0,
        depth: 0,
        edgeCoat: false,
    },
    totals: {
        totalCfm: 0,
        totalSqFtDoors: 0,
        totalSqFtReq: 0,
        totalSqFtReturnAirOpening: 0,
        totalSqFtClimaCell: 0,
        totalSqFtDamper: 0,
        totalSqFtLightBlock: 0,
    },

    setAllParts: function (parts) {
        set(prevState => {
            const storageAssessmentHead = {
                staticPressure: parts.assessmentBase.staticPressure,
                cwt: parts.assessmentBase.cwt,
                tons: parts.assessmentBase.tons,
                selectWeight: parts.assessmentBase.selectWeight,
                plenumSqFt: parts.assessmentBase.plenumSqFt,
                ductSize: parts.assessmentBase.ductSize,
                ductType: parts.assessmentBase.ductType,
                airFloorQty: parts.assessmentBase.airFloorQty,
                airFloorLength: parts.assessmentBase.airFloorLength,
                airFloorWidth: parts.assessmentBase.airFloorWidth,
                numberOfDucts: parts.assessmentBase.numberOfDucts,
                voltage: parts.assessmentBase.voltage,
                currRefTonage: parts.assessmentBase.currRefTonage,
                ratio: parts.assessmentBase.ratio,
                ventDesign: parts.assessmentBase.ventDesign,
            }

            return {
                ...prevState,
                storageAssessmentHead,
                fan: parts.assessmentFans || [],
                motor: parts.assessmentMotors || [],
                freqDrive: parts.assessmentFreqDrives || [],
                fad: parts.assessmentFad || [],
                returnAirOpening: parts.assessmentReturnAirOpenings || [],
                actuator: parts.assessmentActuators || [],
                humidifier: parts.assessmentHumidifiers || [],
                control: parts.assessmentControls || [],
                climaCell: parts.assessmentClimaCell || [],
                damper: parts.assessmentDampers || [],
                lightBlock: parts.assessmentLightBlock || [],
                refrigeration: parts.assessmentRefrigeration || [],
            }
        });
    },

    getVarianceColor: function (variance) {
        if (variance >= 0) return 'green';
        if (variance >= -10) return '#8B8000'; // Dark yellow
        return 'red';
    },

    setObj: function (newObj, objToReplace) {
        set(prevState => ({
            ...prevState,
            [objToReplace]: {
                ...prevState[objToReplace],
                ...newObj,
            }
        }))
    },

    setCrop: function (name, value) {
        set(prevState => ({
            ...prevState,
            crop: { ...prevState.crop, [name]: value }
        }))
    },

    addPart: function (partType) {
        // Adds additional part as well as changes the indexId
        set(prevState => {
            if (prevState[partType].length < 6) {
                return {
                    ...prevState,
                    [partType]: [...prevState[partType],
                    { ...newPart, indexId: prevState[partType].length }]
                }
            } else return prevState
        })
    },

    removeLine: function (partType, indexId) {
        set(prevState => ({
            ...prevState,
            [partType]: prevState[partType].filter(part => part.indexId !== indexId)
        }))
    },

    replacePartArray: function (partType, data) {
        set(prevState => ({
            ...prevState,
            [partType]: data || []
        }));
    },

    updatePart: function (partType, indexId, data) {
        set(prevState => ({
            ...prevState,
            [partType]: prevState[partType].map(part =>
                part.indexId === indexId ? { ...part, ...data } : part
            )
        }));
    },

    calculateTotals: function (partType) {
        //Calculate the totals shown at the bottom of the part list in storage assessmenets. 
        set(prevState => {
            let totalVar = '';
            let totalSqFtReq = 0;
            const total = prevState[partType]?.reduce((sum, part) => {
                if (partType === 'fan') {
                    // The total CFM of fans
                    totalVar = 'totalCfm';
                    const cfmValue = part.partNumber === 'SERPART'
                        ? part.additionalData?.cfm || 0
                        : part.additionalData?.[`staticPressure${prevState.storageAssessmentHead.staticPressure * 100}`] || 0;
                    totalSqFtReq = parseFloat(((sum + (Number(part.qty || 0) * Number(cfmValue) * (Number(part.fanPercent || 100) / 100))) / 1400).toFixed(1));
                    return sum + (Number(part.qty || 0) * Number(cfmValue) * (Number(part.fanPercent || 100) / 100));
                } else if (partType === 'fad') {
                    // The total square feet of the door opening
                    totalVar = 'totalSqFtDoors';
                    let totalSqFtDoors = 0;
                    prevState.fad.forEach((fad) => {
                        const sqFtPerDoor = parseFloat(((fad.height * fad.width) / 144).toFixed(1));
                        const totalSqFtDoor = parseFloat((sqFtPerDoor * fad.qty).toFixed(1));
                        totalSqFtDoors = totalSqFtDoors + totalSqFtDoor
                    })
                    return totalSqFtDoors;
                } else if (partType === 'returnAirOpening') {
                    // The total square feet of the return air opening
                    totalVar = 'totalSqFtReturnAirOpening';
                    let totalSqFtReturnAirOpening = 0;
                    prevState.returnAirOpening.forEach((returnAirOpeningPart) => {
                        const sqFt = parseFloat(((returnAirOpeningPart.height * returnAirOpeningPart.width) / 144).toFixed(1));
                        const totalSqFt = parseFloat((sqFt * returnAirOpeningPart.qty).toFixed(1));
                        totalSqFtReturnAirOpening = totalSqFtReturnAirOpening + totalSqFt
                    })
                    return totalSqFtReturnAirOpening;
                } else if (partType === 'lightBlock') {
                    totalVar = 'totalSqFtLightBlock';
                    let totalSqFtLightBlock = 0;
                    prevState.lightBlock.forEach((lightBlock) => {
                        const sqFt = parseFloat(((lightBlock.height * lightBlock.width) / 144).toFixed(1));
                        const totalSqFt = parseFloat((sqFt * lightBlock.qty).toFixed(1));
                        totalSqFtLightBlock = totalSqFtLightBlock + totalSqFt
                    })
                    return totalSqFtLightBlock;
                } else if (partType === 'damper') {
                    totalVar = 'totalSqFtDamper'
                    let totalSqFtDamper = 0;
                    prevState.damper.forEach((damper) => {
                        const sqFt = parseFloat(((damper.height * damper.width) / 144).toFixed(1));
                        const totalSqFt = parseFloat((sqFt * damper.qty).toFixed(1));
                        totalSqFtDamper = totalSqFtDamper + totalSqFt
                    })
                    return totalSqFtDamper;
                } else if (partType === 'climaCell') {
                    totalVar = 'totalSqFtClimaCell'
                    return parseFloat((prevState.climaCellBase.width * prevState.climaCellBase.height) * prevState.climaCellBase.qty).toFixed(1);
                } else return 0;
            }, 0);
            return {
                ...prevState,
                totals: {
                    ...prevState.totals,
                    [totalVar]: total,
                    ...(partType === 'fan' && { totalSqFtReq })
                }
            };
        });
    },

    clearState: function () {
        set(prevState => {
            return {
                ...prevState,
                storageAssessmentHead: {
                    staticPressure: 1.25,
                    cwt: "",
                    tons: "",
                    selectWeight: "cwt",
                    plenumSqFt: "",
                    ductSize: "",
                    numberOfDucts: "",
                    voltage: 0,
                    crop: "",
                },
                fan: [newPart],
                motor: [newPart],
                freqDrive: [newPart],
                fad: [newPart],
                returnAirOpening: [newPart],
                actuator: [newPart],
                humidifier: [newPart],
                control: [newPart],
                climaCell: [newPart],
                damper: [newPart],
                lightBlock: [newPart],
                refrigeration: [newPart],
                climaCellBase: {
                    qty: 0,
                    width: 0,
                    height: 0,
                    depth: 0,
                    edgeCoat: false,
                },
                totals: {
                    totalCfm: 0,
                    totalSqFtDoors: 0,
                    totalSqFtReq: 0,
                    totalSqFtReturnAirOpening: 0,
                    totalSqFtClimaCell: 0,
                    totalSqFtDamper: 0,
                    totalSqFtLightBlock: 0,
                },
            }
        })
    },
}))

export default useStorageAssessmentStore
