import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import useStorageAssessmentStore from "../../stores/storageAssessmentStore";
import { Box } from "@mui/material";
import StorageAssessFan from "./Parts/StorageAssessFan";
import StorageAssessPart from "./Parts/StorageAssessPart";
import StorageAssessPartOther from "./Parts/StorageAssessPartOther";
import StorageAssessClimaCell from "./Parts/StorageAssessClimaCell";
import SheetsApi from "../../api";
import PartCollapseCard from "./Parts/PartCollapseCard";

const StorageAssessParts = forwardRef(({ edit }, ref) => {
    const [fan, motor, freqDrive, fad, returnAirOpening, actuator, climaCell, lightBlock, humidifier,
        damper, control, refrigeration] = useStorageAssessmentStore(state => [state.fan, state.motor,
        state.freqDrive, state.fad, state.returnAirOpening, state.actuator, state.climaCell,
        state.lightBlock, state.humidifier, state.damper, state.control, state.refrigeration]);
    const [fans, setFans] = useState([]);
    const [motors, setMotors] = useState([]);
    const [freqDrives, setFreqDrives] = useState([]);
    const [actuators, setActuators] = useState([]);
    const [climaCells, setClimaCells] = useState([]);
    const [humidifiers, setHumidifiers] = useState([]);
    const [lightBlocks, setLightBlocks] = useState([]);
    const [dampers, setDampers] = useState([]);
    const [controls, setControls] = useState([]);
    const changesRef = useRef({});
    const [render, setRender] = useState(false);

    useImperativeHandle(ref, () => ({
        getChanges: () => changesRef.current
    }));

    const handlePartChange = (partType, indexId, data) => {
        if (!changesRef.current[partType]) {
            changesRef.current[partType] = [];
        }

        if (data === null) {
            changesRef.current[partType] = changesRef.current[partType].filter(
                item => item.indexId !== indexId
            );
            return;
        }
        // Find if we already have an entry for this indexId
        const existingIndex = changesRef.current[partType].findIndex(
            item => item.indexId === indexId
        );

        if (existingIndex >= 0) {
            // Update existing entry
            changesRef.current[partType][existingIndex] = {
                ...changesRef.current[partType][existingIndex],
                ...data,
                indexId
            };
        } else {
            // Add new entry
            changesRef.current[partType].push({
                ...data,
                indexId
            });
        }
    };

    useEffect(() => {
        setRender(false);
        const getParts = async function () {
            try {
                const motors = await SheetsApi.getBidPartsByCategory({ category: 'motor' })
                const freqDrives = await SheetsApi.getBidPartsByCategory({ category: 'freqDrive' })
                const fans = await SheetsApi.getBidPartsByCategory({ category: 'fan' })
                const actuators = await SheetsApi.getBidPartsByCategory({ category: 'actuator' })
                const climaCells = await SheetsApi.getBidPartsByCategory({ category: "climaCell" })
                const humidifiers = await SheetsApi.getBidPartsByCategory({ category: "humidifier" })
                const dampers = await SheetsApi.getBidPartsByCategory({ category: "damper" })
                const lightBlocks = await SheetsApi.getBidPartsByCategory({ category: "lightBlock" })
                const controls = await SheetsApi.getBidPartsByCategory({ category: "control" })
                return { motors, freqDrives, fans, actuators, climaCells, humidifiers, dampers, lightBlocks, controls }
            } catch (error) {
                console.error('Error fetching parts:', error);
                return { motors: [], freqDrives: [], fans: [], actuators: [], climaCells: [], humidifiers: [], dampers: [], lightBlocks: [], controls: [] }
            }
        }
        getParts().then(({ motors, freqDrives, fans, actuators, climaCells, humidifiers, dampers, lightBlocks, controls }) => {
            const fanSerpart = {
                partNumber: "SERPART",
                description: "",
                price: "",
                additionalData: {
                    hp: "",
                    cfm: 0,
                    staticPressure1: 0,
                    staticPressure05: 0,
                    staticPressure15: 0,
                    staticPressure075: 0,
                    staticPressure125: 0,
                    staticPressure175: 0
                }
            }
            const serPart = {
                partNumber: "SERPART",
                description: "",
                price: "",
                additionalData: {},
            }
            fans.forEach(fan => {
                fan.fanPercent = 100;
                fan.qty = 1;
            });
            setFans([...fans, fanSerpart]);
            setMotors([...motors, serPart]);
            setFreqDrives([...freqDrives, serPart]);
            setActuators([...actuators, serPart]);
            setClimaCells([...climaCells, serPart]);
            setHumidifiers([...humidifiers, serPart]);
            setDampers([...dampers, serPart]);
            setLightBlocks([...lightBlocks, serPart]);
            setControls([...controls, serPart]);
            setRender(true);
        })
    }, []);

    return (<>
        {render && <Box sx={{ mt: 2 }}>
            <PartCollapseCard title="Fans" parts={fan} partType="fan">
                {fan?.length > 0 && fan.map((part) => (
                    <StorageAssessFan
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        parts={fans}
                        partType="fan"
                    />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Motors" parts={motor} partType="motor">
                {motor?.length > 0 && motor.map((part) => (
                    <StorageAssessPart
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        parts={motors}
                        partType="motor"
                        onPartChange={handlePartChange}
                    />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Frequency Drives" parts={freqDrive} partType="freqDrive">
                {freqDrive?.length > 0 && freqDrive.map((part) => (
                    <StorageAssessPart
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        parts={freqDrives}
                        partType="freqDrive"
                        onPartChange={handlePartChange}
                    />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Fresh Air Doors" parts={fad} partType="fad">
                {fad?.length > 0 && fad.map((part) => (
                    <StorageAssessPartOther
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        partType="fad"
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Return Air Openings" parts={returnAirOpening} partType="returnAirOpening">
                {returnAirOpening?.length > 0 && returnAirOpening.map((part) => (
                    <StorageAssessPartOther
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        partType="returnAirOpening"
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Actuators" parts={actuator} partType="actuator">
                {actuator?.length > 0 && actuator.map((part) => (
                    <StorageAssessPart
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        parts={actuators}
                        partType="actuator"
                        onPartChange={handlePartChange}
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="ClimaCell Evaporative Cooler" parts={climaCell} partType="climaCell">
                <StorageAssessClimaCell
                    climaCells={climaCells}
                    edit={edit}
                    onPartChange={handlePartChange}
                />
            </PartCollapseCard>
            <PartCollapseCard title="Humidifiers" parts={humidifier} partType="humidifier">
                {humidifier?.length > 0 && humidifier.map((part) => (
                    <StorageAssessPart
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        parts={humidifiers}
                        partType="humidifier"
                        onPartChange={handlePartChange}
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Dampers" parts={damper} partType="damper">
                {damper?.length > 0 && damper.map((part) => (
                    <StorageAssessPartOther
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        partList={dampers}
                        partType="damper"
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Light Block" parts={lightBlock} partType="lightBlock">
                {lightBlock?.length > 0 && lightBlock.map((part) => (
                    <StorageAssessPartOther
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        partList={lightBlocks}
                        partType="lightBlock"
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Controls" parts={control} partType="control">
                {control?.length > 0 && control.map((part) => (
                    <StorageAssessPart
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        parts={controls}
                        partType="control"
                        onPartChange={handlePartChange}
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
            <PartCollapseCard title="Refrigeration" parts={refrigeration} partType="refrigeration">
                {refrigeration?.length > 0 && refrigeration.map((part) => (
                    <StorageAssessPartOther
                        key={part.indexId}
                        edit={edit}
                        part={part}
                        partType="refrigeration"
                        hideTotals={true}
                        indexId={part.indexId} />
                ))}
            </PartCollapseCard>
        </Box>}
    </>
    );
})

export default StorageAssessParts;