import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material"
import BidBase from "./BidComponents/BidBase";
import SheetsApi from "../../api";
import Doors from "./BidComponents/Doors";
import ClimaCell from "./BidComponents/ClimaCell";
import PartContainer from "./BidComponents/BidContainers/PartContainer";
import Dampers from "./BidComponents/Dampers";
import LightBlock from "./BidComponents/LightBlock";
import Labor from "./BidComponents/BidMisc/Labor";
import Misc from "./BidComponents/BidMisc/Misc";
import GrandTotal from "./BidComponents/BidSubmit/GrandTotal";
import { useParams } from "react-router-dom";
import useBidStoreMain from "../../stores/BidStore/bidStoreMain";
import useBidStoreSub from "../../stores/BidStore/bidStoreSub";
import useBidPartsStore from "../../stores/BidStore/bidPartsStore";
import useBidStoreMisc from "../../stores/BidStore/bidStoreMisc";
import usePartsStore from "../../stores/partsStore";
import useImageStore from "../../stores/imageStore";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import useBidRefrigerationStore from "../../stores/BidStore/bidRefrigerationStore";
import CFMSelector from "./BidComponents/bidCFM/CFMSelector";
import CustomTable from "./BidComponents/BidMisc/CustomTable";

function BidEdit() {
    const { id } = useParams()
    const componentRef = useRef()
    const [renderCheck, setRenderCheck] = useState(false)
    const [renderCheckCfm, setRenderCheckCfm] = useState(false)
    const [fetchedBid, setFetchedBid] = useState({})
    const [resetStateMain, setBidBaseObj] = useBidStoreMain(state =>
        [state.resetState, state.setBidBaseObj])
    const [resetStateSub] = useBidStoreSub(state => [state.resetState])
    const [resetStateMisc] = useBidStoreMisc(state => [state.resetState])
    const [resetStateParts] = useBidPartsStore(state => [state.resetState])
    const [resetStateRefrigeration] = useBidRefrigerationStore(state => [state.resetState])
    const [clearPartsForm, setPartsObj] = usePartsStore(state =>
        [state.clearPartsForm, state.setPartsObj])
    const [setSignature, clearSignature] = useImageStore(state =>
        [state.setSignature, state.clearSignature]);

    useEffect(() => {
        resetStateMain();
        resetStateSub();
        resetStateParts();
        resetStateMisc();
        clearPartsForm();
        resetStateRefrigeration();
        clearSignature();
        async function getSheet() {
            const parts = await SheetsApi.getPartsById({ id, type: "bid_id" })
            const sheet = await SheetsApi.getBidSheet({ id })
            return { sheet, parts };
        }
        getSheet().then(async (obj) => {
            console.log(obj);
            const partsArray = [];
            for (let partObj of obj.parts) {
                let modifiedPart = {
                    qty: partObj.quantity,
                    type: partObj.quantity_type,
                    part: partObj.part_number,
                    description: partObj.description,
                    cost: partObj.cost,
                    isMisc: partObj.ismisc,
                    location: partObj.location,
                    name: partObj.name,
                    invoice: partObj.invoice,
                    po: partObj.po
                }
                partsArray.push(modifiedPart)
            }

            obj.sheet.images.forEach(async (img) => {
                const imageObj = await HandleImage.setImage(img)
                if (img.imageType === 'signature') {
                    if (!img.optionalSig) setSignature(imageObj)
                    else setSignature(imageObj, true)
                }
            })
            setPartsObj(partsArray)
            setFetchedBid(obj.sheet)
            setBidBaseObj(obj.sheet.bidBase)
            setRenderCheck(true)
        })
    }, [])

    return (
        <div ref={componentRef} >
            {renderCheck && <>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <BidBase
                            id={id}
                            edit={true}
                            data={fetchedBid.bidBase}
                        />
                        <CFMSelector
                            fetchedBid={fetchedBid}
                            edit={true}
                            setRenderCheckCfm={setRenderCheckCfm}
                        />
                        {renderCheckCfm && <>
                            <Doors
                                edit={true}
                                doorData={fetchedBid.doors}
                            />
                            <PartContainer
                                category="actuator"
                                title="Actuators"
                                maxParts={2}
                                edit={true}
                                partData={fetchedBid.actuators}
                            />
                            <ClimaCell
                                edit={true}
                                bidData={fetchedBid.bidBase}
                                climaCellData={fetchedBid.climaCell}
                            />
                            <PartContainer
                                category="humidifier"
                                title="Gellert™ Bahnson Humidifiers"
                                maxParts={8}
                                edit={true}
                                partData={fetchedBid.humidifiers}
                            />
                            <Dampers
                                edit={true}
                                damperData={fetchedBid.dampers}
                            />
                            <LightBlock
                                edit={true}
                                lightBlockData={fetchedBid.lightBlock}
                            />
                            <PartContainer
                                category="control"
                                title="Control Panel"
                                maxParts={20}
                                edit={true}
                                partData={fetchedBid.controlPanel}
                            />
                            <Misc
                                title="Hardware and Supplies: (SerParts, Wire, Tie wraps, etc.)"
                                category="hardware"
                                edit={true}
                                miscData={fetchedBid.hardware}
                            />
                            <Labor
                                edit={true}
                                data={fetchedBid}
                            />
                            <Misc
                                title="Equipment Rental: (Scissor Life, Fork Lift, Crane, Etc.)"
                                category="equipment"
                                edit={true}
                                miscData={fetchedBid.equipment}
                            />
                            <Misc
                                title="Sub Contractors: (Concrete, Builder, Electrical, etc.)"
                                category="contractor"
                                edit={true}
                                miscData={fetchedBid.contractors}
                            />
                            <Misc
                                title="Other: (Licenses, Permits, Disposals, Hotels, Meals, etc.)"
                                category="other"
                                edit={true}
                                miscData={fetchedBid.other}
                            />
                            <CustomTable
                                edit={true}
                                customData={fetchedBid.custom}
                            />
                            <Misc
                                title="Options:"
                                category="option"
                                edit={true}
                                miscData={fetchedBid.option}
                            />
                        </>}
                        <GrandTotal componentRef={componentRef} edit={true} />
                    </Grid>
                </Grid >
            </>}
        </div>
    )
}

export default BidEdit;