import React, { useContext, useEffect, useState, useRef } from "react";
import BaseSheets from "../SheetComponents/BaseSheet";
import PartsSheet from "../SheetComponents/PartsSheet";
import TechSheet from "../SheetComponents/TechSheet";
import {
    Box, Grid, Typography, FormControl, Card, CardContent,
    Select, MenuItem, InputLabel, TextareaAutosize
} from "@mui/material/"
import { useTheme } from '@mui/material/styles';
import useSheetsStore from "../../stores/SheetsStore";
import useBaseStore from "../../stores/baseStore";
import usePartsStore from "../../stores/partsStore";
import useTechStore from "../../stores/techStore";
import SheetsApi from "../../api";
import { useHistory, useParams } from "react-router-dom"
import UserContext from "../../Users/UserContext";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import useImageStore from "../../stores/imageStore";
import StageSelector from "../SheetComponents/StageSelector";
import useNotification from "../../Hooks/useNotification";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import DeptSelect from "../SheetComponents/DeptSelect";
import useSettingsStore from "../../stores/settingsStore";
import { checkPermissions } from "../../helpers/permissions";

function ServiceSheets() {
    const componentRef = useRef() 
    const { currentUser } = useContext(UserContext)
    const { id } = useParams()
    const theme = useTheme()
    const messanger = useNotification();
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const history = useHistory()
    const [renderCheckPart, setRenderCheckPart] = useState(false)
    const [renderCheckTech, setRenderCheckTech] = useState(false)
    const [toggle, setToggle] = useState(true)
    const [logs, setLogs] = useState([]);
    const [home] = useSettingsStore((state) => [state.home]);
    const [currstage, setCurrStage] = useState("");
    const [setServiceSheet, serviceSheet, clearServiceSheet, setServiceSheetObj] = useSheetsStore(state =>
        [state.setServiceSheet, state.serviceSheet, state.clearServiceSheet, state.setServiceSheetObj]);
    const [baseSheet, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj]);
    const [partSheet, clearPartsForm, setPartsObj] = usePartsStore(state =>
        [state.parts, state.clearPartsForm, state.setPartsObj]);
    const [techSheet, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj]);
    const [images, addImage, clearImages] = useImageStore(state =>
        [state.images, state.addImage, state.clearImages]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFormSubmit = async function (e) {
        e.preventDefault()
        const permissionsResult = checkPermissions(currentUser.account_level, currentUser.dept, currstage)
        if (!permissionsResult.status) {
            return messanger(permissionsResult.message, "error")
        }
        setIsSubmitting(true);
        const sheetObj = CreateSubmitObj(
            serviceSheet, currentUser, messanger,
            baseSheet, partSheet, techSheet)
        if (sheetObj.error) return setIsSubmitting(false)
        sheetObj.id = id
        sheetObj.sheet.sheetType = "sheet"
        console.log(sheetObj)
        const response = await SheetsApi.editServiceSheet(sheetObj)
        HandleImage.addAndRemove(images, sheetObj)
        setRenderCheckPart(false)
        setRenderCheckTech(false)
        clearBaseForm()
        clearServiceSheet()
        clearPartsForm()
        clearTechForm()
        clearImages()
        messanger(response.message, "success")
        history.push(home);
        return setIsSubmitting(false);
    }

    const handleChange = (e, passedName) => {
        if (!e) return
        const name = e.target.name || passedName
        const value = e.target.value
        return setServiceSheet(name, value)
    }
    const handleToggle = () => {
        setToggle(!toggle)
    }
    useEffect(() => {
        setToggle(true)
        clearServiceSheet()
        clearPartsForm()
        clearBaseForm()
        clearTechForm()
        clearImages()
        async function getServiceSheet() {
            try {
                const serviceSheet = await SheetsApi.getServiceSheet({ id })
                const parts = await SheetsApi.getPartsById({ id, type: "sheet_id" })
                const techs = await SheetsApi.getTechsById({ id, type: "sheet_id" })
                const images = serviceSheet.images
                // fetch location name based on ID and replace. Also Add Id to the obj
                const locationId = serviceSheet.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                serviceSheet.location = locationName.location_name
                serviceSheet.locationId = locationId
                setLogs(serviceSheet.logs)
                return { serviceSheet, parts, techs, images }
            } catch (err) {
                console.log(err)
            }
        }
        getServiceSheet().then(async sheet => {
            console.log(sheet)
            for (let section in sheet) {
                if (section === 'serviceSheet') {
                    const split = sheet[section]
                    let baseFormObj = {
                        creator: split.creator,
                        customerName: split.customer,
                        dateServiced: split.dateServiced,
                        locationName: split.location,
                        po: split.po,
                        requestedBy: split.request_by,
                        total: split.total,
                        dateCreated: split.dateCreated,
                        locationId: split.locationId,
                        company: split.company,
                        dept: split.dept,
                    }
                    let serviceSheetObj = {
                        serviceRequested: split.service_requested,
                        workPerformed: split.work_performed,
                        serviceType: split.service_type,
                        stage: split.stage
                    }
                    setBaseFormObj(baseFormObj)
                    setServiceSheetObj(serviceSheetObj)
                    setCurrStage(split.stage)
                } else if (section === 'parts') {
                    let partsArray = []
                    for (let obj of sheet[section]) {
                        let partObj = {
                            qty: obj.quantity,
                            type: obj.quantity_type,
                            part: obj.part_number,
                            description: obj.description,
                            cost: obj.cost,
                            isMisc: obj.ismisc,
                            location: obj.location,
                            name: obj.name,
                            invoice: obj.invoice,
                            po: obj.po
                        }
                        partsArray.push(partObj)
                    }
                    setPartsObj(partsArray)
                    setRenderCheckPart(true)
                } else if (section === 'techs') {
                    let id = -1
                    for (let obj of sheet[section]) {
                        id++
                        let techObj = {
                            technician: obj.technician,
                            truck: obj.truck,
                            labor: obj.labor,
                            travel: obj.travel,
                            nc: obj.nc,
                            notes: obj.notes
                        }
                        setTechObj(techObj, id)
                    }
                    setRenderCheckTech(true)
                } else if (section === 'images') {
                    for (let img of sheet[section]) {
                        const imageObj = await HandleImage.setImage(img)
                        console.log(imageObj)
                        if (img.imageType === 'img' || img.imageType === 'receipt' || img.imageType === 'pdf') {
                            addImage(imageObj)
                        }
                    }
                }
            }
        })
    }, [])

    return (
        <div ref={componentRef} className="page" >
            <form onSubmit={handleFormSubmit}>
                <Grid container >
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <BaseSheets title="Service" id={id} />
                        <Box
                            style={{ width: "100%" }}
                            mb={1}
                        >
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>Service Type</InputLabel>
                                <Select
                                    label="Service Type"
                                    onChange={handleChange}
                                    id="serviceType"
                                    name="serviceType"
                                    value={serviceSheet.serviceType || ""}
                                    defaultValue=""
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="ventilation">Ventilation</MenuItem>
                                    <MenuItem value="refrigeration">Refrigeration</MenuItem>
                                    <MenuItem value="electrical">Electrical</MenuItem>
                                    <MenuItem value="vfdPump">VFD / Pump</MenuItem>
                                    <MenuItem value="serviceCheck">Service Check</MenuItem>
                                    <MenuItem value="overtimeVent">Overtime Ventilation</MenuItem>
                                    <MenuItem value="overtimeRef">Overtime Refrigeration</MenuItem>
                                    <MenuItem value="largeJob">Large Job</MenuItem>
                                    <MenuItem value="productive">Productive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            style={{ width: "99.5%" }}
                            sx={{ backgroundColor: primary }}
                        >
                            <Typography
                                variant="h5"
                                ml={1}
                                style={{ color: defaultColor }}>
                                Service Requested
                            </Typography>
                        </Box>
                        <Box ml={.25}>
                            <TextareaAutosize
                                minRows={4}
                                style={{
                                    width: "99%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                                aria-label="maximum height"
                                name="serviceRequested"
                                id="serviceRequested"
                                value={serviceSheet.serviceRequested || ""}
                                onChange={handleChange}
                            />
                        </Box>
                        <Box
                            style={{ width: "99.5%" }}
                            sx={{ backgroundColor: primary }}
                        >
                            <Typography
                                ml={1}
                                variant="h5"
                                style={{ color: defaultColor }}>
                                Work Performed & Comments
                            </Typography>
                        </Box>
                        <Box ml={0.25}>
                            <TextareaAutosize
                                minRows={8}
                                style={{
                                    width: "99.0%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                                aria-label="maximum height"
                                name="workPerformed"
                                id="workPerformed"
                                value={serviceSheet.workPerformed || ""}
                                onChange={handleChange}
                            />
                        </Box>
                        {renderCheckPart && <PartsSheet toggle={toggle} />}
                        {renderCheckTech && <TechSheet edit={true} toggle={toggle} />}
                        <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                            <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                <CardContent>
                                    <Box display="flex" justifyContent="left" className="hideForPrint" >
                                        <Box>
                                            <StageSelector
                                                currentUser={currentUser}
                                                handleChange={handleChange}
                                                value={serviceSheet.stage}
                                            />
                                            <DeptSelect />
                                            <SubmitAndPrint
                                                isSubmitting={isSubmitting}
                                                width={300}
                                                height={280}
                                                componentRef={componentRef}
                                                toggle={toggle}
                                                handleToggle={handleToggle}
                                                showToggle={true} />
                                        </Box>
                                        {!currentUser.mobile && renderCheckTech ? <ImageDropZone sheetType="sheet" /> : null}
                                    </Box>
                                    {currentUser.mobile && renderCheckTech ? <ImageDropZone sheetType="sheet" /> : null}
                                </CardContent>
                            </Card>
                            {logs.length > 0 &&
                                <HistoryCard toggle={toggle} baseSheet={baseSheet} setLogs={setLogs} logs={logs} />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default ServiceSheets