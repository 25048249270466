import React from "react";
import { Box, MenuItem, Typography, Tooltip, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CommentIcon from '@mui/icons-material/Comment';
import SheetsApi from "../api";
import useSettingsStore from "../stores/settingsStore";
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

function MessageNotification({ notification, handleFullClose, indexId }) {
    const theme = useTheme();
    const background = theme.palette.background.default;
    const [removeNotification] = useSettingsStore(state => [state.removeNotification]);
    const history = useHistory();

    const handleRedirect = function () {
        handleFullClose();
        let sheetPath = notification.sheetType;
        if (notification.sheetType === "sheet") sheetPath = "sheets";
        if (notification.sheetType === "sales") sheetPath = "salesOrders";
        if (notification.sheetType === "chemAppAgreement") sheetPath = "chemApps/Agreement"
        if (notification.sheetType === "sprayBarAgreement") sheetPath = "sprayBar/Agreement"
        if (notification.sheetType === "disinfectantAgreement") sheetPath = "disinfectant/Agreement"
        history.push(`/${sheetPath}/edit/${notification.sheetId}`);
    };

    const handleFollowupDelete = async () => {
        await SheetsApi.removeNotification({
            id: notification.id,
            sheetId: notification.sheetId,
            sheetType: notification.sheetType,
            message: true,
        });

        removeNotification(indexId);
    };

    return (
        <Tooltip title={`Click to view message in ${notification.sheetType} ID# ${notification.sheetId}`}>
            <MenuItem
                style={{ background: background }}
                disableRipple
                onClick={handleRedirect}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1.5,
                    borderRadius: '8px',
                }}
            >
                <Box display="flex" alignItems="center" gap={2}>
                    <CommentIcon color="info" />
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 14 }}>
                        Alert # {notification.sheetId}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {notification.message}
                    </Typography>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleFollowupDelete();
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </MenuItem>
        </Tooltip>
    );
}

export default MessageNotification;