import React from "react";
import { browserName } from "react-device-detect";
import {
    Button, Box, useTheme,
    ToggleButton, ToggleButtonGroup,
} from "@mui/material"
import ReactToPrint from "react-to-print";
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

function SubmitAndPrint({ componentRef, showToggle, toggle, handleToggle, toggleTerms, isSubmitting }) {
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const pageStyleSelector = function (pageStyle) {
        const newStyles = `
        @media print {   
            .page {
                size: A4;
                margin-bottom: 1in;
                width: ${pageStyle.width}%;
                scale: ${pageStyle.scaleLength} ${pageStyle.scaleWidth};
                transform-origin: top left;
                max-height: 100vh;
            }
            .nextPage {
                page-break-before: always;
            }
            .nextPageNoMargin {
                page-break-before: always;
            }
            .hideForPrint {
                display: none !important;
            }
            .displayColumns3 {
                display: grid !important;
                grid-template-columns: repeat(3, 450px) !important;
            }
            .displayColumns4 {
                display: grid !important;
                grid-template-columns: repeat(4, 350px) !important;
            }
            .showForPrint {
                display: block !important;
            }
        }`
        return newStyles;
    }
    const detectBrowser = function () {
        if (browserName === "Chrome") {
            return {
                width: "150",
                scaleLength: "0.7",
                scaleWidth: "1"
            }
        } else if (browserName === "Edge") {
            return {
                width: "150",
                scaleLength: "0.7",
                scaleWidth: "1"
            }
        } else if (browserName === "Opera") {
            return {
                width: "245",
                scaleLength: "0.6",
                scaleWidth: "0.9"
            }
        } else {
            return {
                width: "100",
                scaleLength: "1",
                scaleWidth: "1"
            }
        }
    }

    const pageStyle2 = pageStyleSelector(detectBrowser())

    const beforePrint = async function () {
        if (toggleTerms) {
            //this is exclusive to the chemAppAgreement
            return await toggleTerms(true);
        }
    }
    
    return (
        <>
            <Box
                mt={1}
                display="flex"
                alignItems="center"
                className="hideForPrint"
            >
                {showToggle && <Box sx={{ display: 'inline' }}>
                    <ToggleButtonGroup
                        onChange={handleToggle}>
                        <ToggleButton
                            value={toggle}>
                            {toggle ?
                                <ToggleOnOutlinedIcon sx={{ fontSize: 32 }} /> :
                                <ToggleOffOutlinedIcon sx={{ fontSize: 32 }} />}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>}
                <Box ml={1} display="inline">
                    <Button
                        disabled={isSubmitting}
                        style={{ color: defaultColor, fontWeight: "bold" }}
                        variant="contained"
                        size='large'
                        type="submit">
                        Submit
                    </Button>
                </Box>
                <Box display="inline" ml={1}>
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                size='large'
                                variant="contained"
                                style={{ color: defaultColor, fontWeight: "bold" }}>
                                Print
                            </Button>
                        )}
                        onBeforeGetContent={beforePrint}
                        content={() => { return componentRef.current; }}
                        pageStyle={pageStyle2}
                    />
                </Box>
            </Box>
        </>
    )
}

export default SubmitAndPrint;

