import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import StorageAssessPrintTable from './StorageAssessPrintTable';
import StorageAssessPrintOtherTable from './StorageAssessPrintOtherTable';
import asCoHeader from "../../../images/BidHeaders/asCoHeader.jpg";
import asNwHeader from "../../../images/BidHeaders/asNwHeader.jpg";
import asTfHeader from "../../../images/BidHeaders/asTfHeader.jpg";


function StorageAssessDocument({ data, currentUser, location, customer, styles }) {
    // Helper function to chunk components into pages
    const [pages, setPages] = useState([]);
    const createPages = (components) => {
        const pages = [];
        let currentPage = [];
        let currentHeight = 0;
        const maxHeight = 650; // Approximate max height per page in points

        components.forEach(component => {
            // Estimate component height (adjust these values based on your needs)
            const estimatedHeight = {
                header: 100,
                details: 200,
                fanConfig: 150,
                componentSection: 100 + (component.data?.length || 0) * 30
            }[component.type];

            if (currentHeight + estimatedHeight > maxHeight) {
                pages.push(currentPage);
                currentPage = [];
                currentHeight = 0;
            }

            currentPage.push(component);
            currentHeight += estimatedHeight;
        });

        if (currentPage.length > 0) {
            pages.push(currentPage);
        }

        return pages;
    };
    useEffect(() => {
        setPages(createPages(allComponents));
    }, [])

    const setCompanyHeader = () => {
        if (!currentUser) return null;
        switch (currentUser.company) {
            case "Agri-Stor Company North West":
                return asNwHeader;
            case "Agri-Stor Company Colorado":
                return asCoHeader;
            case "Agri-Stor Companies":
                return asTfHeader;
            default:
                return asTfHeader;
        }
    }

    const allComponents = [
        { type: 'header', content: data.storageSettings.site_name },
        { type: 'details', content: data.storageAssessmentHead },
        { type: 'fanConfig', data: data.fan },
        ...[
            { type: 'componentSection', title: 'Motors', data: data.motor },
            { type: 'componentSection', title: 'Frequency Drives', data: data.freqDrive },
            { type: 'componentSectionOther', title: 'Fresh Air Doors', data: data.fad },
            { type: 'componentSectionOther', title: 'Return Air Opening', data: data.returnAirOpening },
            { type: 'componentSection', title: 'Actuators', data: data.actuator },
            { type: 'componentSection', title: 'Clima Cell', data: data.climaCell },
            { type: 'componentSection', title: 'Humidifiers', data: data.humidifier },
            { type: 'componentSectionOther', title: 'Dampers', data: data.damper },
            { type: 'componentSectionOther', title: 'Light Block', data: data.lightBlock },
            { type: 'componentSection', title: 'Controls', data: data.control },
            { type: 'componentSectionOther', title: 'Refrigeration', data: data.refrigeration },
        ].filter(comp => comp.data && comp.data.length > 0)
    ];

    const GridItem = function ({ label, value, color }) {
        let textColor = ""
        if (color) {
            const variance = parseFloat(value);
            if (variance >= 0) {
                textColor = { ...styles.value, color: 'green' };
            } else if (variance >= -10) {
                textColor = { ...styles.value, color: '#8B8000' }; // Dark yellow
            } else {
                textColor = { ...styles.value, color: 'red' };
            }
        }

        return <View style={styles.gridItem}>
            <Text>
                <Text style={styles.label}>{label}: </Text>
                <Text style={textColor}>{value}</Text>
            </Text>
        </View>
    };

    return (
        <Document>
            {pages.map((pageComponents, pageIndex) => (
                <Page key={pageIndex} size="A4" style={styles.page}>
                    {pageComponents.map((component, compIndex) => {
                        switch (component.type) {
                            case 'header':
                                return pageIndex === 0 ? (
                                    <View key={compIndex} style={styles.header}>
                                        <Image src={setCompanyHeader()} />
                                        <Text style={styles.value}>{component.content}</Text>
                                    </View>
                                ) : null;

                            case 'details':
                                return (
                                    <View key={compIndex} style={styles.section}>
                                        <Text style={styles.sectionTitle}>{customer} - {location} Assessment Details </Text>
                                        <View style={styles.grid}>
                                            <GridItem label="Static Pressure" value={data.storageAssessmentHead.staticPressure} />
                                            <GridItem
                                                label="Weight"
                                                value={data.storageAssessmentHead.selectWeight === 'cwt' ?
                                                    `${data.storageAssessmentHead.cwt} CWT` :
                                                    `${data.storageAssessmentHead.tons} Tons`}
                                            />
                                            <GridItem label="Plenum Variance" color={true} value={`${data.varianceTotals.plenumVariance}%`} />
                                            <GridItem label="Plenum Sq.Ft" value={data.storageAssessmentHead.plenumSqFt} />
                                            <GridItem label="Duct Size" value={data.storageAssessmentHead.ductSize} />
                                            <GridItem label="Number of Ducts" value={data.storageAssessmentHead.numberOfDucts} />
                                            <GridItem label="Duct Variance" color={true} value={`${data.varianceTotals.ductVariance}%`} />
                                            <GridItem label="Duct Type" value={data.storageAssessmentHead.ductType} />
                                            <GridItem label="Refrigeration Variance" color={true} value={`${data.varianceTotals.refVariance}%`} />
                                            <GridItem label="Current Refrigeration Tonnage" value={data.storageAssessmentHead.currRefTonage} />
                                            <GridItem label="Voltage" value={data.storageAssessmentHead.voltage} />
                                        </View>
                                    </View>
                                );

                            case 'fanConfig':
                                return component.data && component.data.length > 0 ? (
                                    <View key={compIndex} style={styles.section}>
                                        <Text style={styles.sectionTitle}>Fans</Text>
                                        <StorageAssessPrintTable data={component.data} />
                                    </View>
                                ) : null;

                            case 'componentSection':
                                return component.data && component.data.length > 0 ? (
                                    <View key={compIndex} style={styles.section}>
                                        <Text style={[styles.sectionTitle, { fontSize: 14 }]}>{component.title}</Text>
                                        <StorageAssessPrintTable data={component.data} />
                                    </View>
                                ) : null;

                            case 'componentSectionOther':
                                return component.data && component.data.length > 0 ? (
                                    <View key={compIndex} style={styles.section}>
                                        <Text style={[styles.sectionTitle, { fontSize: 14 }]}>{component.title}</Text>
                                        <StorageAssessPrintOtherTable data={component.data} />
                                    </View>
                                ) : null;
                        }
                    })}

                    {pageIndex === pages.length - 1 && (
                        <View style={styles.footer}>
                            <Text>Generated on {new Date().toLocaleDateString()}</Text>
                            <Text style={{ marginTop: 5 }}>Page {pageIndex + 1} of {pages.length}</Text>
                        </View>
                    )}
                </Page>
            ))}
        </Document>
    );
};

export default StorageAssessDocument;