import React, { useContext, useState, useEffect } from "react";
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, Button, Typography, FormControl,
    Select, MenuItem, InputLabel, Box, Divider,
    TextareaAutosize
} from "@mui/material";
import UserContext from "../Users/UserContext";
import dayjs from 'dayjs';
import CategorizedInputs from "./CategorizedInputs"
import SheetsApi from "../api";
import useImageStore from "../stores/imageStore";
import useNotification from "../Hooks/useNotification";
import HandleImage from "../Sheets/SheetComponents/images/imageUtilities/HandleImage";
import DateInput from "../Sheets/SheetComponents/DateInput";

function ReceiptPopUp({ open, setOpen, receipt, sheet, skipDelete }) {
    const sheetPass = sheet;
    const receiptPass = receipt;
    const { currentUser } = useContext(UserContext)
    const [customers, setCustomers] = useState([])
    const [users, setUsers] = useState([])
    const messanger = useNotification()
    const [receiptState, setReceipt, setReceiptObj, updateImageToReceipt,
        clearReceipt] = useImageStore(state => [state.receipt, state.setReceipt,
        state.setReceiptObj, state.updateImageToReceipt, state.clearReceipt]);

    const saveReceipt = async function (e) {
        e.preventDefault();
        e.stopPropagation();
        setReceiptObj({ ...receiptState })
        const newReceipt = {
            ...receipt,
            imageType: 'receipt',
            receiptData: {
                ...receiptState,
                creator: currentUser.username,
                receiptDate: receiptState.receiptDate ? receiptState.receiptDate : dayjs().format('YYYY/MM/DD'),
            }
        }
        updateImageToReceipt(newReceipt);
        if (newReceipt.edit) {
            const response = await SheetsApi.updateReceipt(newReceipt.receiptData)
            messanger(response.message, response.error ? "error" : "success")
        } else if (!sheet) {
            const response = await HandleImage.addAndRemove([newReceipt], {}, skipDelete)
            messanger(response.message, response.error ? "error" : "success")
        }
        return setOpen(false);
    }

    useEffect(() => {
        async function getCustomersAndTechs() {
            try {
                let customers;
                let users = await SheetsApi.getAllUsers()
                if (currentUser.account_level === 'Admin') {
                    customers = await SheetsApi.getAllCustomers()
                } else {
                    const companyName = currentUser.company
                    customers = await SheetsApi.getAllCustomersByCompany({ companyName })
                }
                return { customers, users }
            } catch (err) {
                console.error(err)
            }
        }
        getCustomersAndTechs().then(({ customers, users }) => {
            setUsers(users);
            setCustomers(customers);
        })
    }, [])

    useEffect(() => {
        if (open) {
            setReceiptObj(receipt.receiptData);
        } else {
            clearReceipt();
        }
    }, [open]);

    return (
        <>
            <Dialog fullWidth={true} sx={{ maxHeight: '80vh' }} open={open} onClose={() => setOpen(false)}>
                <Box display="flex" justifyContent="space-between">
                    <DialogTitle>Receipt Form</DialogTitle>
                    <DialogTitle>{receiptState.id}</DialogTitle>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <DialogContent>
                    <form onSubmit={saveReceipt}>
                        <Box>
                            <Box display={currentUser.mobile ? '' : 'flex'} justifyContent={currentUser.mobile ? '' : 'space-between'}>
                                <FormControl sx={{ mt: 1, width: currentUser.mobile ? '100%' : "18ch" }}>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        required
                                        id="category"
                                        name="category"
                                        value={receiptState.category || ""}
                                        label="Category"
                                        onChange={(e) => setReceipt(e.target.name, e.target.value)}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value="part">Part</MenuItem>
                                        <MenuItem value="fuel">Fuel</MenuItem>
                                        <MenuItem value="largeJob">Large Job</MenuItem>
                                        <MenuItem value="entertainment">Entertainment</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width: currentUser.mobile ? '100%' : "24ch", mt: 1 }}>
                                    <InputLabel>Department</InputLabel>
                                    <Select
                                        required
                                        id="receiptDept"
                                        name="receiptDept"
                                        value={receiptState.receiptDept || ""}
                                        label="Department"
                                        onChange={(e) => setReceipt(e.target.name, e.target.value)}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        <MenuItem value="sales">Sales</MenuItem>
                                        <MenuItem value="service">Service</MenuItem>
                                        <MenuItem value="chemApps">Chemical Applications</MenuItem>
                                        <MenuItem value="labs">Storage Management</MenuItem>
                                        <MenuItem value="admin">Admin</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box sx={{ mt: 1, width: currentUser.mobile ? '100%' : "18ch" }}>
                                    <DateInput
                                        state={receiptState.receiptDate}
                                        setState={setReceipt}
                                        variableName="receiptDate"
                                        label="Date"
                                        required={true}
                                    />
                                </Box>
                            </Box>
                            <Box display="flex">
                                {!currentUser.mobile &&
                                    <Box m={1} display="flex" sx={{ maxHeight: '15ch', maxWidth: '10ch' }}>
                                        <img
                                            src={receipt.thumbnail}
                                            alt={`img`}
                                            loading="lazy"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Box>
                                }
                                <Box width="100%">
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography ml={4} variant="h6">
                                            Description
                                        </Typography>
                                        <Divider sx={{
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                            flexGrow: 1,
                                            borderColor: 'black',
                                        }} />
                                    </Box>
                                    <Box sx={{ mb: 1, mr: 3 }}>
                                        <TextareaAutosize
                                            name="description"
                                            id="description"
                                            required
                                            minRows={currentUser.mobile ? 5 : 4}
                                            style={{
                                                width: "100%",
                                                outline: '1px solid #000',
                                                fontSize: "16px",
                                            }}
                                            value={receiptState.description || ""}
                                            onChange={(e) => setReceipt(e.target.name, e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {receiptState.category !== '' &&
                                <CategorizedInputs
                                    customers={customers}
                                    users={users}
                                    category={receiptState.category}
                                    receipt={receiptPass}
                                    sheet={sheetPass}
                                />
                            }
                        </Box>
                        <DialogActions>
                            <Button type="submit">Save</Button>
                            <Button onClick={() => setOpen(false)}>Cancel</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ReceiptPopUp;