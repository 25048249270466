import React, { useEffect } from "react";
import useFields from "../../../Hooks/useFields";
import useStorageAssessmentStore from "../../../stores/storageAssessmentStore";
import { Box, TextField } from "@mui/material";

function StorageAssessVariance({ formData, partType, indexId, setLocalTotals, localTotals }) {
    const [updatePart, calculateTotals, removeLine, totalSqFtDoors, getVarianceColor,
        totalSqFtReturnAirOpening, totalSqFtClimaCell, totalCfm, totalSqFtDamper, totalSqFtLightBlock,
        totalSqFtReq] = useStorageAssessmentStore(state => [state.updatePart,
        state.calculateTotals, state.removeLine, state.totals.totalSqFtDoors, state.getVarianceColor,
        state.totals.totalSqFtReturnAirOpening, state.totals.totalSqFtClimaCell, state.totals.totalCfm,
        state.totals.totalSqFtDamper, state.totals.totalSqFtLightBlock, state.totals.totalSqFtReq]);

    useEffect(() => {
        updatePart(partType, indexId, formData);
        if (formData.qty && formData.height && formData.width) {
            let totalSqFtParts;
            if (partType === 'climaCell') {
                totalSqFtParts = parseFloat(formData.qty * (formData.height * formData.width)).toFixed(1)
            } else {
                const sqFtPerPart = parseFloat(((formData.height * formData.width) / 144).toFixed(1));
                totalSqFtParts = parseFloat((sqFtPerPart * formData.qty).toFixed(1));
                setLocalTotals("invalidInput", "sqFtPerPart", sqFtPerPart);
            }
            setLocalTotals("invalidInput", "totalSqFtParts", totalSqFtParts);
            calculateTotals(partType);
        }
    }, [formData]);

    useEffect(() => {
        let ratio = 0;
        let newTotalSqFtReq = totalSqFtReq;
        if (partType === 'fad') {
            ratio = totalSqFtDoors / totalSqFtReq;
        } else if (partType === 'returnAirOpening') {
            ratio = totalSqFtReturnAirOpening / totalSqFtReq;
        } else if (partType === 'damper') {
            newTotalSqFtReq = totalCfm / 1250
            ratio = totalSqFtDamper / newTotalSqFtReq;
        } else if (partType === 'lightBlock') {
            ratio = totalSqFtLightBlock / totalSqFtReq;
        } else if (partType === 'climaCell') {
            if (formData.depth === 12) {
                newTotalSqFtReq = totalCfm / 650;
            } else if (formData.depth === 18) {
                newTotalSqFtReq = totalCfm / 550;
            }
            ratio = totalSqFtClimaCell / newTotalSqFtReq
        }
        const inversePercentage = ((1 - ratio) * -100).toFixed(0);

        setLocalTotals("invalidInput", "variance", inversePercentage);
        setLocalTotals("invalidInput", "totalSqFtReq", newTotalSqFtReq.toFixed(1));
    }, [totalSqFtDoors, totalSqFtReq, totalSqFtReturnAirOpening, totalSqFtClimaCell,
        totalSqFtDamper, totalSqFtLightBlock,]);


    return (<>
        <Box mt={2} ml={1}>
            <TextField
                name="totalSqFtReq"
                id="totalSqFtReq"
                label="Total Sq. Ft. Required"
                type="number"
                variant="standard"
                value={localTotals.totalSqFtReq || ""}
                sx={{ width: "15ch", mr: 1 }}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                name="totalSqFtParts"
                id="totalSqFtParts"
                label="Total Sq. Ft."
                variant="standard"
                value={localTotals.totalSqFtParts || ""}
                sx={{ width: "15ch", mr: 1 }}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                name="variance"
                id="variance"
                label="Variance"
                type="number"
                variant="standard"
                value={localTotals.variance || ""}
                sx={{
                    width: "10ch", mr: 1,
                    '& .MuiInputBase-input': {
                        fontWeight: 'bold',
                        color: getVarianceColor(localTotals.variance)
                    },
                    '& .MuiInputLabel-root': {
                        fontWeight: 'bold',
                        color: getVarianceColor(localTotals.variance)
                    }
                }
                }
                InputProps={{ readOnly: true, endAdornment: <span>%</span> }}
                InputLabelProps={{ shrink: true }}
            />
        </Box>
    </>)
}

export default StorageAssessVariance;