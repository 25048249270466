import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Typography, Box, Divider, Collapse, IconButton,
} from '@mui/material'
import AddCommentIcon from '@mui/icons-material/AddComment';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import AddMessage from './AddMessage';
import GradeIcon from '@mui/icons-material/Grade';
import ArchiveIcon from '@mui/icons-material/Archive';
import CommentIcon from '@mui/icons-material/Comment';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ListIcon from '@mui/icons-material/List';

function HistoryCard({ baseSheet, setLogs, logs, toggle }) {
    const theme = useTheme();
    const defautBackground = theme.palette.background.default;
    const blue = theme.palette.secondary.alter
    const [render, setRender] = useState(false);
    const [messageToggle, setMessageToggle] = useState(false);
    const [recentLog, setRecentLog] = useState({});
    const [readyForInvDate, setReadyForInvDate] = useState("");

    dayjs.extend(timezone);
    dayjs.extend(utc);

    useEffect(() => {
        logs.sort((a, b) => {
            // sorts by date
            const dateA = new Date(a.timeStamp);
            const dateB = new Date(b.timeStamp);
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            return 0;
        });
        if (logs.length > 0) {
            setRecentLog(logs[0]);
        }
        logs.forEach((log) => {
            const readableDate = dayjs(log.timeStamp).tz('America/Denver').format('MM-DD-YYYY h:mm A');
            log.readableDate = readableDate + " MNT"
            if (log.stage === 'new') {
                log.generatedMessage = `Created by ${log.actionUser}`;
            } else if (log.message) {
                log.customMessage = `${log.actionUser}: ${log.message}`;
            } else if (log.stage === 'archive') {
                log.generatedMessage = `Sent to the archive by ${log.actionUser}`;
            } else {
                log.generatedMessage = `Updated by ${log.actionUser} in ${log.stage}`;
            } if (log.stage === 'Ready for Invoicing' && !readyForInvDate) {
                setReadyForInvDate(log.readableDate);
            }
        })
        setRender(true)
    }, [])

    const iconFinder = function (log) {
        if (log.stage === "new") return <GradeIcon color="primary" />
        else if (log.stage === 'archive') return <ArchiveIcon color="primary" />
        else if (log.customMessage) return <CommentIcon color="info" />
        else if (log.generatedMessage) return <DynamicFeedIcon />;
    }

    const handleAddMessage = function (log) {
        const readableDate = dayjs(log.timeStamp).tz('America/Denver').format('MM-DD-YYYY h:mm A');
        log.readableDate = readableDate + " MNT"
        log.customMessage = `${log.actionUser}: ${log.message}`;
        setLogs([log, ...logs])
    }


    return (
        <>
            {render && <>

                {toggle && <Typography sx={{ display: 'none' }} className="showForPrint">
                    Ready for Invoice: {readyForInvDate}
                </Typography>}
                <Box
                    className='hideForPrint'
                    mt={1} ml={1} display="flex"
                    flexDirection={{ xs: "column", sm: 'row' }}
                >
                    <Card sx={{
                        width: '44ch',
                        height: '400px',
                        overflowY: 'auto',
                        backgroundColor: defautBackground,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                    }}>
                        <Box justifyContent="space-between" display="flex">
                            <Typography
                                sx={{ m: 3, fontSize: '1.5rem', fontWeight: 'bold', color: '#333' }}
                                variant='h4'>
                                Sheet History
                            </Typography>
                            <IconButton sx={{ mr: 3, mt: 2 }} onClick={() => setMessageToggle(!messageToggle)}>
                                {messageToggle ?
                                    <ListIcon sx={{ fontSize: 40 }} /> :
                                    <AddCommentIcon sx={{ fontSize: 40 }} />
                                }
                            </IconButton>
                        </Box>
                        <CardContent>
                            <Divider />
                            <Collapse in={messageToggle}>
                                <AddMessage
                                    baseSheet={baseSheet}
                                    handleAddMessage={handleAddMessage}
                                    setMessageToggle={setMessageToggle}
                                    recentLog={recentLog} />
                            </Collapse>
                            <Collapse in={!messageToggle}>
                                {logs.map((log, index) => (
                                    <Card key={log.id || index + 'Special'} sx={{
                                        mb: 2,
                                        p: 2,
                                        borderRadius: '8px',
                                        boxShadow: 1,
                                        border: log.customMessage ? `2px solid ${blue}` : 'none'
                                    }}
                                    >
                                        <Box display="flex" alignItems="center" gap={2}>
                                            {iconFinder(log)}
                                            <Box flex={1}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        color: 'text.primary',
                                                        fontWeight: log.stage === 'new' ? 500 : 400
                                                    }}
                                                >
                                                    {log.customMessage ? log.customMessage : log.generatedMessage}
                                                </Typography>
                                                <Divider sx={{ mt: 0.5 }} />
                                                <Typography
                                                    sx={{
                                                        fontSize: '0.9rem',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    {log.actionUser} • {log.readableDate}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Card>
                                ))}
                            </Collapse>
                        </CardContent>
                    </Card>
                </Box >
            </>
            }
        </>
    )
}

export default HistoryCard;

