import React, { useEffect } from "react";
import {
    TextField, FormControl, InputLabel, Stack,
    Select, MenuItem, TableRow, TableCell, IconButton,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import useFields from "../../Hooks/useFields";
import usePartsStore from "../../stores/partsStore";
import useNotification from "../../Hooks/useNotification";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function Part({ toggle, calculator, partsList, item, id }) {
    const INITIAL_STATE = {
        isMisc: false,
        qty: "",
        type: "",
        part: "",
        description: "",
        cost: "",
        location: "",
        name: "",
        invoice: "",
        po: ""
    }
    const notification = useNotification()
    const [parts, setPart, clearPart] = usePartsStore(state =>
        [state.parts, state.setPart, state.clearPart])
    const [formData, handleLocalPart, resetFormData] = useFields(INITIAL_STATE)
    const miscParts = [
        { part_number: 'MISC. PART', description: "" },
        { part_number: 'SERPART', description: "" },
        { part_number: 'MISC. WIRE', description: "" }
    ]
    const handleChange = function (e, passedName, id) {
        if (!e) {
            return;
        }
        const value = e.target.value;
        const isPart = passedName === "part";
        const isDescription = passedName === "description";
        const miscValues = ['SERPART', 'MISC. PART', 'MISC. WIRE'];
        const isMiscValue = miscValues.includes(value);

        if ((isPart && !isMiscValue) || (isDescription && !isMiscValue)) {
            const keyToFind = isPart ? 'part_number' : 'description';
            const partObj = partsList.find(obj => obj[keyToFind] === value);
            if (partObj) {
                const setValue = isPart ? partObj.description : partObj.part_number;
                handleLocalPart("invalidInput", isPart ? "description" : "part", setValue);
                setPart(isPart ? "description" : "part", setValue, id);
            } else if (isDescription && partObj === undefined) {
                setPart("description", value, id);
            } else if (value === "") {
                // do nothing
            } else {
                setPart(passedName, "", id);
                handleLocalPart("invalidInput", passedName, "");
                return notification("There was an error adding that part to the page. Please make sure that it is a valid part", "error");
            }
        }
        return setPart(passedName, value, id);
    }

    const removePart = function (e) {
        e.preventDefault()
        resetFormData()
        clearPart(id)
    }

    useEffect(() => {
        if (item.part !== "") {
            for (let field in item) {
                handleLocalPart("invalidInput", field, item[field])
            }
            calculator()
        }
    }, [])

    useEffect(() => {
        calculator()
    }, [parts[id].cost, parts[id].qty])

    return (
        <>
            <TableRow sx={{
                '&:hover': { bgcolor: 'action.hover' },
                transition: 'background-color 0.2s',
            }}>
                <TableCell>
                    <TextField
                        size="small"
                        name="qty"
                        label="QTY"
                        type="number"
                        value={parts[id].qty || ""}
                        onChange={(e) => handleChange(e, "qty", id)}
                        sx={{
                            width: "70px",
                        }}
                    />
                </TableCell>
                <TableCell>
                    <FormControl size="small" sx={{ minWidth: 90 }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            onChange={handleLocalPart}
                            onBlur={(e) => handleChange(e, "type", id)}
                            value={formData.type || ""}
                            id="type"
                            name="type"
                            sx={{ width: "100%", minWidth: "12ch", maxWidth: "12ch", }}
                            defaultValue=""
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="feet">Feet</MenuItem>
                            <MenuItem value="pieces">Pieces</MenuItem>
                            <MenuItem value="inches">Inches</MenuItem>
                            <MenuItem value="lbs">LBS</MenuItem>
                            <MenuItem value="lot">LOT</MenuItem>
                            <MenuItem value="gal">GAL</MenuItem>
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell sx={{ paddingLeft: 1, paddingRight: 0 }}>
                    <Autocomplete
                        size="small"
                        disableClearable
                        inputValue={formData.part}
                        onInputChange={(e) => handleLocalPart(e, "part")}
                        onBlur={(e) => handleChange(e, "part", id)}
                        name="part"
                        id="part"
                        options={item.isMisc ? miscParts : partsList}
                        getOptionLabel={(option) => option.part_number}
                        isOptionEqualToValue={(option, value) => option.part_number === value.part_number}
                        filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.part_number?.toLowerCase().includes(inputValue) ||
                                    option.description?.toLowerCase().includes(inputValue)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="part"
                                sx={{ input: { background: "white", fontSize: "0.8rem" }, width: "24ch" }}
                                fullWidth
                                id="part2"
                                label="Part #"
                            />
                        )}
                    />
                </TableCell>
                <TableCell sx={{ paddingLeft: 1, paddingRight: 0 }}>
                    {item.isMisc ?
                        <TextField
                            size="small"
                            name="description"
                            id="description"
                            label="Description"
                            value={formData.description || ""}
                            onBlur={(e) => handleChange(e, "description", id)}
                            onChange={(e) => handleLocalPart(e, "description")}
                            sx={{ width: "100%", minWidth: "50ch" }}
                        />
                        : <Autocomplete
                            size="small"
                            freeSolo={true}
                            disableClearable
                            inputValue={formData.description}
                            onInputChange={(e) => handleLocalPart(e, "description")}
                            onBlur={(e) => handleChange(e, "description", id)}
                            name="description"
                            id="description"
                            options={item.isMisc ? miscParts : partsList}
                            getOptionLabel={(option) => option.description}
                            isOptionEqualToValue={(option, value) =>
                                option.part_number === value.part_number &&
                                option.description === value.description
                            }
                            filterOptions={(options, state) => {
                                const inputValue = state.inputValue.toLowerCase();
                                return options.filter(
                                    (option) =>
                                        option.description?.toLowerCase().includes(inputValue) ||
                                        option.part_number?.toLowerCase().includes(inputValue)
                                );
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={`${option.part_number}-DESCRIPTION`}>
                                    {option.description}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    name="description"
                                    {...params}
                                    sx={{ width: "100%", minWidth: "50ch" }}
                                    label="Description"
                                />
                            )}
                        />}
                </TableCell>
                {toggle && (<>
                    <TableCell>
                        <TextField
                            size="small"
                            name="cost"
                            type="number"
                            label="Price"
                            value={parts[id].cost || ""}
                            onChange={(e) => handleChange(e, "cost", id)}
                            sx={{
                                width: "100px",
                            }}
                        />
                    </TableCell>
                    <TableCell align="right" sx={{ width: 40 }}>
                        <IconButton
                            size="small"
                            onClick={removePart}
                            sx={{
                                color: 'error.light',
                                '&:hover': { color: 'error.main' }
                            }}
                        >
                            <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </>)}
            </TableRow>
            {item.isMisc && toggle ?
                <TableRow sx={{
                    '&:hover': { bgcolor: 'action.hover' },
                    transition: 'background-color 0.2s',
                }}>
                    <TableCell colSpan={6} sx={{ py: 1 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <TableCell sx={{ paddingLeft: 1, paddingRight: 0 }} >
                                <FormControl size="small">
                                    <InputLabel>Location</InputLabel>
                                    <Select
                                        label="Location"
                                        onChange={handleLocalPart}
                                        onBlur={(e) => handleChange(e, "location", id)}
                                        value={formData.location || ""}
                                        id="location"
                                        name="location"
                                        sx={{ width: '11ch' }}
                                        inputProps={{ style: { fontSize: "0.8rem" } }}
                                        defaultValue=""
                                    >
                                        <MenuItem value="truck">Truck</MenuItem>
                                        <MenuItem value="vendor">Vendor</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell >
                            {item.location === "vendor" ?
                                <>
                                    <TableCell sx={{ paddingLeft: 1, paddingRight: 0 }}>
                                        <TextField
                                            size="small"
                                            label="P.O."
                                            name="po"
                                            id="po"
                                            sx={{ width: "12ch" }}
                                            inputProps={{ style: { fontSize: "0.8rem" } }}
                                            onChange={handleLocalPart}
                                            onBlur={(e) => handleChange(e, "po", id)}
                                            value={formData.po || ""} />
                                    </TableCell>
                                    <TableCell sx={{ paddingLeft: 1, paddingRight: 0 }}>
                                        <TextField
                                            size="small"
                                            label="Name"
                                            name="name"
                                            id="name"
                                            sx={{ width: "24ch" }}
                                            inputProps={{ style: { fontSize: "0.8rem" } }}
                                            onChange={handleLocalPart}
                                            onBlur={(e) => handleChange(e, "name", id)}
                                            value={formData.name || ""} />
                                    </TableCell>
                                    <TableCell sx={{ paddingLeft: 1, paddingRight: 0 }}>
                                        <TextField
                                            size="small"
                                            label="Invoice"
                                            name="invoice"
                                            id="invoice"
                                            sx={{ width: "25ch" }}
                                            inputProps={{ style: { fontSize: "0.8rem" } }}
                                            onChange={handleLocalPart}
                                            onBlur={(e) => handleChange(e, "invoice", id)}
                                            value={formData.invoice || ""} />
                                    </TableCell>
                                </>
                                : null}
                        </Stack>
                    </TableCell>
                </TableRow>
                : null}
        </>
    )

}
export default React.memo(Part)