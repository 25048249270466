import React, { useState, useEffect } from "react";
import { Typography, Button, Box, Alert } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useNotification from "../../../Hooks/useNotification";

function UploadParts({ savePart }) {
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [abort, setAbort] = useState(false);
    const [parts, setParts] = useState([]);
    const messanger = useNotification();

    useEffect(() => {
        if (abort) {
            messanger("File upload aborted due to error", "error");
        } else if (parts.length > 0) {
            const uploadParts = async () => {
                try {
                    await savePart(parts, "upload");
                    setSuccess(`Successfully uploaded ${parts.length} parts`);
                } catch (err) {
                    console.error(err);
                    setError("Error uploading parts: " + err.message);
                }
            };
            uploadParts();
        }
    }, [abort, parts]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
            setSuccess("");
            setError("");
        } else {
            setError("Please select a valid CSV file");
            setFile(null);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setError("Please select a file first");
            return;
        }

        try {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                const rows = text.split('\n').filter(row => row.trim());
                const headers = rows[0].split(',');
                for (let header of headers) {
                    const possibleHeaders = ['part_number', 'description', 'price', 'discontinued', 'category', 'additionalData'];
                    if (!possibleHeaders.includes(header.trim())) {
                        setAbort(true);
                        return setError(`Invalid CSV file. Found: ${header}`);
                    }
                }
                const parts = rows.slice(1).map(row => {
                    // Use regex to handle the object within the CSV
                    const matches = row.match(/("[^"]*"|[^,]+)/g).map(m => m.replace(/^"|"$/g, ''));
                    const values = matches.map(value => value.replace(/^"|"$/g, '').trim());

                    const part = {};
                    headers.forEach((header, index) => {
                        let value = values[index];
                        try {
                            if (value === "NULL") value = null;
                            if (value === "{}") value = null;
                            // Check if the value looks like an object
                            if (index === 4 && value) {
                                // Index 4 should be an object if present
                                // First, convert the string format to valid JSON
                                // Replace single quotes with double quotes if present
                                value = value.replace(/'/g, '"');
                                // Convert property names to have quotes
                                value = value.replace(/([{,]\s*)([a-zA-Z0-9_]+):/g, '$1"$2":');
                                value = JSON.parse(value);
                            }
                            part[header.trim()] = value;

                        } catch (e) {
                            setAbort(true);
                            console.error("Error parsing additionalData:", e);
                            setError(`There was an error processing part_number: ${values[0]} Error parsing additionalData: ${e.message}. The value that caused an error is: ${value}.`);
                            return null;
                        }
                    });
                    return part;
                }).filter(part => part !== null);

                setParts(parts);
                setFile(null);
            };
            reader.readAsText(file);
        } catch (err) {
            console.error("Error opening file:", err);
            setError("Error reading file: " + err.message);
        }
    };

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between" p={3}>
            <Box flex={1} pr={2}>
                <Typography>
                    When uploading a CSV file to the database you must format the file correctly. Everything in row #1 must be labeled as such:
                    Column A: part_number, Column B: description, Column C: discontinued, Column D: category, Column E: additionalData, Column F: price.
                </Typography>
                <Typography>
                    The part number must be unique and the price must be a number. The discontinued column must be TRUE or FALSE.
                    Cells can be empty or say 'NULL'. The additionalData column must be a JSON object.
                    Only parts with the category of fan, motor, and freqDrive require the additionalData parameter.
                </Typography>
            </Box>
            <Box flex={1} display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h4" align="left" gutterBottom>
                    Upload Parts
                </Typography>
                <input
                    accept=".csv"
                    style={{ display: 'none' }}
                    id="csv-upload"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="csv-upload">
                    <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                    >
                        Select CSV File
                    </Button>
                </label>
                {file && (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Selected file: {file.name}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    disabled={!file}
                    sx={{ mt: 2 }}
                >
                    Upload Parts
                </Button>
                {error && (
                    <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
                        {success}
                    </Alert>
                )}

                <Typography>Example Object: {`{hp: 7.5, staticPressure1: 22300, staticPressure05: 25800, staticPressure15: 19100, staticPressure075: 23500, staticPressure125: 20800, staticPressure175: 17300}`}
                    OR {`{hp: 10, brand: 'ABB', voltage: 240}`}
                </Typography>
            </Box>
        </Box>
    );
}

export default UploadParts;