import React, { useEffect, useState } from "react";
import {
    Box, TextField, Select, MenuItem,
    FormControl, InputLabel
} from "@mui/material";
import useFields from "../../../Hooks/useFields";
import useStorageAssessmentStore from "../../../stores/storageAssessmentStore";
import StorageAssessPart from "./StorageAssessPart";

function StorageAssessClimaCell({ edit, climaCells, onPartChange }) {
    const [formData, handleLocal] = useFields({ depth: 12 });
    const [localTotals, setLocalTotals] = useFields({});
    const [triggerRecalculation, setTriggerRecalculation] = useState(0);

    const [climaCellBase, setObj, climaCell, calculateTotals, totalCfm, totalSqFtClimaCell, getVarianceColor] =
        useStorageAssessmentStore(state => [
            state.climaCellBase,
            state.setObj,
            state.climaCell,
            state.calculateTotals,
            state.totals.totalCfm,
            state.totals.totalSqFtClimaCell,
            state.getVarianceColor
        ]);

    const getTotalSqFtParts = function (qty, height, width) {
        if (qty && height && width) {
            const totalSqFtParts = parseFloat((qty * height * width)).toFixed(1);
            setLocalTotals("invalidInput", "totalSqFtParts", totalSqFtParts);
        }

    }

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const newValue = e.target.type === 'number' ? parseFloat(value) || 0 : value;

        // Create new data with updated field
        const newData = { ...formData, [name]: newValue };

        // Update both local state and global state in one step
        handleLocal("replaceObj", newData);
        setObj(newData, "climaCellBase");

        // Immediately calculate sq ft values
        getTotalSqFtParts(newData.qty, newData.height, newData.width);
        // Calculate totals in the global state
        calculateTotals("climaCell");

        // Trigger recalculation of variance
        setTriggerRecalculation(prev => prev + 1);
    };

    // Calculate variance whenever relevant values change
    useEffect(() => {
        if (formData.qty && formData.height && formData.width && totalCfm) {
            // Calculate required sq ft based on depth
            let newTotalSqFtReq = totalCfm || 0;
            console.log(formData);
            if (formData.depth === 12) {
                newTotalSqFtReq = newTotalSqFtReq / 650;
            } else if (formData.depth === 18) {
                newTotalSqFtReq = newTotalSqFtReq / 550;
            }

            // Calculate local sq ft for immediate feedback
            const localSqFt = parseFloat((formData.qty * formData.height * formData.width)).toFixed(1);

            // Calculate variance
            const ratio = localSqFt / newTotalSqFtReq;
            const inversePercentage = ((1 - ratio) * -100).toFixed(0);

            // Update local totals
            setLocalTotals("invalidInput", "totalSqFtReq", newTotalSqFtReq.toFixed(1));
            setLocalTotals("invalidInput", "variance", inversePercentage);
        }
    }, [formData.qty, formData.width, formData.height, formData.depth, totalCfm, triggerRecalculation]);

    useEffect(() => {
        setObj(formData, "climaCellBase");
    }, [formData]);

    useEffect(() => {
        if (edit) {
            const initialData = {
                depth: climaCellBase.climaCellBaseDepth || 12,
                edgeCoat: climaCellBase.climaCellBaseEdgeCoat || false,
                height: climaCellBase.climaCellBaseHeight || 0,
                qty: climaCellBase.climaCellBaseQty || 0,
                width: climaCellBase.climaCellBaseWidth || 0,
            };
            getTotalSqFtParts(initialData.qty, initialData.height, initialData.width);
            handleLocal("replaceObj", initialData);
        }
    }, [edit]);

    return (<>
        <Box ml={1} display="inline">
            <TextField
                name="qty"
                id="qty"
                label="Quantity"
                variant="outlined"
                type="number"
                sx={{ width: "10ch" }}
                value={formData.qty || ""}
                onChange={handleFormChange}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </Box>
        <Box ml={1} display="inline">
            <TextField
                name="width"
                id="width"
                label="Width"
                type="number"
                sx={{ width: "12ch" }}
                value={formData.width || ""}
                onChange={handleFormChange}
            />
        </Box>
        <Box ml={1} display="inline">
            <TextField
                name="height"
                id="height"
                label="Height"
                type="number"
                sx={{ width: "12ch" }}
                value={formData.height || ""}
                onChange={handleFormChange}
            />
        </Box>
        <Box ml={1} display="inline">
            <FormControl>
                <InputLabel>Depth</InputLabel>
                <Select
                    name="depth"
                    id="depth"
                    label="Depth"
                    type="number"
                    sx={{ width: "12ch" }}
                    value={formData.depth || 12}
                    onChange={handleFormChange}
                >
                    <MenuItem value={12}>12"</MenuItem>
                    <MenuItem value={18}>18"</MenuItem>
                </Select>
            </FormControl>
        </Box>
        <Box ml={1} display="inline">
            <FormControl>
                <InputLabel>Edge Coat</InputLabel>
                <Select
                    name="edgeCoat"
                    id="edgeCoat"
                    label="Edge Coat"
                    sx={{ width: "12ch" }}
                    value={formData.edgeCoat || false}
                    onChange={handleFormChange}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </FormControl>
        </Box>
        {climaCell?.length > 0 && climaCell.map((part) => (
            <StorageAssessPart
                key={part.indexId}
                edit={edit}
                part={part}
                parts={climaCells}
                partType="climaCell"
                onPartChange={onPartChange}
                indexId={part.indexId} />
        ))}

        <Box mt={2} ml={1}>
            <TextField
                name="totalSqFtReq"
                id="totalSqFtReq"
                label="Total Sq. Ft. Required"
                type="number"
                variant="standard"
                value={localTotals.totalSqFtReq || ""}
                sx={{ width: "15ch", mr: 1 }}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                name="totalSqFtParts"
                id="totalSqFtParts"
                label="Total Sq. Ft."
                variant="standard"
                value={localTotals.totalSqFtParts || ""}
                sx={{ width: "15ch", mr: 1 }}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                name="variance"
                id="variance"
                label="Variance"
                type="number"
                variant="standard"
                value={localTotals.variance || ""}
                sx={{
                    width: "10ch", mr: 1,
                    '& .MuiInputBase-input': {
                        fontWeight: 'bold',
                        color: getVarianceColor(localTotals.variance)
                    },
                    '& .MuiInputLabel-root': {
                        fontWeight: 'bold',
                        color: getVarianceColor(localTotals.variance)
                    }
                }}
                InputProps={{ readOnly: true, endAdornment: <span>%</span> }}
                InputLabelProps={{ shrink: true }}
            />
        </Box>
    </>)
}

export default StorageAssessClimaCell;