import React, { useContext, useState, useEffect } from "react";
import BaseSheet from "../SheetComponents/BaseSheet";
import {
    Box, Grid, FormControl, MenuItem, Select,
    Typography, Button, InputLabel, useTheme,
    TextareaAutosize, TextField,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import useFields from "../../Hooks/useFields";
import useNotification from "../../Hooks/useNotification";
import UserContext from "../../Users/UserContext";
import PartsSheet from "../SheetComponents/PartsSheet";
import TechSheet from "../SheetComponents/TechSheet";
import usePartsStore from "../../stores/partsStore";
import useTechStore from "../../stores/techStore";
import useBaseStore from "../../stores/baseStore";
import useSheetsStore from "../../stores/SheetsStore";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import SheetsApi from "../../api";
import useSettingsStore from "../../stores/settingsStore";

function ChemAppService() {
    const [formData, handleLocal] = useFields({
        serviceType: "",
        serviceRequested: "",
        workPerformed: ""
    })
    const { currentUser } = useContext(UserContext)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [renderCheck, setRenderCheck] = useState(false)
    const [home] = useSettingsStore((state) => [state.home]);
    const theme = useTheme()
    const primary = theme.palette.primary.main
    const messanger = useNotification();
    const defaultColor = theme.palette.default.main
    const history = useHistory();
    const [setServiceSheet, serviceSheet, clearServiceSheet] = useSheetsStore(state =>
        [state.setServiceSheet, state.serviceSheet, state.clearServiceSheet])
    const [baseSheet, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm])
    const [partSheet, clearPartsForm] = usePartsStore(state =>
        [state.parts, state.clearPartsForm])
    const [techSheet, clearTechForm] = useTechStore(state =>
        [state.techs, state.clearTechForm])

    useEffect(() => {
        setRenderCheck(true)
        clearServiceSheet()
        clearBaseForm()
        clearPartsForm()
        clearTechForm()
    }, [])

    const handleFormSubmit = async function (e) {
        e.preventDefault()
        setIsSubmitting(true)
        const sheetObj = CreateSubmitObj(
            serviceSheet, currentUser, messanger,
            baseSheet, partSheet, techSheet
        )
        console.log(sheetObj)
        if (sheetObj.error) return setIsSubmitting(false);
        const sheet = await SheetsApi.addChemAppServiceSheet(sheetObj)
        setRenderCheck(false)
        clearBaseForm()
        clearServiceSheet()
        clearPartsForm()
        clearTechForm()
        messanger(sheet.message, sheet.error ? "error" : "success")
        history.push(home);
        return setIsSubmitting(false)
    }


    return (
        <Grid container >
            <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                <BaseSheet title="Custom Application Service" />
                <Box
                    style={{ width: "100%" }}
                    mb={1}
                >
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel>Service Type</InputLabel>
                        <Select
                            label="Service Type"
                            onChange={handleLocal}
                            value={formData.serviceType === undefined ? "" : formData.serviceType}
                            onBlur={(e) => setServiceSheet(e.target.name, e.target.value)}
                            id="serviceType"
                            name="serviceType"
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="coldFog">Cold Fog</MenuItem>
                            <MenuItem value="productive">Productive</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    style={{ width: "100%" }}
                    sx={{
                        backgroundColor: primary,
                        "@media print": {
                            backgroundColor: primary,
                        },
                    }}
                >
                </Box>
                <Box sx={{ backgroundColor: primary, p: 1 }}>
                    <Typography variant="h5" style={{ color: defaultColor }}>
                        Service Requested
                    </Typography>
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        variant="outlined"
                        name="serviceRequested"
                        id="serviceRequested"
                        value={formData.serviceRequested || ""}
                        onChange={handleLocal}
                        onBlur={(e) => setServiceSheet(e.target.name, e.target.value)}
                        InputProps={{
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: '2px',
                                    borderColor: 'black',
                                },
                            }
                        }}
                    />
                </Box>
                <Box sx={{ backgroundColor: primary, p: 1 }}>
                    <Typography variant="h5" style={{ color: defaultColor }}>
                        Work Performed & Comments
                    </Typography>
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        variant="outlined"
                        name="workPerformed"
                        id="workPerformed"
                        value={formData.workPerformed || ""}
                        onBlur={(e) => setServiceSheet(e.target.name, e.target.value)}
                        onChange={handleLocal}
                        InputProps={{
                            sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: '2px',
                                    borderColor: 'black',
                                },
                            }
                        }}
                    />
                </Box>
                {renderCheck && <PartsSheet toggle={true} />}
                {renderCheck && <TechSheet edit={false} toggle={true} />}
                <Box mt={1} display="flex" justifyContent="flex-start">
                    <Button
                        disabled={isSubmitting}
                        variant="contained"
                        style={{ alignSelf: 'center', color: defaultColor, fontWeight: "bold" }}
                        onClick={handleFormSubmit}
                        type="submit"
                    >
                        Submit
                    </Button>

                </Box>
            </Grid>
        </Grid>
    );
}

export default ChemAppService;