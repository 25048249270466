import React, { useEffect, useState } from "react";
import {
    Button, Box, Divider, Card, CardContent, Typography,
    FormControl, InputLabel, Select, MenuItem, Tooltip,
} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import SheetsApi from "../../api";
import useFields from "../../Hooks/useFields";
import useNotification from "../../Hooks/useNotification";

function SiteSettings() {
    const messanger = useNotification()
    const [render, setRender] = useState(false);
    const [freqDriveBrands, setFreqDriveBrands] = useState([]);
    const [formData, handleLocal] = useFields({
        recommendedFreqDrive: ""
    })
    const saveSettings = async function () {
        const response = await SheetsApi.saveSettings(formData)
        messanger(response.message, "success")
    }
    useEffect(() => {
        async function getFreqDrives() {
            return await SheetsApi.getBidPartsByCategory({ category: "freqDrive" });
        }
        getFreqDrives().then((freqDrives) => {
            for (const freq of freqDrives) {
                if (freq.brand) {
                    setFreqDriveBrands(prevBrands => {
                        const index = prevBrands.indexOf(freq.additionalData.brand);
                        if (index === -1) {
                            return [...prevBrands, freq.additionalData.brand];
                        }
                        return prevBrands;
                    });
                }
            }
        })

    }, []);

    useEffect(() => {
        async function getSettings() {
            return await SheetsApi.getSettings()
        }
        if (freqDriveBrands.length > 0) {
            getSettings().then((settingsData) => {
                for (const setting of settingsData) {
                    handleLocal("invalidInput", setting.name, setting.value);
                }
                setRender(true);
            });
        }
    }, [freqDriveBrands])

    return (
        <>
            {render && <>
                <Box m={10}>
                    <Card sx={{ width: '60ch' }} >
                        <Box m={1}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography ml={4} variant="h5">
                                    Bid Settings
                                </Typography>
                                <Divider sx={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    flexGrow: 1,
                                    borderColor: 'black',
                                }} />
                            </Box>
                            <Divider sx={{
                                marginRight: '10px',
                                flexGrow: 1,
                                borderColor: 'black',
                            }} />
                        </Box>
                        <CardContent>
                            <Box m={1}>
                                <Box display="flex">
                                    <Tooltip title="Bids make a fan / motor / VFD recommendation based on airflow, this input changes what kind of drives will be selected in that recommendation.">
                                        <HelpIcon sx={{ paddingTop: .5 }} />
                                    </Tooltip>
                                    <Typography variant="h6">Recommended Frequency Drive brand:</Typography>
                                </Box>
                                <Box mt={1}>
                                    <FormControl fullWidth>
                                        <InputLabel>Frequency Drive</InputLabel>
                                        <Select
                                            name="recommendedFreqDrive"
                                            value={formData.recommendedFreqDrive || ""}
                                            label="Frequency Drive"
                                            onChange={handleLocal}
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {freqDriveBrands.map((brand) => (
                                                <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Divider />
                        </CardContent>
                        <Box mb={2} mr={5} display="flex" justifyContent="flex-end">
                            <Button onClick={saveSettings}>
                                Save
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </>}
        </>
    )
}

export default SiteSettings;
