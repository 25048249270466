import React, { useContext, useState, useEffect } from 'react';
import { PDFDownloadLink, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Fab, CircularProgress } from '@mui/material';
import StorageAssessmentDocument from './StorageAssessDocument';
import UserContext from '../../../Users/UserContext';
import useStorageAssessmentStore from '../../../stores/storageAssessmentStore';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12,
    },
    header: {
        marginBottom: 20,
        borderBottom: 1,
        borderBottomColor: '#333',
        paddingBottom: 10,
    },
    section: {
        marginBottom: 15,
        backgroundColor: '#ffffff',
        padding: 10,
        borderRadius: 3,
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#2d3748',
        backgroundColor: '#ebcc98',
        padding: 5,
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10,
    },
    gridItem: {
        width: '50%',
        marginBottom: 5,
    },
    label: {
        color: '#4a5568',
        marginRight: 5,
        fontWeight: 'bold',
    },
    value: {
        color: '#1a202c',
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: 'center',
        color: '#718096',
        fontSize: 10,
    },

});

function StorageAssessPrint({ location, customer }) {
    const { currentUser } = useContext(UserContext);
    const [preparingPdf, setPreparingPdf] = useState(false);
    const [downloadReady, setDownloadReady] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const storageAssessmentHead = useStorageAssessmentStore(state => state.storageAssessmentHead);
    const fan = useStorageAssessmentStore(state => state.fan);
    const motor = useStorageAssessmentStore(state => state.motor);
    const freqDrive = useStorageAssessmentStore(state => state.freqDrive);
    const fad = useStorageAssessmentStore(state => state.fad);
    const returnAirOpening = useStorageAssessmentStore(state => state.returnAirOpening);
    const actuator = useStorageAssessmentStore(state => state.actuator);
    const climaCell = useStorageAssessmentStore(state => state.climaCell);
    const humidifier = useStorageAssessmentStore(state => state.humidifier);
    const damper = useStorageAssessmentStore(state => state.damper);
    const lightBlock = useStorageAssessmentStore(state => state.lightBlock);
    const control = useStorageAssessmentStore(state => state.control);
    const refrigeration = useStorageAssessmentStore(state => state.refrigeration);
    const climaCellBase = useStorageAssessmentStore(state => state.climaCellBase);
    const storageSettings = useStorageAssessmentStore(state => state.storageSettings);
    const varianceTotals = useStorageAssessmentStore(state => state.varianceTotals);

    const prepareData = () => {
        setPreparingPdf(true);
        try {
            const data = {
                storageAssessmentHead,
                fan,
                motor,
                freqDrive,
                fad,
                returnAirOpening,
                actuator,
                climaCell,
                humidifier,
                damper,
                lightBlock,
                control,
                refrigeration,
                climaCellBase,
                storageSettings,
                varianceTotals,
            };

            setPdfData(data);
            setDownloadReady(true);
            setRefreshKey(prev => prev + 1);
        } catch (error) {
            console.error("Error preparing PDF data:", error);
        } finally {
            setPreparingPdf(false);
        }
    };

    // Reset when any of the data changes
    useEffect(() => {
        setDownloadReady(false);
    }, [
        storageAssessmentHead, fan, motor, freqDrive, fad, returnAirOpening,
        actuator, climaCell, humidifier, damper, lightBlock, control,
        refrigeration, climaCellBase, storageSettings, varianceTotals
    ]);

    if (preparingPdf) {
        return (
            <Fab
                color="primary"
                variant="extended"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                disabled
            >
                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                Preparing PDF...
            </Fab>
        );
    }

    if (!downloadReady) {
        return (
            <Fab
                color="primary"
                variant="extended"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                onClick={prepareData}
            >
                Prepare PDF
            </Fab>
        );
    }

    return (
        <div>
            <PDFDownloadLink
                key={refreshKey}
                document={
                    <StorageAssessmentDocument
                        currentUser={currentUser}
                        data={pdfData}
                        location={location}
                        customer={customer}
                        styles={styles}
                    />
                }
                fileName={`assessment-${location}-${customer}.pdf`}
                options={{
                    compress: true
                }}
            >
                {({ blob, url, loading, error }) => {
                    if (error) {
                        console.error("PDF Generation Error:", error);
                        return (
                            <Fab
                                color="error"
                                variant="extended"
                                sx={{
                                    position: 'fixed',
                                    bottom: 16,
                                    right: 16,
                                }}
                                onClick={() => setDownloadReady(false)}
                            >
                                Error - Try Again
                            </Fab>
                        );
                    }

                    if (loading) {
                        return (
                            <Fab
                                color="primary"
                                variant="extended"
                                sx={{
                                    position: 'fixed',
                                    bottom: 16,
                                    right: 16,
                                }}
                                disabled
                            >
                                <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                Generating PDF...
                            </Fab>
                        );
                    }

                    return (
                        <Fab
                            color="primary"
                            variant="extended"
                            sx={{
                                position: 'fixed',
                                bottom: 16,
                                right: 16,
                            }}
                        >
                            Download PDF
                        </Fab>
                    );
                }}
            </PDFDownloadLink>
        </div>
    );
}

export default StorageAssessPrint;