import React from "react";
import useFields from "../../Hooks/useFields";
import SheetsApi from "../../api";
import {
    Box, FormControl, InputLabel, Select,
    MenuItem, Button, Card, CardContent,
    CardHeader, Typography, useTheme,
} from "@mui/material"
import useNotification from "../../Hooks/useNotification";

function LocationMerge({ locationData, locations }) {
    const messanger = useNotification();
    const theme = useTheme()
    const dark = theme.palette.default.main;
    const inputBackground = theme.palette.default.secondary;
    const mainColor = theme.palette.primary.main;
    const textColor = 'white';
    const [formData, handleLocal] = useFields({
        mergeSiteName: "",
    })

    const mergeSite = async function (e) {
        e.preventDefault()
        if (formData.mergeSiteName !== "") {
            const result = await SheetsApi.mergeLocation({
                id: locationData.id,
                customer: locationData.customer_name,
                locationToDelete: formData.mergeSiteName,
            })
            return messanger(result.message, result.error ? "error" : "success")
        } else {
            messanger("You must select a location to merge to before submitting.", "warning")
        }
    }

    return (
        <>
            <Box m={3}>
                <Card sx={{ width: '100%', bgcolor: dark }} >
                    <CardHeader
                        titleTypographyProps={{ sx: { color: textColor } }}
                        title="Merge Site" />
                    <CardContent>
                        <Typography sx={{ color: textColor }}>
                            This action will take all sheets associated with "{formData.mergeSiteName}" and merge them into "{locationData.location_name}".
                            It will then DELETE "{formData.mergeSiteName}" permanently.
                            This action is IRREVERSIBLE. Please proceed with caution.
                        </Typography>
                        <Box mt={2}>
                            <form onSubmit={mergeSite} >
                                <FormControl>
                                    <InputLabel sx={{ color: mainColor }}>Merge a Site</InputLabel>
                                    <Select
                                        id="mergeSiteName"
                                        name="mergeSiteName"
                                        label="Merge a Site"
                                        sx={{ width: "26ch", bgcolor: inputBackground }}
                                        onChange={handleLocal}
                                        value={formData.mergeSiteName || ""}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {locations.length > 0 &&
                                            locations.map((location2) => (
                                                location2.location_name !== locationData.location_name &&
                                                <MenuItem
                                                    key={location2.id}
                                                    value={location2.location_name}>
                                                    {location2.location_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <Box display="inline" ml={1}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1, bgcolor: mainColor, color: 'white' }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default LocationMerge;