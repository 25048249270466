import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Users/UserContext";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import UndoIcon from '@mui/icons-material/Undo';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import ShowerIcon from '@mui/icons-material/Shower';
import { NavLink } from "react-router-dom"
import {
    List, ListItemText, ListItemIcon, ListItemButton,
    ListItem, Typography, Divider, Collapse, useTheme,
} from "@mui/material";
import { v4 as uuid } from "uuid";

function SheetMenu({ handleDrawerClose }) {
    const { currentUser } = useContext(UserContext);
    const [sheetList, setSheetList] = useState({ sheets: [] });
    const theme = useTheme()
    const gray = theme.palette.default.secondary;
    const blue = theme.palette.secondary.alter;
    const yellow = theme.palette.secondary.alter2;
    const [menu, setMenu] = useState({ service: false, chemApps: false, labs: false, sales: false });
    useEffect(() => {
        const sheetArray = [];
        const service = { name: 'Service', link: "/sheets", id: uuid() };
        const chemAppService = { name: 'Custom Application Service', link: '/chemAppService', id: uuid() };
        const refrigeration = { name: 'Refrigeration Start-up', link: '/refrigeration', id: uuid() };
        const preSeason = { name: 'Preseason', link: '/preseason', id: uuid() };
        const sales = { name: 'Sales Order', link: '/salesOrders', id: uuid() };
        const inspection = { name: 'Storage Inspection', link: '/inspections', id: uuid() };
        const chemApps = { name: 'Custom Application', link: '/chemApps/newAgreement', id: uuid() };
        const bids = { name: 'Ventilation Bid', link: '/bids/newBid', id: uuid() };
        const bidRef = { name: 'Refrigeration Bid', link: '/bidRef/newBid', id: uuid() };
        const freshPack = { name: 'Fresh Pack', link: '/freshPack', id: uuid() };
        const labs = { name: 'Labs', link: '/labs', id: uuid() }
        const disinfectant = { name: 'Disinfectant', link: '/disinfectant', id: uuid() };
        const sprayBar = { name: 'Spray Bar', link: '/sprayBar', id: uuid() };

        if (currentUser.dept === 'admin' || currentUser.dept === 'sales') {
            return setSheetList({
                service: [service, refrigeration, preSeason, sales],
                chemApps: [chemApps, disinfectant, sprayBar, freshPack, chemAppService, sales, inspection],
                labs: [inspection, labs, sales],
                sales: [bids, bidRef, sales],
            })
        } else if (currentUser.dept === 'service') {
            sheetArray.push(service, refrigeration, preSeason, sales);
        } else if (currentUser.dept === 'chemApps') {
            sheetArray.push(chemApps, disinfectant, sprayBar, freshPack, chemAppService, sales, inspection,);
        } else if (currentUser.dept === 'labs') {
            sheetArray.push(inspection, labs, sales);
        }
        return setSheetList({ sheets: sheetArray });
    }, [])

    const IconFinder = function (sheetName) {
        if (sheetName === 'Service') return <WarehouseIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Storage Inspection') return <SearchIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Refrigeration Start-up') return <AcUnitIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Sales Order') return <ReceiptIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Preseason') return <UndoIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Custom Application') return <CloudSyncIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Ventilation Bid') return <ReceiptLongIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Refrigeration Bid') return <ReceiptLongIcon sx={{ color: blue }} />
        else if (sheetName === 'Fresh Pack') return <PrecisionManufacturingIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Labs') return <AssessmentIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Disinfectant') return <SanitizerIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Spray Bar') return <ShowerIcon sx={{ color: 'white' }} />
        else if (sheetName === 'Custom Application Service') return <WarehouseIcon sx={{ color: yellow }} />
    };

    const defaultStyle = { textDecoration: 'none', color: "#004e7c" }

    function camelCaseToTitle(str) {
        if (str === 'chemApps') return 'Custom Apps'
        if (str === 'labs') return 'Storage Management'
        let result = str.charAt(0).toUpperCase();
        for (let i = 1; i < str.length; i++) {
            if (str.charAt(i) === str.charAt(i).toUpperCase()) {
                result += ' ' + str.charAt(i).toUpperCase();
            } else result += str.charAt(i);
        }
        return result;
    }
    return (
        <>
            <Typography
                sx={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 1,
                }}
                variant="h6"
            >
                Add New
            </Typography>
            <List>
                {currentUser.dept === 'sales' || currentUser.dept === 'admin' ? (
                    <>
                        {Object.keys(sheetList).length > 0 && Object.keys(sheetList).map((dept) => {
                            return <div key={dept} >
                                <Divider />
                                <ListItemButton onClick={() => setMenu({ ...menu, [dept]: !menu[dept] })}>
                                    <ListItemText
                                        sx={{ color: 'white', ml: 1 }}
                                        primary={camelCaseToTitle(dept)}
                                        primaryTypographyProps={{ fontSize: '20px' }}
                                    />
                                    {menu[dept] ?
                                        <ExpandLess sx={{ color: 'white' }} /> :
                                        <ExpandMore sx={{ color: 'white' }} />
                                    }
                                </ListItemButton>
                                <Collapse in={menu[dept]} unmountOnExit>
                                    <List component="div" disablePadding>
                                        {sheetList[dept].map((sheet) => {
                                            return <div key={sheet.id}>
                                                <Divider />
                                                <NavLink onClick={handleDrawerClose} to={sheet.link} style={defaultStyle}>
                                                    <ListItem sx={{ paddingLeft: 5 }} >
                                                        <ListItemIcon>
                                                            {IconFinder(sheet.name)}
                                                        </ListItemIcon>
                                                        <ListItemText sx={{ color: 'white' }} primary={sheet.name} />
                                                        {sheet.name === 'Refrigeration Bid' ? (
                                                            <ListItemText
                                                                sx={{ mr: 1, display: 'flex', justifyContent: 'flex-end', color: blue, fontStyle: 'italic' }}
                                                                primary="Beta"
                                                            />
                                                        ) : null}
                                                    </ListItem>
                                                </NavLink>
                                            </div>
                                        })}
                                    </List>
                                </Collapse>
                            </div>
                        })}
                    </>
                ) : (sheetList.sheets.length > 0 && sheetList.sheets.map((sheet) => (
                    <div key={sheet.id}>
                        <Divider />
                        <NavLink onClick={handleDrawerClose} to={sheet.link} style={defaultStyle}>
                            <ListItem>
                                <ListItemIcon>
                                    {IconFinder(sheet.name)}
                                </ListItemIcon>
                                <ListItemText sx={{ color: 'white' }} primary={sheet.name} />
                                {sheet.name === 'Refrigeration Bid' ? (
                                    <ListItemText
                                        sx={{ mr: 2, display: 'flex', justifyContent: 'flex-end', color: 'primary', fontStyle: 'italic' }}
                                        primary="Beta"
                                    />
                                ) : null}
                            </ListItem>
                        </NavLink>
                    </div>
                ))
                )}
            </List>
            <Divider sx={{ borderColor: gray }} />
        </>
    )
}

export default SheetMenu;