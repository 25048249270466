import React, { useEffect, useContext } from "react";
import { Box, Button, TextField, Tooltip, } from "@mui/material";
import useFields from "../../../Hooks/useFields";
import TechSelect from "../TechSelect";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserContext from "../../../Users/UserContext";
import dayjs from 'dayjs';
import SheetsApi from "../../../api";
import useNotification from "../../../Hooks/useNotification";

function AddMessage({ baseSheet, handleAddMessage, setMessageToggle, recentLog }) {
    const [formData, handleLocal] = useFields({ message: "", user: "" });
    const { currentUser } = useContext(UserContext);
    const messanger = useNotification();

    useEffect(() => {
        handleLocal("invalidInput", "user", recentLog.actionUser)
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.message) return messanger("You must enter a message to submit.", "warning");
        if (formData.message.length > 345) return messanger("Message must be less than 345 characters.", "warning");
        let stage;
        if (recentLog.stage === 'new') stage = 'Preliminary Review'
        else stage = recentLog.stage;

        const SheetHistoryEntry = {
            actionUser: currentUser.username,
            sheetId: recentLog.sheetId,
            message: formData.message,
            stage: stage,
            timeStamp: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
            user: formData.user,
            tableName: recentLog.sheetType + "Logs", //The sheetType comes from the front end when initially getting the logs
        }
        const notificationEntry = {
            sheetId: recentLog.sheetId,
            creator: currentUser.username,
            customer: baseSheet.customerName,
            location: baseSheet.locationName,
            locationId: baseSheet.locationId,
            username: formData.user,
            followupDept: "",
            sheetType: recentLog.sheetType,
            company: currentUser.company,
            notificationType: "message",
        }
        const response = await SheetsApi.postLogMessage(SheetHistoryEntry);
        await SheetsApi.addNotification(notificationEntry);
        setMessageToggle(false);
        handleLocal("invalidInput", "message", "");
        handleAddMessage(SheetHistoryEntry);
        return messanger(response.message, response.error ? "error" : "success");
    }

    return (<>
        <Box m={1}>
            <TextField
                name="message"
                value={formData.message || ""}
                onChange={handleLocal}
                label="Add a note"
                multiline
                rows={5}
                sx={{
                    mt: 1,
                    width: '333px',
                    '& .MuiOutlinedInput-root': {
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        padding: '8px 12px',
                        borderRadius: '8px',
                        color: '#1C2025',
                        backgroundColor: '#fff',
                        border: '1px solid #DAE2ED',
                        boxShadow: '0 2px 2px #F3F6F9',
                        resize: 'none',
                        '& fieldset': {
                            border: 'none'
                        },
                        '&:hover': {
                            borderColor: '#3399FF'
                        },
                        '&.Mui-focused': {
                            borderColor: '#3399FF',
                            boxShadow: '0 0 0 3px rgba(51, 153, 255, 0.2)'
                        }
                    },
                    '& .MuiInputLabel-root': {
                        backgroundColor: '#fff',
                        padding: '0 4px',
                        '&.Mui-focused': {
                            color: '#3399FF'
                        }
                    }
                }}
            />
        </Box>
        <Box display="flex" m={1}>
            <TechSelect
                name="Notify User"
                id="user"
                edit={true}
                formData={formData}
                handleLocal={handleLocal}
            />
            <Tooltip title="Assign a notification to this user regarding this message. The default selection is the most recent user to make a change to the sheet.">
                <HelpOutlineIcon sx={{ fontSize: 20, ml: 1, mt: 2 }} />
            </Tooltip>
        </Box>
        <Box ml={1}>
            <Button
                onClick={handleSubmit}>
                Submit
            </Button>
        </Box>
    </>
    );
}

export default AddMessage;