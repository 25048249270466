import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom"
import useSettingsStore from "../stores/settingsStore";
import Refrigeration from "../Sheets/Refrigeration/Refrigeration"
import ServiceSheets from "../Sheets/serviceSheets/ServiceSheets"
import PreSeason from "../Sheets/PreSeason/PreSeason";
import Companies from "../Companies/Companies"
import Customers from "../Customers/Cutomers"
import LoginForm from "../Users/LoginForm"
import LoginGuard from "../Users/LoginGuard"
import Home from "../Home/Home"
import ServiceSheetEdit from "../Sheets/serviceSheets/serviceSheetEdit";
import PreSeasonEdit from "../Sheets/PreSeason/PreSeasonEdit";
import RefrigerationEdit from "../Sheets/Refrigeration/RefrigerationEdit";
import SalesOrder from "../Sheets/SalesOrder/SalesOrder";
import SalesOrderEdit from "../Sheets/SalesOrder/SalesOrderEdit";
import StorageInspection from "../Sheets/StorageInspection/StorageInspection";
import StorageInspectionEdit from "../Sheets/StorageInspection/StorageInspectionEdit";
import ChemAppAgreement from "../Sheets/ChemApps/ChemAppAgreement";
import ChemAppAgreementEdit from "../Sheets/ChemApps/ChemAppAgreementEdit";
import ChemAppForm from "../Sheets/ChemApps/chemAppForm/ChemAppFormBase";
import Bid from "../Sheets/Bids/Bid"
import BidRef from "../Sheets/Bids/BidRef";
import SheetsApi from "../api";
import BidEdit from "../Sheets/Bids/BidEdit";
import BidRefEdit from "../Sheets/Bids/BidRefEdit";
import BidQuote from "../Sheets/Bids/BidQuote/BidQuote";
import SiteSettings from "../Users/SiteSettings/SiteSettings";
import useNotification from "../Hooks/useNotification";
import BidRefrigerationQuote from "../Sheets/Bids/BidQuote/BidRefrigerationQuote";
import SalesDashboard from "../Home/SalesDashboard/SalesDashboard";
import BidBuilderSizes from "../Sheets/Bids/BidQuote/BidBuilderSizes";
import FreshPack from "../Sheets/FreshPack/FreshPack";
import FreshPackEdit from "../Sheets/FreshPack/FreshPackEdit";
import ReceiptsHome from "../Receipts/ReceiptsHome/ReceiptsHome";
import ReceiptsPdfBase from "../Receipts/ReceiptsPdf/ReceiptsPdfBase";
import LabsBase from "../Sheets/Labs/LabsBase";
import Users2 from "../Users/UserPage/newUsers";
import Calendar from "../Home/SalesDashboard/Calendar/Calendar";
import StorageAssessmentBase from "../Sheets/StorageAssessment/storageAssessmentBase";
import ChemAppService from "../Sheets/ChemAppService/ChemAppService";
import ChemAppServiceEdit from "../Sheets/ChemAppService/ChemAppServiceEdit";
import LocationSettings from "../Customers/locations/LocationSettings";

function Routes({ setToken, logout }) {
    const messanger = useNotification();
    const location = useLocation();
    const [setHome] = useSettingsStore((state) => [state.setHome])

    async function handleLogin(data) {
        let response = await SheetsApi.login(data)
        if (response.error) {
            return messanger(response.message, "error");
        } else {
            setToken(response.token)
            return { success: true }
        }
    };

    useEffect(() => {
        //Set the home page based on the last home page visited.
        if (location.pathname.startsWith("/home/")) {
            setHome(location.pathname)
        } else if (location.pathname === "/") {
            setHome("/")
        }
    }, [location])

    const routes = [
        { path: "/", component: Home },
        { path: "/home/:company", component: Home },
        { path: "/sheets", component: ServiceSheets },
        { path: "/sheets/edit/:id", component: ServiceSheetEdit },
        { path: "/refrigeration", component: Refrigeration },
        { path: "/refrigeration/edit/:id", component: RefrigerationEdit },
        { path: "/preseason", component: PreSeason },
        { path: "/preseason/edit/:id", component: PreSeasonEdit },
        { path: "/serviceViewer", component: SalesDashboard },
        { path: "/serviceViewer/calendar", component: Calendar },
        { path: "/salesOrders", component: SalesOrder },
        { path: "/salesOrders/edit/:id", component: SalesOrderEdit },
        { path: "/inspections", component: StorageInspection },
        { path: "/inspections/edit/:id", component: StorageInspectionEdit },
        { path: "/labs", component: LabsBase },
        { path: "/chemApps/newAgreement", component: ChemAppAgreement },
        { path: "/chemApps/agreement/edit/:id", component: ChemAppAgreementEdit },
        { path: "/ChemApps/form/edit/:id", component: ChemAppForm },
        { path: "/sprayBar", component: ChemAppAgreement, props: { sprayBar: true } },
        { path: "/sprayBar/form/edit/:id", component: ChemAppForm, props: { sprayBar: true } },
        { path: "/sprayBar/agreement/edit/:id", component: ChemAppAgreementEdit, props: { sprayBar: true } },
        { path: "/disinfectant/", component: ChemAppAgreement, props: { disinfectant: true } },
        { path: "/disinfectant/agreement/edit/:id", component: ChemAppAgreementEdit, props: { disinfectant: true } },
        { path: "/disinfectant/form/edit/:id", component: ChemAppForm, props: { disinfectant: true } },
        { path: "/bids/edit/:id", component: BidEdit },
        { path: "/bids/newBid", component: Bid },
        { path: "/bids/newBid/:customer", component: Bid },
        { path: "/bidRef/newBid", component: BidRef },
        { path: "/bidRef/edit/:id", component: BidRefEdit },
        { path: "/bids/generateQuote/:id", component: BidQuote, props: { gellertQuote: false } },
        { path: "/bids/generateGellertOrder/:id", component: BidQuote, props: { gellertQuote: true } },
        { path: "/bidRef/refrigerationQuote/:id", component: BidRefrigerationQuote },
        { path: "/bids/builderSizes/:id", component: BidBuilderSizes },
        { path: "/freshPack", component: FreshPack },
        { path: "/freshPack/edit/:id", component: FreshPackEdit },
        { path: "/storageAssessment/:id/:customer/:location", component: StorageAssessmentBase },
        { path: "/chemAppService", component: ChemAppService },
        { path: "/chemAppService/edit/:id", component: ChemAppServiceEdit },
        { path: "/companies", component: Companies },
        { path: "/customers", component: Customers },
        { path: "/customers/search/:searchTerm/:company", component: Customers },
        { path: "/customers/:company", component: Customers },
        { path: "/locationSettings/:id/:customer", component: LocationSettings },
        { path: "/receipts", component: ReceiptsHome },
        { path: "/receipts/print", component: ReceiptsPdfBase },
        { path: "/users", component: Users2, props: { logout } },
        { path: "/siteSettings", component: SiteSettings, props: { admin: true } },
        { path: "/login", component: LoginForm, props: { login: handleLogin }, isRoute: true }
    ];

    return (
        <div>
            <Switch>
                {routes.map(({ path, component: Component, props = {}, isRoute = false }) => (
                    isRoute ? (
                        <Route key={path} exact path={path}>
                            <Component {...props} />
                        </Route>
                    ) : (
                        <LoginGuard key={path} exact path={path}>
                            <Component {...props} />
                        </LoginGuard>
                    )
                ))}
            </Switch>
        </div>
    )
}

export default Routes