import React, { useContext } from "react";
import { Typography, Card, CardContent, Box, TextField } from "@mui/material";
import UserContext from "../../Users/UserContext";

function StorageAssessRefrigeration({ pageData, formData, handleLocal, getVarianceColor }) {
    const { currentUser } = useContext(UserContext);
    return (
        <Card sx={{ borderRadius: 3, boxShadow: 3, height: '100%' }}>
            <CardContent>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Refrigeration Analysis
                </Typography>
                <Box display={currentUser.mobile ? "block" : "flex"} justifyContent="space-evenly" sx={{ mb: 2 }}>
                    <Box>
                        <TextField
                            label="Ratio"
                            value={formData.ratio || ""}
                            onChange={handleLocal}
                            type="number"
                            name="ratio"
                            size="small"
                            inputProps={{ step: "0.1", min: "0" }}
                            sx={{ mb: 1 }}
                        />
                        <Typography variant="body1">
                            Tonage Required: {pageData.ratioValue || '0'}
                        </Typography>
                        <Typography variant="body1">
                            Total Sq Ft Required: {pageData.refTotalSqFtReq || '0'}
                        </Typography>
                        <Typography variant="body1">
                            Total Sq Ft: {pageData.refTotalSqFt || '0'}
                        </Typography>
                    </Box>
                    <Box>
                        <TextField
                            label="Current Refrigeration Tonage"
                            name="currRefTonage"
                            value={formData.currRefTonage || ""}
                            onChange={handleLocal}
                            type="number"
                            size="small"
                            inputProps={{ step: "0.1", min: "0" }}
                            sx={{ mb: 1 }}
                        />
                        <Typography variant="body1">
                            Max Fan Speed: {pageData.maxFanSpeed || '0'}%
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: getVarianceColor(pageData.refVariance),
                            fontWeight: 'bold'
                        }}>
                            Variance: {pageData.refVariance || '0'}%
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default StorageAssessRefrigeration;