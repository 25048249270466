import React, { useContext, useEffect, useState, useRef } from "react";
import BaseSheet from "../SheetComponents/BaseSheet";
import PartsSheet from "../SheetComponents/PartsSheet";
import TechSheet from "../SheetComponents/TechSheet";
import {
    Box, Grid, Typography, FormControl, Card, CardContent,
    Select, MenuItem, InputLabel, TextField,
} from "@mui/material/"
import { useTheme } from '@mui/material/styles';
import useSheetsStore from "../../stores/SheetsStore";
import useBaseStore from "../../stores/baseStore";
import usePartsStore from "../../stores/partsStore";
import useTechStore from "../../stores/techStore";
import SheetsApi from "../../api";
import { useHistory, useParams } from "react-router-dom"
import UserContext from "../../Users/UserContext";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import useImageStore from "../../stores/imageStore";
import StageSelector from "../SheetComponents/StageSelector";
import useNotification from "../../Hooks/useNotification";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import DeptSelect from "../SheetComponents/DeptSelect";
import useSettingsStore from "../../stores/settingsStore";
import { checkPermissions } from "../../helpers/permissions";
import useFields from "../../Hooks/useFields";

function ChemAppServiceEdit() {
    const componentRef = useRef()
    const { currentUser } = useContext(UserContext)
    const { id } = useParams()
    const theme = useTheme()
    const messanger = useNotification();
    const primary = theme.palette.primary.main
    const defaultColor = theme.palette.default.main
    const history = useHistory()
    const [renderCheck, setRenderCheck] = useState(false)
    const [toggle, setToggle] = useState(true)
    const [logs, setLogs] = useState([]);
    const [home] = useSettingsStore((state) => [state.home]);
    const [currstage, setCurrStage] = useState("");
    const [setServiceSheet, serviceSheet, clearServiceSheet, setServiceSheetObj] = useSheetsStore(state =>
        [state.setServiceSheet, state.serviceSheet, state.clearServiceSheet, state.setServiceSheetObj]);
    const [baseSheet, clearBaseForm, setBaseFormObj] = useBaseStore(state =>
        [state.baseForm, state.clearBaseForm, state.setBaseFormObj]);
    const [partSheet, clearPartsForm, setPartsObj] = usePartsStore(state =>
        [state.parts, state.clearPartsForm, state.setPartsObj]);
    const [techSheet, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj]);
    const [images, addImage, clearImages] = useImageStore(state =>
        [state.images, state.addImage, state.clearImages]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, handleLocal] = useFields({})

    const handleFormSubmit = async function (e) {
        e.preventDefault()
        const permissionsResult = checkPermissions(currentUser.account_level, currentUser.dept, currstage)
        if (!permissionsResult.status) {
            return messanger(permissionsResult.message, "error")
        }
        setIsSubmitting(true);
        const sheetObj = CreateSubmitObj(
            serviceSheet, currentUser, messanger,
            baseSheet, partSheet, techSheet)
        if (sheetObj.error) return setIsSubmitting(false)
        sheetObj.id = id
        const response = await SheetsApi.updateChemAppServiceSheet(sheetObj);
        HandleImage.addAndRemove(images, sheetObj);
        setRenderCheck(false)
        clearBaseForm()
        clearServiceSheet()
        clearPartsForm()
        clearTechForm()
        clearImages()
        messanger(response.message, "success")
        history.push(home);
        return setIsSubmitting(false);
    }
    const handleToggle = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        setServiceSheet("stage", formData.stage)
    }, [formData?.stage])

    useEffect(() => {
        setToggle(true)
        clearServiceSheet()
        clearPartsForm()
        clearBaseForm()
        clearTechForm()
        clearImages()
        async function getServiceSheet() {
            try {
                const serviceSheet = await SheetsApi.getChemAppServiceSheet({ id })
                const parts = await SheetsApi.getPartsById({ id, type: "chem_app_service_id" })
                const techs = await SheetsApi.getTechsById({ id, type: "chem_app_service_id" })
                const images = serviceSheet.images
                // fetch location name based on ID and replace. Also Add Id to the obj
                const locationId = serviceSheet.location
                const locationName = await SheetsApi.getLocationName({ locationId })
                serviceSheet.location = locationName.location_name
                serviceSheet.locationId = locationId
                setLogs(serviceSheet.logs)
                return { serviceSheet, parts, techs, images }
            } catch (err) {
                console.log(err)
            }
        }
        getServiceSheet().then(async sheet => {
            console.log(sheet)
            for (let section in sheet) {
                if (section === 'serviceSheet') {
                    const split = sheet[section]
                    let baseFormObj = {
                        ...split,
                        requestedBy: split.requestBy,
                        customerName: split.customer,
                        dateServiced: split.dateServiced,
                        locationName: split.location,
                    }
                    let serviceSheetObj = {
                        serviceRequested: split.serviceRequested,
                        workPerformed: split.workPerformed,
                        serviceType: split.serviceType,
                        stage: split.stage
                    }
                    handleLocal("replaceObj", serviceSheetObj)
                    setBaseFormObj(baseFormObj)
                    setServiceSheetObj(serviceSheetObj)
                    setCurrStage(split.stage)
                } else if (section === 'parts') {
                    let partsArray = []
                    for (let obj of sheet[section]) {
                        let partObj = {
                            qty: obj.quantity,
                            type: obj.quantity_type,
                            part: obj.part_number,
                            description: obj.description,
                            cost: obj.cost,
                            isMisc: obj.ismisc,
                            location: obj.location,
                            name: obj.name,
                            invoice: obj.invoice,
                            po: obj.po
                        }
                        partsArray.push(partObj)
                    }
                    setPartsObj(partsArray)
                } else if (section === 'techs') {
                    let id = -1
                    for (let obj of sheet[section]) {
                        id++
                        let techObj = {
                            technician: obj.technician,
                            truck: obj.truck,
                            labor: obj.labor,
                            travel: obj.travel,
                            nc: obj.nc,
                            notes: obj.notes
                        }
                        setTechObj(techObj, id)
                    }
                } else if (section === 'images') {
                    for (let img of sheet[section]) {
                        const imageObj = await HandleImage.setImage(img)
                        if (img.imageType === 'img' || img.imageType === 'receipt' || img.imageType === 'pdf') {
                            addImage(imageObj)
                        }
                    }
                }
            }
            setRenderCheck(true)
        })
    }, [])

    return (
        <div ref={componentRef} className="page" >
            <Grid container >
                <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                    <form onSubmit={handleFormSubmit}>
                        <BaseSheet title="Custom Application Service" id={id}/>
                        <Box
                            style={{ width: "100%" }}
                            mb={1}
                        >
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel>Service Type</InputLabel>
                                <Select
                                    label="Service Type"
                                    onChange={handleLocal}
                                    value={formData.serviceType === undefined ? "" : formData.serviceType}
                                    onBlur={(e) => setServiceSheet(e.target.name, e.target.value)}
                                    id="serviceType"
                                    name="serviceType"
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="coldFog">Cold Fog</MenuItem>
                                    <MenuItem value="productive">Productive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            style={{ width: "100%" }}
                            sx={{
                                backgroundColor: primary,
                                "@media print": {
                                    backgroundColor: primary,
                                },
                            }}
                        >
                        </Box>
                        <Box sx={{ backgroundColor: primary, p: 1 }}>
                            <Typography variant="h5" style={{ color: defaultColor }}>
                                Service Requested
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                variant="outlined"
                                name="serviceRequested"
                                id="serviceRequested"
                                value={formData.serviceRequested || ""}
                                onChange={handleLocal}
                                onBlur={(e) => setServiceSheet(e.target.name, e.target.value)}
                                InputProps={{
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: '2px',
                                            borderColor: 'black',
                                        },
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ backgroundColor: primary, p: 1 }}>
                            <Typography variant="h5" style={{ color: defaultColor }}>
                                Work Performed & Comments
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                variant="outlined"
                                name="workPerformed"
                                id="workPerformed"
                                value={formData.workPerformed || ""}
                                onBlur={(e) => setServiceSheet(e.target.name, e.target.value)}
                                onChange={handleLocal}
                                InputProps={{
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderWidth: '2px',
                                            borderColor: 'black',
                                        },
                                    }
                                }}
                            />
                        </Box>
                        {renderCheck && <PartsSheet edit={true} toggle={toggle} />}
                        {renderCheck && <TechSheet edit={true} toggle={toggle} />}
                        <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                            <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                <CardContent>
                                    <Box display="flex" justifyContent="left" className="hideForPrint" >
                                        <Box>
                                            <StageSelector
                                                currentUser={currentUser}
                                                handleChange={handleLocal} value={formData.stage}
                                            />
                                            <SubmitAndPrint
                                                isSubmitting={isSubmitting}
                                                width={300}
                                                height={280}
                                                componentRef={componentRef}
                                                toggle={toggle}
                                                handleToggle={handleToggle}
                                                showToggle={true} />
                                        </Box>
                                        {!currentUser.mobile && renderCheck ? <ImageDropZone sheetType="sheet" /> : null}
                                    </Box>
                                    {currentUser.mobile && renderCheck ? <ImageDropZone sheetType="sheet" /> : null}
                                </CardContent>
                            </Card>
                            {logs.length > 0 &&
                                <HistoryCard toggle={toggle} baseSheet={baseSheet} setLogs={setLogs} logs={logs} />
                            }
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}

export default ChemAppServiceEdit;