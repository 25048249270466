import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Grid, Box, Typography, Card, CardContent
} from "@mui/material";
import BaseSheet from "../SheetComponents/BaseSheet";
import useFields from "../../Hooks/useFields";
import useFreshPackStore from "../../stores/freshPackStore";
import Pump from "./Pump";
import TechSheet from "../SheetComponents/TechSheet";
import { TextareaAutosize } from '@mui/base';
import { useTheme } from '@mui/material/styles';
import FreshPackVisit from "./FreshPackVisit";
import DeptSelect from "../SheetComponents/DeptSelect";
import CreateSubmitObj from "../SheetComponents/CreateSubmitObj";
import UserContext from "../../Users/UserContext";
import useBaseStore from "../../stores/baseStore";
import useTechStore from "../../stores/techStore";
import useNotification from "../../Hooks/useNotification";
import SheetsApi from "../../api";
import { useParams, useHistory } from "react-router-dom"
import StageSelector from "../SheetComponents/StageSelector";
import SubmitAndPrint from "../SheetComponents/SubmitAndPrint";
import ImageDropZone from "../SheetComponents/images/imageDropZone";
import HistoryCard from "../SheetComponents/SheetHistory/HistoryCard";
import HandleImage from "../SheetComponents/images/imageUtilities/HandleImage";
import useImageStore from "../../stores/imageStore";
import useSettingsStore from "../../stores/settingsStore";
import { checkPermissions } from "../../helpers/permissions";

function FreshPackEdit() {
    const theme = useTheme();
    const componentRef = useRef()
    const primary = theme.palette.primary.main;
    const defaultColor = theme.palette.default.main;
    const { currentUser } = useContext(UserContext);
    const history = useHistory()
    const [logs, setLogs] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [home] = useSettingsStore((state) => [state.home])
    const [freshPackForm, setFreshPackFrom, pump1, pump2,
        setFreshPackObjs, clearFreshPack] = useFreshPackStore(state =>
            [state.freshPackForm, state.setFreshPackFrom, state.pump1,
            state.pump2, state.setFreshPackObjs, state.clearFreshPack]);
    const [baseForm, setBaseFormObj, clearBaseForm] = useBaseStore(state =>
        [state.baseForm, state.setBaseFormObj, state.clearBaseForm]);
    const [techs, clearTechForm, setTechObj] = useTechStore(state =>
        [state.techs, state.clearTechForm, state.setTechObj]);
    const [images, addImage, clearImages] = useImageStore(state =>
        [state.images, state.addImage, state.clearImages])
    const [formData, handleLocal] = useFields({
        visitReason: "",
        currPpm: "",
    });
    const [render, setRender] = useState(false)
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const messanger = useNotification()

    const handleSubmit = async function (e) {
        e.preventDefault();
        const permissionsResult = checkPermissions(currentUser.account_level, currentUser.dept, baseForm.stage)
        if (!permissionsResult.status) {
            return messanger(permissionsResult.message, "error")
        }
        setIsSubmitting(true);
        const mergedSheet = { ...freshPackForm, pump1, pump2 }
        const sheetObj = CreateSubmitObj(
            mergedSheet, currentUser, messanger,
            baseForm, [], techs)
        if (sheetObj.error) return setIsSubmitting(false)
        sheetObj.id = id;
        sheetObj.sheetType = "freshPack";
        const response = await SheetsApi.updateFreshPack(sheetObj);
        HandleImage.addAndRemove(images, sheetObj)
        messanger(response.message, response.error ? "error" : "success");
        clearBaseForm();
        clearFreshPack();
        clearTechForm();
        clearImages();
        history.push(home)
        setIsSubmitting(false);
    }

    const handleToggle = () => {
        setToggle(!toggle);
    }

    const handleChange = (e, passedName) => {
        if (!e) {
            return
        }
        const name = e.target.name || passedName
        const value = e.target.value
        return setFreshPackFrom(name, value)
    }

    useEffect(() => {
        clearImages();
        clearBaseForm();
        clearFreshPack();
        clearTechForm();
        const getSheet = async function () {
            const sheet = await SheetsApi.getFreshPackById({ id });
            const techs = await SheetsApi.getTechsById({ id, type: 'freshPackId' });
            return { sheet, techs };
        }
        getSheet().then(async (response) => {
            console.log(response);
            const sheet = response.sheet;
            const techs = response.techs;
            const images = response.sheet.images;
            const pump1 = sheet.pump1
            const pump2 = sheet.pump2
            const baseForm = {
                company: sheet.company,
                creator: sheet.creator,
                customerName: sheet.customer,
                locationName: sheet.locationName,
                locationId: sheet.location,
                dateCreated: sheet.dateCreated,
                dateServiced: sheet.dateServiced,
                dept: sheet.dept,
                requestedBy: sheet.requestedBy,
                po: sheet.po,
                stage: sheet.stage,
                sheetType: sheet.sheetType,
            }
            const baseFreshPackObj = {
                stage: sheet.stage,
                visitReason: sheet.visitReason,
                currPpm: sheet.currPpm,
                lastVisit: sheet.lastVisit,
                membrane: sheet.membrane,
                nozzles: sheet.nozzles,
                comments: sheet.comments,
            }
            let techId = -1
            for (let tech of techs) {
                techId++
                let techObj = {
                    technician: tech.technician,
                    timeEnd: tech.timeend,
                    timeStart: tech.timestart,
                    travel: tech.travel,
                    truck: tech.truck,
                }
                setTechObj(techObj, techId)
            }
            for (let img of images) {
                const imageObj = await HandleImage.setImage(img)
                if (img.imageType !== 'signature') {
                    addImage(imageObj)
                }
            }
            setLogs(sheet.logs)
            setBaseFormObj(baseForm);
            setFreshPackObjs(baseFreshPackObj, pump1, pump2)
            handleLocal("replaceObj", baseFreshPackObj)
            setRender(true);
        })
    }, [])

    return (<>{render &&
        <div ref={componentRef} className="page">
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid m={2} item xs={11} sm={11} md={10} lg={10} xl={8} mt={5} mb={5}>
                        <BaseSheet title="Equipment / Fresh Pack Check" id={id} />
                        <FreshPackVisit edit={true} />
                        <Pump data={pump1} id={1} />
                        <Pump data={pump2} id={2} />
                        <TechSheet timeTrack={true} edit={true} toggle={true} />
                        <Box
                            style={{ width: "100%" }}
                            sx={{ backgroundColor: primary }}
                        >
                            <Typography
                                ml={1}
                                variant="h5"
                                style={{ color: defaultColor }}>
                                Additional Comments:
                            </Typography>
                        </Box>
                        <Box ml={0.25}>
                            <TextareaAutosize
                                minRows={4}
                                style={{
                                    width: "99.0%",
                                    outline: '2px solid #000',
                                    fontSize: "16px",
                                }}
                                name="comments"
                                id="comments"
                                value={formData.comments || ""}
                                onChange={handleLocal}
                                onBlur={(e) => setFreshPackFrom(e.target.name, e.target.value)}
                            />
                        </Box>
                        <Box display="flex" flexDirection={{ xs: "column", lg: 'row' }}>
                            <Card sx={{ width: currentUser.mobile ? '100%' : 600 }} className="hideForPrint">
                                <CardContent>
                                    <Box display="flex" justifyContent="left" className="hideForPrint" >
                                        <Box>
                                            <StageSelector
                                                currentUser={currentUser}
                                                handleChange={handleChange}
                                                value={freshPackForm.stage}
                                            />
                                            <DeptSelect chemApp={true} />
                                            <SubmitAndPrint
                                                isSubmitting={isSubmitting}
                                                width={300}
                                                height={280}
                                                componentRef={componentRef}
                                                toggle={toggle}
                                                handleToggle={handleToggle}
                                                showToggle={true} />
                                        </Box>
                                        {!currentUser.mobile ? <ImageDropZone sheetType="freshPack" /> : null}
                                    </Box>
                                    {currentUser.mobile ? <ImageDropZone sheetType="freshPack" /> : null}
                                </CardContent>
                            </Card>
                            {logs.length > 0 &&
                                <HistoryCard toggle={toggle} baseSheet={baseForm} setLogs={setLogs} logs={logs} />
                            }
                        </Box>
                    </Grid>
                </Grid >
            </form>
        </div>
    }</>)
}

export default FreshPackEdit;