import React, { useEffect, useState, useContext } from "react"
import SheetsApi from "../../api"
import UserContext from "../../Users/UserContext";
import {
    Table, TableBody, TableContainer, Paper,
    Typography, Box, Button, Stack, Chip
} from "@mui/material";
import Tech from "./Tech"
import useTechStore from "../../stores/techStore";
import useBaseStore from "../../stores/baseStore";
import AddIcon from '@mui/icons-material/Add';

function TechList({ toggle, edit, timeTrack, chemApp }) {
    const chemAppPass = chemApp;
    const { currentUser } = useContext(UserContext)
    const [techs, setTechs] = useState({ managers: [], technicians: [], other: [] })
    const [renderCheck, setRenderCheck] = useState(false)
    const [calcValue, setCalc] = useState(0)
    let [technicians, addTech] = useTechStore(state =>
        [state.techs, state.addTech])
    const [setBaseForm] = useBaseStore(state =>
        [state.setBaseForm])
    const timeTrackPass = timeTrack

    useEffect(() => {
        async function getTechs() {
            try {
                if (edit && currentUser.account_level === 'Admin') {
                    return await SheetsApi.getInactive()
                } else {
                    return await SheetsApi.getAllUsers()
                }
            } catch (err) {
                console.log(err)
            }
        }
        getTechs().then(techs => {
            const categorizedUsers = { managers: [], technicians: [], other: [] };
            techs.forEach(tech => {
                if (tech.account_level === 'Manager') categorizedUsers.managers.push(tech);
                if (tech.account_level === 'Technician') categorizedUsers.technicians.push(tech);
                else if (tech.account_level === 'Admin' || tech.account_level === 'Regional Admin') {
                    categorizedUsers.other.push(tech);
                }
            })
            setTechs(categorizedUsers);
            setRenderCheck(true);
        })
    }, [])

    const additionalTech = (e) => {
        e.preventDefault()
        addTech()
    }
    const calculator = function () {
        let total = 0
        technicians.forEach(tech => {
            let labor = tech.labor
            let travel = tech.travel
            if (labor === "") {
                labor = 0
            }
            if (travel === "") {
                travel = 0
            }
            labor = parseFloat(tech.labor) || 0
            travel = parseFloat(tech.travel) || 0
            let newTotal = labor + travel
            total += newTotal
        })
        setCalc(total);
        return setBaseForm("total", total)
    }

    return (
        <Paper elevation={2} sx={{ borderRadius: '8px', overflow: 'hidden', mt: 2 }}>
            {renderCheck && (
                <Stack spacing={0}>
                    <Box sx={{ 
                        p: 1.5, 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                            Technician List
                        </Typography>
                        {!timeTrack && (
                            <Chip
                                label={`Total Hours: ${calcValue}`}
                                color="primary"
                                variant="outlined"
                                size="small"
                            />
                        )}
                    </Box>

                    <TableContainer sx={{ maxHeight: '60vh' }}>
                        <Table 
                            size="small" 
                            sx={{
                                '& .MuiTableCell-root': {
                                    py: 0.75,
                                    px: 1,
                                    fontSize: '0.875rem'
                                }
                            }}
                        >
                            <TableBody>
                                {technicians.map((item, index) => (
                                    <Tech
                                        toggle={toggle}
                                        calculator={calculator}
                                        key={index}
                                        id={index}
                                        item={item}
                                        techs={techs}
                                        timeTrack={timeTrackPass}
                                        chemApp={chemAppPass || false}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ 
                        p: 1,
                        borderTop: 1,
                        borderColor: 'divider',
                        display: 'flex',
                        gap: 1
                    }} className="hideForPrint">
                        <Button
                            size="small"
                            variant="contained"
                            onClick={additionalTech}
                            startIcon={<AddIcon />}
                            sx={{ 
                                textTransform: 'none',
                                fontWeight: 500
                            }}
                        >
                            Add Technician
                        </Button>
                    </Box>
                </Stack>
            )}
        </Paper>
    );
}

export default React.memo(TechList);