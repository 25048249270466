import React, { useEffect, useState, useContext, createRef } from "react";
import SheetsApi from "../../api";
import {
    Box, Divider, TextField, Typography, Autocomplete, Tooltip, IconButton,
    useTheme,
} from "@mui/material";
import DirectionsIcon from '@mui/icons-material/Directions';
import UserContext from "../../Users/UserContext"
import useBaseStore from "../../stores/baseStore";
import { shallow } from "zustand/shallow"
import useNotification from "../../Hooks/useNotification";
import DateInput from "./DateInput";
import asTfLogo from "../../images/asTfLogo.png";
import mainLogo from "../../images/mainLogo.png";
import asNwLogo from "../../images/asNwLogo.png";

function BaseSheets({ id, additionalCustomers, disableFields, title }) {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const { currentUser } = useContext(UserContext)
    const [customers, setCustomers] = useState([])
    const messanger = useNotification()
    const [locations, setLocations] = useState([])
    const [inputFilled, setInputFilled] = useState(0)
    const [baseForm, setBaseForm] = useBaseStore(state =>
        [state.baseForm, state.setBaseForm], shallow)
    const [coords, setCoords] = useState({ lat: 0, long: 0 })
    const inputRef = createRef()
    const lockedInput = {
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#000000',
            color: '#000000',
            backgroundColor: '#f8f8f8',
            cursor: 'not-allowed',
            paddingRight: '30px'
        },
        '& .MuiInputBase-root.Mui-disabled': {
            '&::after': {
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                pointerEvents: 'none'
            }
        }
    }
    const isMobile = currentUser.mobile;

    const mobileStyles = {
        wrapper: {
            flexDirection: 'column',
            gap: 2
        },
        logo: {
            width: '150px',
            height: '94px'
        },
        header: {
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 3
        },
        divider: {
            width: '100%',
            margin: '16px 0'
        }
    }

    useEffect(() => {
        async function getCustomers() {
            try {
                let customers;
                if (currentUser.account_level === 'Admin') {
                    customers = await SheetsApi.getAllCustomers()
                } else {
                    const companyName = currentUser.company
                    customers = await SheetsApi.getAllCustomersByCompany({ companyName })
                }
                return customers
            } catch (err) {
                messanger(err)
            }
        }
        getCustomers().then(customers => {
            setCustomers(customers)
            if (additionalCustomers) {
                additionalCustomers(customers)
            }
        })
    }, [])

    useEffect(() => {
        async function getLocations() {
            try {
                // Get locations and populate form
                const customerName = baseForm.customerName
                const locations = await SheetsApi.getActiveLocations({ customerName })
                if (baseForm.locationName) {
                    const location = locations.find(loc =>
                        loc.location_name.trim() === baseForm.locationName.trim()
                    );
                    if (location) {
                        setCoords({
                            lat: location.lat,
                            long: location.long
                        });
                    }
                }
                return locations
            } catch (err) {
                console.log(err)
            }
        }
        getLocations().then(locations => {
            setLocations(locations)
        })
    }, [inputFilled])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.querySelector("input").innerHTML = baseForm.locationName;
        }
    }, [inputRef, baseForm.locationName]);

    const handleFocus = () => {
        baseForm.locationName = ""
        setInputFilled(inputFilled + 1)
    }

    const handleChange = (e, passedName, id) => {
        if (!e) {
            return
        } if (e.target.className === "MuiAutocomplete-option Mui-focused") {
            //This will tell the handler if the object is a special type of event
            //passed by AutoComplete component
            const locationName = e.target.innerText
            if (e.target.parentElement.id === "locationName-listbox") {
                // if this is for the location autoComplete then query the state of the location ID
                // and set the ID in the global state
                const findId = locations.find(location => location.location_name.trim() === locationName)
                setBaseForm('locationId', findId.id)
            }
            if (e.target.dataset.company) {
                //This should only happen on the customer autoComplete
                //passes the customers company, this is so admins can add customers from other companies to a sheet.
                //also helps asign sheet card to the right company
                //passes watch array data
                setBaseForm("watchArray", JSON.parse(e.target.dataset.watcharray));
                setBaseForm("company", e.target.dataset.company);
            }
            return setBaseForm(passedName, locationName)
        } else {
            const name = e.target.name || passedName
            const value = e.target.value
            return setBaseForm(name, value)
        }
    }
    const isValid = function (e) {
        //checks if the autocomplete input is a valid selection.
        const name = e.target.name.trim();
        const value = e.target.value.trim();

        if (name === "customerName" && !customers.find(
            o => o.customer.trim() == value)) {
            setBaseForm(name, "");
        } else if (name === "locationName") {
            const location = locations.find(o => o.location_name.trim() == value);
            if (!location) {
                setBaseForm(name, "");
            } else {
                // Set coordinates when valid location is found
                setCoords({
                    lat: location.lat,
                    long: location.long
                });
            }
        }
    };

    const logoPicker = function () {
        if (id) {
            if (baseForm.company === 'Agri-Stor Companies') return asTfLogo;
            else if (baseForm.company === 'Agri-Stor Company North West') return asNwLogo;
            // else if (baseForm.company === 'Agri-Stor Company Colorado') return null;
            else return mainLogo;
        } else return mainLogo;
    }

    const redirectToMaps = () => {

        if (coords.lat && coords.long) {
            if (currentUser.os === 'ios') {
                const appleMapsUrl = `http://maps.apple.com/?ll=${coords.lat},${coords.long}`;
                window.open(appleMapsUrl, "_blank");
            } else {
                const googleMapsUrl = `https://www.google.com/maps?q=${coords.lat},${coords.long}`;
                window.open(googleMapsUrl, "_blank");
            }
        } else {
            messanger("No coordinates have been set. To add coords you can click the 'GET COORDS' button. If you do not have a button to get coords then go to Menu > Customers > Select a site > Site Settings, and add new coords manually.", "warning")
        }
    }
    return (
        <div>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...(isMobile && mobileStyles.header)
            }}>
                <Box mb={2}>
                    <img
                        src={logoPicker()}
                        style={{
                            width: isMobile ? '150px' : '200px',
                            height: isMobile ? '94px' : '125px'
                        }}
                        alt="logo"
                    />
                </Box>
                <Box mb={5} sx={{
                    display: 'flex-end',
                    alignItems: 'center',
                    flexGrow: 5,
                    width: isMobile ? '100%' : 'auto'
                }}>
                    <Box ml={5}>
                        <Typography
                            sx={{ typography: { xs: 'h6', sm: 'h5' } }}>
                            {title}
                        </Typography>
                    </Box>
                    <Box mr={2} sx={{ display: 'flex-end', alignItems: 'right' }}>
                        <Typography sx={{ fontWeight: 'bold' }} align="right">
                            {id}
                        </Typography>
                    </Box>
                    <Divider sx={{
                        marginRight: '10px',
                        flexGrow: 1,
                        borderTop: '2px solid',
                        borderColor: 'black',
                        fontWeight: 'bold'
                    }} />
                </Box>
            </Box>

            <Box display="flex"
                justifyContent="space-evenly"
                gap={isMobile ? 2 : 1}
                sx={{
                    flexDirection: isMobile ? 'column' : 'row',
                    marginBottom: isMobile ? 2 : 0
                }}
            >
                <Autocomplete
                    disabled={disableFields}
                    disableClearable
                    inputValue={baseForm.customerName || ""}
                    onInputChange={(e, id) => handleChange(e, "customerName", id)}
                    onBlur={(e) => isValid(e)}
                    name="autoComplete"
                    id="customerName"
                    sx={{ width: '100%', ...lockedInput }}
                    getOptionLabel={(customer) => customer.customer}
                    options={customers ? customers : null}
                    renderInput={(params) => (
                        <>
                            <TextField
                                sx={{ width: '100%' }}
                                {...params}
                                name="customerName"
                                variant="outlined"
                                required
                                id="customerName2"
                                label="Customer"
                                onBlur={handleFocus}
                            />
                        </>
                    )}
                    renderOption={(props, customer) => (
                        <li {...props}
                            data-company={customer.company}
                            data-watcharray={JSON.stringify(customer.watchArray)}
                        >
                            {customer.customer}
                        </li>
                    )}
                />
                <TextField
                    name="primaryTech"
                    label={`Created on ${baseForm.dateCreated} By:`}
                    value={baseForm.creator || currentUser.username}
                    disabled
                    sx={{
                        width: '100%',
                        ...lockedInput,
                        '& .MuiInputLabel-root.Mui-disabled': {
                            color: 'rgba(0, 0, 0, 0.87)',
                        }
                    }}
                />
                <DateInput
                    state={baseForm.dateServiced}
                    setState={setBaseForm}
                    required={true}
                    variableName="dateServiced"
                    label="Date Serviced"
                />
            </Box>

            <Box mt={1}
                mb={1}
                display="flex"
                justifyContent="space-evenly"
                gap={isMobile ? 2 : 1}
                sx={{
                    flexDirection: isMobile ? 'column' : 'row'
                }}
            >
                <Box display="flex"
                    gap={1}
                    sx={{
                        width: '100%',
                        flexDirection: 'row'
                    }}
                >
                    <Autocomplete
                        disabled={disableFields}
                        disableClearable
                        inputValue={baseForm.locationName || ""}
                        onInputChange={(e) => handleChange(e, "locationName")}
                        onBlur={(e) => isValid(e)}
                        sx={{ width: '100%', ...lockedInput }}
                        name="autoComplete"
                        id="locationName"
                        options={locations.map((location) => location.location_name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="locationName"
                                variant="outlined"
                                required
                                id="locationName2"
                                label="Site Name"
                            />)}
                    />
                    <Box className="hideForPrint" display="flex">
                        <Tooltip title="Open in Maps">
                            <IconButton
                                sx={{ color: primaryColor }}
                                onClick={redirectToMaps}
                            >
                                <DirectionsIcon sx={{ fontSize: 50 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <TextField
                    disabled={disableFields}
                    inputlabelprops={{ shrink: baseForm.po ? true : false }}
                    autoComplete="po"
                    name="po"
                    variant="outlined"
                    id="po"
                    label="P.O."
                    sx={{ width: '100%', ...lockedInput }}
                    value={baseForm.po || ""}
                    onChange={handleChange}
                />
                <TextField
                    disabled={disableFields}
                    inputlabelprops={{ shrink: baseForm.requestedBy ? true : false }}
                    autoComplete="requestedBy"
                    name="requestedBy"
                    variant="outlined"
                    id="requestedBy"
                    label="Requested By"
                    sx={{ width: '100%', ...lockedInput }}
                    value={baseForm.requestedBy || ""}
                    onChange={handleChange}
                />
            </Box>
        </div >
    )
}

export default React.memo(BaseSheets);