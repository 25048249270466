import React from "react";
import {
    FormControl, Select, InputLabel, MenuItem,
} from "@mui/material"
function StageSelector({ currentUser, handleChange, value }) {
    return (
        <>
            {currentUser.account_level !== 'Technician' &&
                <FormControl style={{ width: '30ch' }}>
                    <InputLabel>Stage</InputLabel>
                    <Select
                        required
                        label="Stage"
                        onChange={handleChange}
                        id="stage"
                        name="stage"
                        value={value || 'Preliminary Review'}
                        defaultValue=""
                    >
                        <MenuItem value="Preliminary Review">Preliminary Review</MenuItem>
                        <MenuItem value="Ready for Review">Ready for Review</MenuItem>
                        <MenuItem value="Ready for Invoicing">Ready for Invoicing</MenuItem>
                        <MenuItem value="Archive" disabled={currentUser.account_level === 'Admin'
                            || currentUser.account_level === "Regional Admin" ? false : true}>Archive</MenuItem>
                    </Select>
                </FormControl>
            }
        </>
    )
}
export default StageSelector;