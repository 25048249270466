import SheetsApi from "../../../../api";

class HandleImage {
    constructor(sheetObj) {
        this.sheetObj = sheetObj;
        this.changes = {
            toPost: [],
            toDelete: []
        };
    }

    static async processChanges(images, type, sheetObj) {
        const processedArray = [];

        for (let image of images) {
            const imageFormData = new FormData();
            // if (type === "img" || type === "receipt") {
            if (type !== "signature") {
                if (Object.keys(sheetObj).length > 1) {
                    imageFormData.append("sheetId", sheetObj.id)
                    imageFormData.append("sheetType", sheetObj.sheet.sheetType)
                    imageFormData.append("date", sheetObj.sheet.dateServiced)
                    imageFormData.append("customer", sheetObj.sheet.customerName)
                    imageFormData.append("locationId", sheetObj.sheet.locationId || 0)
                }
                imageFormData.append("imageType", type === "img" ? "img" : type === "pdf" ? "pdf" : "receipt")
                if (type === "receipt") {
                    for (let key in image.receiptData) {
                        if (key !== 'selectedUser') {
                            imageFormData.append(key, image.receiptData[key]);
                        } else {
                            imageFormData.append(key, image.receiptData[key].username);
                        }
                    }
                }
                const originalFileName = image.name
                imageFormData.append(image.name, image.file, originalFileName);

            } else if (type === "signature") {
                if (!image.data) continue; // if there is no data then the user cleared the signature and we should not add anything to the array.
                // convert the data string into a file
                const stringOnly = image.data.replace(/^data:image\/(png|jpeg);base64,/, '')
                const byteCharacters = atob(stringOnly)
                const byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/png' });
                const file = new File([blob], 'image.png', { type: 'image/png' });
                // send file to server via FormData
                const signatureFormData = new FormData();
                signatureFormData.append("sheetId", sheetObj.id)
                signatureFormData.append("sheetType", sheetObj.sheet.sheetType)
                signatureFormData.append("imageType", "signature")
                signatureFormData.append("optionalSig", image.optionalSig)
                signatureFormData.append(image.name, file, image.name)
                await SheetsApi.uploadImage(signatureFormData)

            }
            processedArray.push(imageFormData)
        }

        return processedArray
    }

    static compareImages(allImages = [], oldImages = [], skipDelete = false) {
        // Filter images into arrays according to what action needs to be taken.
        const obj = {
            toPost: allImages.filter(newImg =>
                !oldImages.some(oldImg => oldImg.name === newImg.name)
            ),
            toDelete: skipDelete ? [] : oldImages.filter(oldImg =>
                !allImages.some(newImg => newImg.name === oldImg.name)
            ),
            unchanged: allImages.filter(newImg =>
                oldImages.some(oldImg => oldImg.name === newImg.name)
            )
        };
        return obj;
    }

    static async addAndRemove(allImages, sheetObj, skipDelete = false) {
        try {
            // 1. Get existing images from database
            let oldImages = [];
            if (sheetObj?.id) {
                oldImages = await SheetsApi.getLocalImages({ id: sheetObj.id, sheetType: sheetObj.sheet.sheetType });
            }
            const comparedImages = this.compareImages(allImages, oldImages, skipDelete);
            // 3. Group changes by type
            const groupedChanges = {
                toPost: this.groupImagesByType(comparedImages.toPost),
                toDelete: this.groupImagesByType(comparedImages.toDelete)
            };

            // 4. Process each type
            for (const [type, images] of Object.entries(groupedChanges.toPost)) {
                const processedImages = await this.processChanges(images, type, sheetObj);
                for (let processedImage of processedImages)
                    if (processedImage) await this.postImage(processedImage);
            }
            // Handle deletions if not skipped
            if (!skipDelete) {
                for (const [type, images] of Object.entries(groupedChanges.toDelete)) {
                    await this.deleteImage(images)
                }
            }

            return {
                success: true,
                message: 'Successfully processed all images'
            };

        } catch (error) {
            console.error('Error in addAndRemove:', error);
            return {
                success: false,
                message: error.message
            };
        }
    }

    static groupImagesByType(images) {
        //Seperates images by type img/sig/pdf/receipt
        return images.reduce((acc, image) => {
            const type = image.imageType || 'img';
            if (!acc[type]) acc[type] = [];
            acc[type].push(image);
            return acc;
        }, {});
    }

    static async getExistingImages(type) {
        if (!this.sheetObj?.id) return [];

        try {
            const images = await SheetsApi.getLocalImages({
                id: this.sheetObj.id,
                type
            });
            return images.filter(img => img.sheetType === this.sheetObj.sheet.sheetType);
        } catch (error) {
            console.error('Error fetching existing images:', error);
            return [];
        }
    }

    static async postImage(processedImage) {
        try {
            const response = await SheetsApi.uploadImage(processedImage)
            if (response.error) {
                return {
                    error: true,
                    message: 'There was an error when uploading the image. Your image was not saved.'
                }
            }
        } catch (error) {
            console.error('Error posting image:', error);
            throw error;
        }
    }

    static async deleteImage(image) {
        try {
            await SheetsApi.deleteImage(image);
        } catch (error) {
            console.error('Error deleting image:', error);
            throw error;
        }
    }

    static async setImage(img) {
        //determines if its a signature or photo, then gets image from azure db
        if (img.imageType !== 'signature') {
            const image = await SheetsApi.getImages({
                name: img.name,
                imageType: img.imageType
            })
            if (image.error) {
                return {
                    imageType: img.imageType,
                    name: img.name,
                    thumbnail: null,
                    downloadURL: null,
                    file: null,
                    receiptData: img.imageType === 'receipt' ? {
                        id: img.id,
                        creator: img.creator,
                        customer: img.customer,
                        description: img.description,
                        vehicle: img.vehicle,
                        largeJob: img.largeJob,
                        partNumber: img.partNumber,
                        receiptDate: img.receiptDate,
                        receiptDept: img.receiptDept,
                        selectedUser: img.selectedUser,
                        timestamp: img.timestamp,
                        category: img.category,
                        stage: img.stage,
                    } : null
                }
            }
            // convert string data into a file
            const binaryData = atob(image)
            const arrayBuffer = new ArrayBuffer(binaryData.length)
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                uint8Array[i] = binaryData.charCodeAt(i);
            }
            let blob;
            let file;
            if (img.imageType === 'pdf') {
                blob = new Blob([uint8Array], { type: 'application/pdf' });
                file = new File([blob], img.name, { type: 'application/pdf' });
            } else {
                blob = new Blob([uint8Array], { type: 'image/png' });
                file = new File([blob], img.name, { type: 'image/png' });
            }
            // const thumbnail = await HandleImage.createThumbnail(file);
            const dataURL = URL.createObjectURL(file);
            return {
                imageType: img.imageType,
                sheetType: img.sheetType,
                name: img.name,
                thumbnail: img.imageType !== 'pdf' && await HandleImage.createThumbnail(file),
                downloadURL: dataURL,
                file: file,
                receiptData: img.imageType === 'receipt' ? {
                    id: img.id,
                    creator: img.creator,
                    customer: img.customer,
                    description: img.description,
                    vehicle: img.vehicle,
                    largeJob: img.largeJob,
                    partNumber: img.partNumber,
                    receiptDate: img.receiptDate,
                    receiptDept: img.receiptDept,
                    selectedUser: img.selectedUser,
                    timestamp: img.timestamp,
                    category: img.category,
                    stage: img.stage,
                } : null
            }

        } else if (img.imageType === 'signature') {
            const signature = await SheetsApi.getImages({
                name: img.name,
                imageType: img.imageType,
            })
            return {
                data: 'data:image/png;base64,' + signature,
                name: img.name,
                sheetType: img.sheetType,
            }
        }
    }

    static async createThumbnail(singleFile) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const image = new Image();
                image.src = event.target.result;
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Set the dimensions for the thumbnail
                    const width = 100;
                    const height = (image.height / image.width) * width;

                    canvas.width = width;
                    canvas.height = height;

                    // Draw the image on the canvas
                    ctx.drawImage(image, 0, 0, width, height);
                    // Convert the canvas to a data URL (thumbnail)
                    const thumbnailURL = canvas.toDataURL('image/jpeg');
                    resolve(thumbnailURL);
                };
                image.onerror = (err) => {
                    console.error("There was an error when creating the thumbnail", err)
                }
            };
            reader.readAsDataURL(singleFile);
        });
    }
}

export default HandleImage;