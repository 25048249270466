import React, { useState, useEffect } from "react";
import {
    Box, FormControl, InputLabel, TextField, Button, Card, CardContent,
    CardHeader, Select, MenuItem, useTheme,
} from "@mui/material";
import useNotification from "../Hooks/useNotification";
import SheetsApi from "../api";
import useFields from "../Hooks/useFields";

function CustomerSettings({ customer }) {
    const theme = useTheme();
    const dark = theme.palette.default.main;
    const inputBackground = theme.palette.default.secondary;
    const mainColor = theme.palette.primary.main;
    const textColor = 'white';
    const messanger = useNotification();
    const [formData, handleLocal, resetFormData] = useFields({
        address: customer?.address || "",
        city: customer?.city || "",
        state: customer?.state || "",
        zip: customer?.zip || "",
        inactive: customer?.inactive || false,
    });

    useEffect(() => {
        if (customer) {
            handleLocal("replaceObj", { ...customer });
        }
    }, [customer, handleLocal]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await SheetsApi.updateCustomer({
            customer: customer.customer,
            ...formData
        });
        messanger(result.message, result.error ? "error" : "success");
    };

    return (
        <Box m={1}>
            <Card sx={{ width: '100%', bgcolor: dark }}>
                <CardHeader
                    titleTypographyProps={{ sx: { color: textColor } }}
                    title="Edit the customer details below." />
                <CardContent>
                    <Box mt={1}>
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    id="address"
                                    name="address"
                                    label="Address"
                                    value={formData.address || ""}
                                    onChange={handleLocal}
                                    size="small"
                                    InputLabelProps={{ style: { color: mainColor } }}
                                    sx={{ bgcolor: inputBackground, color: mainColor }}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    id="city"
                                    name="city"
                                    label="City"
                                    value={formData.city || ""}
                                    onChange={handleLocal}
                                    size="small"
                                    InputLabelProps={{ style: { color: mainColor } }}
                                    sx={{ bgcolor: inputBackground, color: mainColor }}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    id="state"
                                    name="state"
                                    label="State"
                                    value={formData.state || ""}
                                    onChange={handleLocal}
                                    size="small"
                                    InputLabelProps={{ style: { color: mainColor } }}
                                    sx={{ bgcolor: inputBackground, color: mainColor }}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    id="zip"
                                    name="zip"
                                    label="Zip"
                                    value={formData.zip || ""}
                                    onChange={handleLocal}
                                    size="small"
                                    InputLabelProps={{ style: { color: mainColor } }}
                                    sx={{ bgcolor: inputBackground, color: mainColor }}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="dense">
                                <InputLabel sx={{ color: mainColor }}>Status</InputLabel>
                                <Select
                                    id="inactive"
                                    name="inactive"
                                    value={formData.inactive || false}
                                    onChange={handleLocal}
                                    size="small"
                                    sx={{ bgcolor: inputBackground }}
                                >
                                    <MenuItem value={false}>Active</MenuItem>
                                    <MenuItem value={true}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                            <Box mt={1}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ bgcolor: mainColor, color: 'white' }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default CustomerSettings;