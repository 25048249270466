import React, { useState, useEffect, useContext } from "react";
import useFields from "../../../Hooks/useFields";
import { TextField, Button, Box, IconButton, useTheme, Tooltip } from "@mui/material";
import { useGeolocated } from "react-geolocated";
import useChemAppStore from "../../../stores/chemAppStore";
import useNotification from "../../../Hooks/useNotification"
import SheetsApi from "../../../api";
import UserContext from "../../../Users/UserContext";
import DirectionsIcon from '@mui/icons-material/Directions';

function Coords({ customer, }) {
    const INITIAL_STATE = {
        lat: 0,
        long: 0,
    }
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const { currentUser } = useContext(UserContext)
    const messanger = useNotification()
    const [render, setRender] = useState(false);
    const [formData, handleLocal] = useFields(INITIAL_STATE)
    const [chemAppForm, setChemAppForm] = useChemAppStore((state) => (
        [state.chemAppForm, state.setChemAppForm]))
    const [address, setAddress] = useState("");
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });

    useEffect(() => {
        const getCustomer = async function () {
            return await SheetsApi.getCustomer({ customer: customer })
        }
        getCustomer().then(customer => {
            if (typeof customer.address === 'string' && customer.address.trim() &&
                typeof customer.city === 'string' && customer.city.trim() &&
                typeof customer.state === 'string' && customer.state.trim() &&
                typeof customer.zip === 'string' && customer.zip.trim()) {
                setAddress(`${customer.address} ${customer.city} ${customer.state} ${customer.zip}`);
            }
        })
        const coordObj = {
            lat: chemAppForm.lat,
            long: chemAppForm.long,
        }
        handleLocal("replaceObj", coordObj);
    }, []);

    const getCoords = function () {
        if (!isGeolocationAvailable) {
            return messanger("Your browser does not support Geolocation.", "error");
        } else if (!isGeolocationEnabled) {
            return messanger("Geolocation is not enabled", "error");
        } else {
            const long = coords.longitude
            const lat = coords.latitude
            handleLocal("invalidInput", "lat", lat)
            handleLocal("invalidInput", "long", long)
            setChemAppForm("long", long)
            setChemAppForm("lat", lat)
            setRender(!render)
        }
    }
    const handleChange = function (e) {
        const name = e.target.name
        let value = parseFloat(formData[name])
        setChemAppForm(name, value)
    }

    return (<>
        <Box display={currentUser.mobile ? 'block' : 'flex'} justifyContent="left">
            <TextField
                id="lat"
                name="lat"
                label="Latitude"
                type="number"
                sx={{ mr: 1, width: "100%" }}
                value={formData.lat || ""}
                onChange={handleLocal}
                onBlur={handleChange}
            />
            <TextField
                id="long"
                name="long"
                label="Longitude"
                type="number"
                sx={{ mt: currentUser.mobile && 1, mr: 1, width: "100%" }}
                value={formData.long || ""}
                onChange={handleLocal}
                onBlur={handleChange}
            />
            <Box ml={1} display="flex">
                <Button
                    fullWidth
                    variant="contained"
                    onClick={getCoords}
                    sx={{ mt: .5, height: '50px', width: 275 }}>
                    Get Coords
                </Button>
            </Box>
        </Box>
        <Box mb={1} ml={1} mr={1}>
            <TextField
                id="address"
                name="address"
                label="Address"
                type="text"
                variant="standard"
                sx={{ width: "100%" }}
                value={address}
                disabled
                InputProps={{
                    sx: {
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#000000',
                            color: '#000000',
                            cursor: 'not-allowed',
                            paddingRight: '30px'
                        },
                        '& .MuiInputBase-root.Mui-disabled': {
                            '&::after': {
                                position: 'absolute',
                                right: '8px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                pointerEvents: 'none'
                            }
                        },
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                        '& .MuiInputBase-input': {
                            color: 'text.primary',
                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                            opacity: 1
                        },
                        '& .MuiInputLabel-root': {
                            color: 'rgba(0, 0, 0, 0.87)'
                        }
                    }
                }}
            />
        </Box >
    </>
    )
}

export default Coords;