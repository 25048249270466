import React, { useState, useEffect } from "react"
import SheetsApi from "../../api"
import Part from "./Part";
import {
    Button, Box, TextField, Paper,
    TableRow, TableHead, Table, TableBody, TableCell,
    TableContainer, InputAdornment, Stack, Typography, Chip
} from "@mui/material";
import usePartsStore from "../../stores/partsStore";
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';

function PartsSheet({ toggle, calcOn }) {
    const [partsList, setPartsList] = useState([])
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const [calcValue, setCalc] = useState(0)
    const [addPart, parts, addMiscPart, setTotal] = usePartsStore(state =>
        [state.addPart, state.parts, state.addMiscPart, state.setTotal])

    const partObj = {
        qty: "",
        type: "",
        part: "",
        description: "",
        cost: "",
        isMisc: false
    }

    const miscPartObj = {
        isMisc: true,
        qty: "",
        type: "",
        part: "",
        description: "",
        cost: "",
        location: "",
        name: "",
        invoice: "",
        po: ""
    }


    const additionalPart = (e) => {
        e.preventDefault()
        return addPart(partObj)
    }

    const additionalMiscPart = (e) => {
        e.preventDefault()
        return addMiscPart(miscPartObj)
    }

    useEffect(() => {
        async function getParts() {
            try {
                const parts = await SheetsApi.getAllActiveParts()
                return parts
            } catch (err) {
                console.log(err)
            }
        }
        getParts().then(parts => {
            setPartsList(parts)
        })
    }, [])

    const calculator = function () {
        let total = 0;
        parts.forEach(part => {
            let cost = parseFloat(part.cost) || 0
            let qty = parseFloat(part.qty) || 0
            cost = parseFloat((cost * qty).toFixed(2))
            total += cost
        })
        setTotal(total);
        setCalc(total);
    }
    return (
        <Paper elevation={2} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
            <Stack spacing={0}>
                <Box sx={{ 
                    p: 1.5, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Parts Details
                    </Typography>
                    {calcOn && toggle && (
                        <Chip
                            label={`Total: $${calcValue.toFixed(2)}`}
                            color="primary"
                            variant="outlined"
                            size="small"
                        />
                    )}
                </Box>

                <TableContainer sx={{ maxHeight: '60vh' }}>
                    <Table 
                        size="small" 
                        sx={{
                            '& .MuiTableCell-root': {
                                py: 0.75,
                                px: 1,
                                fontSize: '0.875rem'
                            }
                        }}
                    >
                        <TableBody>
                            {parts.map((item, index) => (
                                <Part
                                    toggle={toggle}
                                    calculator={calculator}
                                    key={index}
                                    id={index}
                                    item={item}
                                    partsList={partsList}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ 
                    p: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    gap: 1
                }} className="hideForPrint">
                    <Button
                        size="small"
                        variant="contained"
                        onClick={additionalPart}
                        startIcon={<AddIcon />}
                        sx={{ 
                            textTransform: 'none',
                            fontWeight: 500
                        }}
                    >
                        Add Part
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={additionalMiscPart}
                        startIcon={<BuildIcon />}
                        sx={{ 
                            textTransform: 'none',
                            fontWeight: 500
                        }}
                    >
                        Add SERPART
                    </Button>
                </Box>
            </Stack>
        </Paper>
    );
}

export default React.memo(PartsSheet);



