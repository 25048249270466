import { useState, useCallback, forwardRef } from "react";
import clsx from "clsx";
import { useSnackbar, SnackbarContent } from "notistack";
import {
    Collapse, Paper, Typography, Card, Box, CardActions,
    Button, IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const classes = {
    root: {
        "@media (min-width:600px)": {
            minWidth: "344px !important"
        }
    },
    card: {
        width: "100%"
    },
    typography: {
        color: "#000"
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        justifyContent: "space-between"
    },
    icons: {
        marginLeft: "auto"
    },
    expand: {
        padding: "8px 8px",
        transform: "rotate(0deg)",
        color: "#000",
        transition: "all .2s"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    paper: {
        backgroundColor: "#fff",
        padding: 16
    },
    checkIcon: {
        fontSize: 20,
        paddingRight: 4
    },
    button: {
        padding: 0,
        textTransform: "none"
    }
}

const DownloadCard = forwardRef(({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <Card className={classes.card} sx={{ backgroundColor: "#fddc6c", width: '40ch' }}>
                <CardActions classes={{ root: classes.actionRoot }}>
                    <Box ml={2}>
                        <Typography variant="body2" className={classes.typography}>
                            {props.message}
                        </Typography>
                    </Box>
                    <div className={classes.icons}>
                        <IconButton
                            size="small"
                            className={classes.expand}
                            onClick={handleDismiss}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            aria-label="Show more"
                            size="small"
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper className={classes.paper}>
                        <Box ml={2}>
                            <Typography
                                gutterBottom
                                variant="caption"
                                style={{ color: "#000", display: "block" }}
                            >
                                PDF ready
                            </Typography>
                        </Box>
                        <Button size="small" color="primary" className={classes.button}>
                            <CheckCircleIcon className={classes.checkIcon} />
                            Download
                        </Button>
                    </Paper>
                </Collapse>
            </Card>
        </SnackbarContent>
    );
});

export default DownloadCard;