import React, { useEffect } from "react";
import {
    TextField, TableRow, FormControl, Button, Box, IconButton,
    InputLabel, Select, MenuItem, TableCell, ListSubheader,
} from "@mui/material";
import useTechStore from "../../stores/techStore";
import useFields from "../../Hooks/useFields";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function Tech({ toggle, techs, item, id, calculator, timeTrack, chemApp }) {
    const INITIAL_STATE = {
        technician: "",
        truck: "",
        labor: null,
        travel: null,
        nc: null,
        notes: "",
        timeStart: "",
        timeEnd: "",
    }
    const [formData, handleLocalTech, resetFormData] = useFields(INITIAL_STATE)
    const [techForm, setTechForm, clearTech] = useTechStore(state =>
        [state.techs, state.setTech, state.clearTech]);

    const handleDate = function (name, value) {
        console.log(name, value, id);
        return setTechForm(name, value, id)
    }
    const handleChange = function (e, passedName, fId) {
        if (!e) {
            return
        } else if (e.$L) {
            //e in this case is a timestamp. Not an event.
            return setTechForm(passedName, e, fId)
        }
        const value = e.target.value
        setTechForm(passedName, value, fId)
    }
    const removeTech = function (e) {
        e.preventDefault()
        resetFormData()
        clearTech(id)
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    useEffect(() => {
        if (item.technician !== "") {
            handleLocalTech("replaceObj", item)
            calculator()
        }
    }, [])

    return (
        <TableRow sx={{ 
            '&:hover': { bgcolor: 'action.hover' },
            transition: 'background-color 0.2s'
        }}>
            <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                {techs ?
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                        <InputLabel>Technician</InputLabel>
                        <Select
                            inputlabelprops={{ shrink: true }}
                            onChange={(e) => handleLocalTech(e, "technician", e.target.value)}
                            onBlur={(e) => handleChange(e, "technician", id)}
                            value={formData.technician || ""}
                            labelId="tech"
                            id={`tech${id}`}
                            name="technician"
                            defaultValue=""
                            label="Technician"
                            sx={{ 
                                '& .MuiSelect-select': { 
                                    fontSize: '0.875rem',
                                    py: 0.75
                                }
                            }}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <ListSubheader style={{ textAlign: 'center' }}>Managers</ListSubheader>
                            {techs.managers.length ? techs.managers.map((tech) => {
                                return (
                                    <MenuItem
                                        key={tech.username}
                                        value={tech.username || ""}>
                                        {tech.username}
                                    </MenuItem>
                                )
                            }) : null}
                            <ListSubheader style={{ textAlign: 'center' }}>Technicians</ListSubheader>
                            {techs.technicians.length ? techs.technicians.map((tech) => {
                                return (
                                    <MenuItem
                                        key={tech.username}
                                        value={tech.username || ""}>
                                        {tech.username}
                                    </MenuItem>
                                )
                            }) : null}
                            <ListSubheader style={{ textAlign: 'center' }}>Other</ListSubheader>
                            {techs.other.length ? techs.other.map((tech) => {
                                return (
                                    <MenuItem
                                        key={tech.username}
                                        value={tech.username || ""}>
                                        {tech.username}
                                    </MenuItem>
                                )
                            }) : null}
                        </Select>
                    </FormControl>
                    : null}
            </TableCell>
            <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Truck</InputLabel>
                    <Select
                        label="Truck"
                        id="truck"
                        name="truck"
                        onChange={handleLocalTech}
                        onBlur={(e) => handleChange(e, "truck", id)}
                        value={formData.truck || ""}
                        sx={{ 
                            '& .MuiSelect-select': { 
                                fontSize: '0.875rem',
                                py: 0.75
                            }
                        }}
                        defaultValue=""
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="Service 1">Service 1</MenuItem>
                        <MenuItem value="Service 2">Service 2</MenuItem>
                        <MenuItem value="Service 3">Service 3</MenuItem>
                        <MenuItem value="Service 4">Service 4</MenuItem>
                        <MenuItem value="Service 5">Service 5</MenuItem>
                        <MenuItem value="Sprout Nip 1">Sprout Nip 1</MenuItem>
                        <MenuItem value="Sprout Nip 2">Sprout Nip 2</MenuItem>
                        <MenuItem value="Sprout Nip 3">Sprout Nip 3</MenuItem>
                        <MenuItem value="Sprout Nip 4">Sprout Nip 4</MenuItem>
                        <MenuItem value="Sprout Nip 5">Sprout Nip 5</MenuItem>
                        <MenuItem value="Sprout Nip 6">Sprout Nip 6</MenuItem>
                        <MenuItem value="Sprout Nip 7">Sprout Nip 7</MenuItem>
                        <MenuItem value="Sprout Nip 8">Sprout Nip 8</MenuItem>
                        <MenuItem value="Storage Managment 1">Storage Managment 1</MenuItem>
                        <MenuItem value="Storage Managment 2">Storage Managment 2</MenuItem>
                        <MenuItem value="Storage Managment 3">Storage Managment 3</MenuItem>
                        <MenuItem value="Storage Managment 4">Storage Managment 4</MenuItem>
                        <MenuItem value="Storage Managment 5">Storage Managment 5</MenuItem>
                        <MenuItem value="Sales 1">Sales 1</MenuItem>
                        <MenuItem value="Sales 2">Sales 2</MenuItem>
                        <MenuItem value="Sales 3">Sales 3</MenuItem>
                        <MenuItem value="Sales 4">Sales 4</MenuItem>
                        <MenuItem value="Sales 5">Sales 5</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            {!timeTrack &&
                <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                    <TextField
                        size="small"
                        name="labor"
                        id="labor"
                        label="Labor"
                        type="number"
                        onBlur={() => calculator()}
                        onChange={(e) => handleChange(e, "labor", id)}
                        value={techForm[id].labor || ""}
                        sx={{ width: 100 }}
                    />
                </TableCell>
            }
            <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                <TextField
                    size="small"
                    name="travel"
                    id="travel"
                    label="Travel"
                    type="number"
                    onBlur={() => calculator()}
                    onChange={(e) => handleChange(e, "travel", id)}
                    value={techForm[id].travel || ""}
                    sx={{ width: 100 }}
                />
            </TableCell>
            {toggle && <>
                {!timeTrack &&
                    <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                        <TextField
                            size="small"
                            name="nc"
                            id="nc"
                            label="N/C"
                            type="number"
                            onBlur={() => calculator()}
                            onChange={(e) => handleChange(e, "nc", id)}
                            value={techForm[id].nc || ""}
                            sx={{ width: 100 }}
                        />
                    </TableCell>
                }
            </>
            }
            {toggle && <>
                {!timeTrack ? <>
                    <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }}>
                        <TextField
                            size="small"
                            label="Notes"
                            name="notes"
                            id="notes"
                            onBlur={(e) => handleChange(e, "notes", id)}
                            onChange={handleLocalTech}
                            value={formData.notes || ""}
                            inputProps={{ style: { fontSize: "0.8rem" } }}
                            sx={{ width: "40ch" }}
                        />
                    </TableCell>
                    <TableCell className="hideForPrint" align="right" sx={{ width: 40 }}>
                        <IconButton
                            size="small"
                            onClick={removeTech}
                            sx={{ 
                                color: 'error.light',
                                '&:hover': { color: 'error.main' }
                            }}
                        >
                            <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </> : <>               
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TableCell>
                            <MobileTimePicker
                                label={chemApp ? "Storage Arrival" : "Time Start"}
                                name="timeStart"
                                onChange={(e) => handleChange(e, "timeStart", id)}
                                value={techForm[id].timeStart}
                                inputVariant="standard"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={false}
                                        sx={{ width: "19ch" }} />}
                            />
                        </TableCell>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TableCell>
                            <MobileTimePicker
                                label={chemApp ? "Storage Depart" : "Time End"}
                                name="timeEnd"
                                onChange={(e) => handleChange(e, "timeEnd", id)}
                                value={techForm[id].timeEnd}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={false}
                                        sx={{ width: "19ch" }} />}
                            />
                        </TableCell>
                    </LocalizationProvider>
                </>}
            </>}
        </TableRow >
    )
}

export default Tech;