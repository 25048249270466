import React, { useState, useEffect, useContext } from "react";
import {
    FormControl, InputLabel, Select, MenuItem,
    ListSubheader,
} from '@mui/material';
import SheetsApi from "../../api";
import UserContext from "../../Users/UserContext";

function TechSelect({ name, id, edit, formData, handleLocal }) {
    const { currentUser } = useContext(UserContext);
    const [techs, setTechs] = useState({ managers: [], technicians: [], other: [] })

    useEffect(() => {
        async function getTechs() {
            try {
                if (edit && currentUser.account_level === 'Admin') {
                    return await SheetsApi.getInactive()
                } else {
                    return await SheetsApi.getAllUsers()
                }
            } catch (err) {
                console.log(err)
            }
        }
        getTechs().then(techs => {
            const categorizedUsers = { managers: [], technicians: [], other: [] };
            techs.forEach(tech => {
                if (tech.account_level === 'Manager') categorizedUsers.managers.push(tech);
                if (tech.account_level === 'Technician') categorizedUsers.technicians.push(tech);
                else if (tech.account_level === 'Admin' || tech.account_level === 'Regional Admin') {
                    categorizedUsers.other.push(tech);
                }
            })
            setTechs(categorizedUsers);
        })
    }, []);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const allTechs = [
        ...techs.managers.map(tech => tech.username),
        ...techs.technicians.map(tech => tech.username),
        ...techs.other.map(tech => tech.username)
    ];

    const selectedValue = allTechs.includes(formData[id]) ? formData[id] : "";

    return (
        <>
            <FormControl sx={{ width: "100%" }}>
                <InputLabel>{name}</InputLabel>
                <Select
                    inputlabelprops={{ shrink: true }}
                    id={id}
                    name={id}
                    label={name}
                    fullWidth
                    MenuProps={MenuProps}
                    onChange={handleLocal}
                    value={selectedValue}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <ListSubheader style={{ textAlign: 'center' }}>Managers</ListSubheader>
                    {techs.managers.length ? techs.managers.map((tech) => {
                        return (
                            <MenuItem
                                key={tech.username}
                                value={tech.username || ""}>
                                {tech.username}
                            </MenuItem>
                        )
                    }) : null}
                    <ListSubheader style={{ textAlign: 'center' }}>Technicians</ListSubheader>
                    {techs.technicians.length ? techs.technicians.map((tech) => {
                        return (
                            <MenuItem
                                key={tech.username}
                                value={tech.username || ""}>
                                {tech.username}
                            </MenuItem>
                        )
                    }) : null}
                    <ListSubheader style={{ textAlign: 'center' }}>Other</ListSubheader>
                    {techs.other.length ? techs.other.map((tech) => {
                        return (
                            <MenuItem
                                key={tech.username}
                                value={tech.username || ""}>
                                {tech.username}
                            </MenuItem>
                        )
                    }) : null}
                </Select>
            </FormControl>
        </>
    )
}

export default TechSelect;