import React from "react";
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    table: {
        width: '100%',
        marginBottom: 15,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e2e8f0',
        minHeight: 25,
        alignItems: 'center',
    },
    tableHeader: {
        backgroundColor: '#f6e3c3',
        fontWeight: 'bold',
    },
    tableCell: {
        padding: 5,
        fontSize: 10,
    },
    qtyCell: {
        width: '15%',
        textAlign: 'center',
    },
    partNumberCell: {
        width: '35%',
    },
    descriptionCell: {
        width: '50%',
        borderRightWidth: 0, 
    },
    sectionTitle: {
        fontSize: 11,
        fontWeight: 'bold',
        padding: 8,
        backgroundColor: '#f8f9fa',
        borderBottomWidth: 1,
        borderBottomColor: '#888',
        color: '#333',
    },
    alternateRow: {
        backgroundColor: '#fafbfc',
    }
});

const renderTableHeaders = () => (
    <View style={[styles.tableRow, styles.tableHeader]}>
        <Text style={[styles.tableCell, styles.qtyCell]}>Qty</Text>
        <Text style={[styles.tableCell, styles.partNumberCell]}>Part Number</Text>
        <Text style={[styles.tableCell, styles.descriptionCell]}>Description</Text>
    </View>
);

const renderTableRow = (item) => (
    <View style={styles.tableRow} key={item.indexId}>
        <Text style={[styles.tableCell, styles.qtyCell]}>{item.qty}</Text>
        <Text style={[styles.tableCell, styles.partNumberCell]}>{item.partNumber}</Text>
        <Text style={[styles.tableCell, styles.descriptionCell]}>{item.description}</Text>         
    </View>
);

const renderClimaBase = (base) => (
    <View style={styles.tableRow}>
        <Text style={styles.tableCell}>Clima Cell Base</Text>
        <Text style={styles.tableCell}>
            {`${base.width}x${base.height}x${base.depth}`}
        </Text>
        <Text style={styles.tableCell}>-</Text>
        <Text style={styles.tableCell}>
            {`Qty: ${base.qty}`}
        </Text>
        <Text style={styles.tableCell}>
            {`Edge Coat: ${base.edgeCoat ? 'Yes' : 'No'}`}
        </Text>
    </View>
);

function StorageAssessPrintTable({ data }) {
    return (
        <View style={styles.table}>
            {Array.isArray(data) ? (
                <>
                    {renderTableHeaders()}
                    {data.map(item => renderTableRow(item))}
                </>
            ) : (
                renderClimaBase(data)
            )}
        </View>
    );
}

export default StorageAssessPrintTable;