import React, { useEffect } from "react";
import useBayStore from "../../stores/bayStore";
import useFields from "../../Hooks/useFields";
import {
    Typography, Box, TextareaAutosize,
    TextField, Divider, useTheme, InputAdornment,
    RadioGroup, FormControlLabel, Radio, Button,
    Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import DeltaT from "./DeltaT";
import RemoveCircle from '@mui/icons-material/RemoveCircleOutlineSharp';
import CustomCheckbox from "./CustomCheckbox";

function Bay({ id, item, edit }) {
    const idPass = id;
    const itemPass = item;
    const editPass = edit;
    const INITIAL_STATE = {
        bay: "",
        avgDeltaTTotal: 0,
        topPileConditions: "",
        none: false,
        eyesOpen: false,
        peeping: false,
        lessThanHalfIn: false,
        greaterThanHalfIn: false,
        sporadic: false,
        moderate: false,
        heavy: false,
        hotSpot: false,
        notes: "",
    }
    const theme = useTheme()
    const defaultColor = theme.palette.default.main
    const [bay, setBayForm, removeBay] = useBayStore(state => [state.bays[id], state.setBayForm, state.removeBay])
    const [formData, handleLocal] = useFields(INITIAL_STATE)

    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", item)
        }
    }, [])

    useEffect(() => {
        //calculates the average delta T total
        let total = 0;
        let avgDivider = 0;
        const deltaTs = [parseFloat(bay.frontAvgDeltaT) || 0, parseFloat(bay.middleAvgDeltaT) || 0, parseFloat(bay.backAvgDeltaT) || 0]
        for (let number of deltaTs) {
            if (number !== 0) {
                avgDivider++;
                total += number;
            }
        }
        const totalAverage = parseFloat((total / avgDivider).toFixed(3));
        handleLocal("invalidInput", "avgDeltaTTotal", totalAverage)
    }, [bay.frontAvgDeltaT, bay.middleAvgDeltaT, bay.backAvgDeltaT])

    const handleChange = function (e, checkBox) {
        let value;
        const name = e.target.name
        if (checkBox === true) {
            value = e.target.checked
        } else {
            value = e.target.value
        }
        setBayForm(name, value, id);
    }

    const removeBayLocal = function (e) {
        e.preventDefault()
        removeBay(id)
    }
    const sproutingOptions = [
        { label: 'None', name: 'none' },
        { label: 'Controlled', name: 'controlled' },
        { label: 'Eyes Open', name: 'eyesOpen' },
        { label: 'Peeping', name: 'peeping' },
        { label: 'Less Than 1/2"', name: 'lessThanHalfIn' },
        { label: 'Greater Than 1/2"', name: 'greaterThanHalfIn' }
    ];

    const breakdownOptions = [
        { label: 'Sporadic', name: 'sporadic' },
        { label: 'Mild', name: 'mild' },
        { label: 'Moderate', name: 'moderate' },
        { label: 'Heavy', name: 'heavy' },
        { label: 'Hot Spot', name: 'hotSpot' }
    ];

    return (
        <>
            <Box className={id === 2 ? "nextPage" : ""}>
                <Box mb={1} >
                    <Divider sx={{ borderBottomWidth: 3 }} />
                </Box>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Box>
                        <TextField
                            name={`bay`}
                            id={`bay${id}`}
                            label={`Bay ${id + 1}`}
                            sx={{ width: "75%" }}
                            value={formData.bay || ""}
                            onBlur={handleChange}
                            onChange={(e) => handleLocal(e)}
                        />
                        <Button
                            sx={{ paddingTop: "15px" }}
                            onClick={removeBayLocal}
                            color="secondary"
                            type="submit">
                            <RemoveCircle />
                        </Button>
                    </Box>
                    <Box m={1} justifyContent="start" display="flex">
                        <Typography variant="h5">
                            Pulp Temps:
                        </Typography>
                        <TextField
                            id="avgDeltaTTotal"
                            name="avgDeltaTTotal"
                            label="Average Delta T"
                            variant="standard"
                            value={formData.avgDeltaTTotal || 0}
                            sx={{ ml: 2, width: '13ch' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    °F</InputAdornment>,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </Box>
                <Box ml={2} mb={1} >
                    <DeltaT
                        edit={editPass}
                        bayId={idPass}
                        item={itemPass}
                        topName="topFront"
                        bottomName="bottomFront"
                        avgDeltaTName="frontAvgDeltaT"
                    />
                    <DeltaT
                        edit={editPass}
                        bayId={idPass}
                        item={itemPass}
                        topName="topMiddle"
                        bottomName="bottomMiddle"
                        avgDeltaTName="middleAvgDeltaT"
                    />
                    <DeltaT
                        edit={editPass}
                        bayId={idPass}
                        item={itemPass}
                        topName="topBack"
                        bottomName="bottomBack"
                        avgDeltaTName="backAvgDeltaT"
                    />
                    <Box mt={1}>
                        <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                            <FormControl>
                                <InputLabel>
                                    Duct Liquid
                                </InputLabel>
                                <Select
                                    sx={{ width: "17ch", fontSize: '0.8rem' }}
                                    label="Duct Liquid ex"
                                    id="ductLiquid"
                                    name="ductLiquid"
                                    value={formData.ductLiquid || ""}
                                    onChange={handleLocal}
                                    onBlur={(e) => handleChange(e)}>
                                    <MenuItem value="none"><em>None</em></MenuItem>
                                    <MenuItem value="light">Light</MenuItem>
                                    <MenuItem value="moderate">Moderate</MenuItem>
                                    <MenuItem value="excessive">Excessive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mb={1} ml={1} style={{ display: 'inline-block' }}>
                            <FormControl>
                                <InputLabel htmlFor="odors">
                                    Odors
                                </InputLabel>
                                <Select
                                    label="Odors x"
                                    sx={{ width: "15ch", fontSize: '0.8rem' }}
                                    id="odors"
                                    name="odors"
                                    value={formData.odors || ""}
                                    onChange={handleLocal}
                                    onBlur={(e) => handleChange(e)}>
                                    <MenuItem value="none"><em>None</em></MenuItem>
                                    <MenuItem value="light">Light</MenuItem>
                                    <MenuItem value="moderate">Moderate</MenuItem>
                                    <MenuItem value="excessive">Excessive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                            <FormControl>
                                <InputLabel htmlFor="bugs">
                                    Bugs
                                </InputLabel>
                                <Select
                                    label="Bugs"
                                    sx={{ width: "15ch", fontSize: '0.8rem' }}
                                    id="bugs"
                                    name="bugs"
                                    value={formData.bugs || ""}
                                    onChange={handleLocal}
                                    onBlur={(e) => handleChange(e)}>
                                    <MenuItem value="none"><em>None</em></MenuItem>
                                    <MenuItem value="light">Light</MenuItem>
                                    <MenuItem value="moderate">Moderate</MenuItem>
                                    <MenuItem value="excessive">Excessive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography
                            variant="h5">
                            Top of Pile Moisture Conditions:
                        </Typography>
                        <Box
                            ml={2}
                            style={{ display: 'inline-block' }}>

                            <RadioGroup
                                name="topPileConditions"
                                id="topPileConditions"
                                value={formData.topPileConditions || ""}
                                onBlur={handleChange}
                                onChange={(e) => {
                                    handleLocal("invalidInput", e.target.name, e.target.value)
                                    handleChange(e)
                                }}
                                row>
                                <FormControlLabel value="Dry" control={<Radio />} label="Dry" />
                                <FormControlLabel value="Condensation" control={<Radio />} label="Condensation" />
                                <FormControlLabel value="Damp" control={<Radio />} label="Damp" />
                                <FormControlLabel value="Wet" control={<Radio />} label="Wet" />
                            </RadioGroup>
                        </Box>
                        <br></br>
                        <Typography variant="h5">Sprouting:</Typography>
                        {sproutingOptions.map(({ label, name }) => (
                            <CustomCheckbox
                                key={name}
                                label={label}
                                name={name}
                                checked={formData[name]}
                                onChange={handleChange}
                                handleLocal={handleLocal}
                            />
                        ))}

                        <Typography variant="h5">Breakdown:</Typography>
                        {breakdownOptions.map(({ label, name }) => (
                            <CustomCheckbox
                                key={name}
                                label={label}
                                name={name}
                                checked={formData[name]}
                                onChange={handleChange}
                                handleLocal={handleLocal}
                            />
                        ))}
                    </Box>
                    <Box>
                        <Typography
                            variant="h5"
                            ml={1}
                            style={{ color: defaultColor }}>
                            Notes:
                        </Typography>
                    </Box>
                    <Box ml={0.25} >
                        <TextareaAutosize
                            minRows={4}
                            style={{
                                width: "99.0%",
                                outline: '2px solid #000',
                                fontSize: "16px",
                            }}
                            aria-label="maximum height"
                            name="notes"
                            value={formData.notes || ""}
                            onBlur={handleChange}
                            onChange={handleLocal}
                        />
                    </Box>
                </Box >
            </Box>
        </>
    )
}

export default Bay;