import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Box, FormControlLabel, Checkbox, FormControl,
    Select, InputLabel, MenuItem, Tooltip, IconButton,
} from '@mui/material';

function FollowUp({ state, setState, optionalFunction }) {

    return (
        <>
            <Box m={1} display="inline">
                <FormControlLabel
                    label={!state.followUp ? `Follow-up Service needed?` : ""}
                    control={<Checkbox
                        name="followUp"
                        checked={state.followUp || false}
                        onChange={(e) => {
                            setState(e);
                            optionalFunction && optionalFunction(e, true);
                        }}
                    />}
                />
                {state.followUp && <>
                    <FormControl style={{ width: '30ch' }}>
                        <InputLabel>Assign notification to department</InputLabel>
                        <Select
                            required
                            label="Assign notification to department"
                            id="followUpDept"
                            name="followUpDept"
                            onChange={setState}
                            onBlur={optionalFunction && optionalFunction}
                            value={state.followUpDept || ""}
                        >
                            <MenuItem key="service" value="service">Service</MenuItem>
                            <MenuItem key="chemApps" value="chemApps">Chem Apps</MenuItem>
                            <MenuItem key="labs" value="labs">Storage Managment</MenuItem>
                        </Select>
                    </FormControl>
                    <Tooltip title="Assign the follow up notification to a department. For example; for damaged equipment assign to service. For sprouting assign to chem apps. Only the managers in that department will see this notification.">
                        <IconButton>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </>}
            </Box>

        </>
    )
}

export default FollowUp;