import React, { useContext, useEffect, useState } from "react";
import {
    Card, CardContent, Typography, CardHeader,
    Box, Button, CardActions, FormControl, Select,
    MenuItem, InputLabel, IconButton,
} from "@mui/material";
import useStorageAssessmentStore from "../../stores/storageAssessmentStore";
import DateInput from "../../Sheets/SheetComponents/DateInput";
import SheetsApi from "../../api";
import useNotification from "../../Hooks/useNotification";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import dayjs from "dayjs";
import UserContext from "../../Users/UserContext";

function LocationCropCard({ edit, locationId }) {
    const messanger = useNotification();
    const { currentUser } = useContext(UserContext)
    const [setCrop, crop] = useStorageAssessmentStore(state => [state.setCrop, state.crop]);
    const [cropHistory, setCropHistory] = useState([]);
    const [varieties, setVarieties] = useState([]);

    const addCropHistory = async function () {
        if (!crop.crop || !crop.startDate || !crop.endDate) {
            return messanger("Please fill all the fields", "warning")
        }
        const response = await SheetsApi.addAssessmentCrop({ ...crop, locationId });
        messanger(response.message, response.error ? "error" : "success")
        if (!response.error) {
            setCropHistory([...cropHistory, { ...crop, id: response.id }])
        }
    }
    const removeAssessCrop = async function (id) {
        const newCrophistory = cropHistory.filter((obj) => obj.id !== id)
        setCropHistory(newCrophistory)
        const response = await SheetsApi.removeAssessmentCrop({ id, locationId });
        return messanger(response.message, response.error ? "error" : "success")
    }

    const formatedDate = (date) => {
        const dateObj = dayjs(date);
        return dateObj.format('MM/DD/YYYY');
    }

    useEffect(() => {
        const getCropAssessment = async function () {
            const varieties = await SheetsApi.getVarieties();
            const assessmentCrop = await SheetsApi.getAssessmentCrop({ locationId });
            return { varieties, assessmentCrop };
        }
        getCropAssessment().then(({ varieties, assessmentCrop }) => {
            setVarieties(varieties)
            return setCropHistory(assessmentCrop);
        })
    }, []);

    return (<>
        <Card sx={{
            mt: currentUser.mobile ? 2 : 0,
            ml: currentUser.mobile ? 0 : 2,
            bgcolor: 'grey.100',
            boxShadow: 1,
            borderRadius: 2,
            width: currentUser.mobile ? '100%' : '40%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CardHeader
                sx={{
                    bgcolor: 'grey.100',
                    p: 1,
                }}
                title={
                    <Typography variant="h6">
                        Crop
                    </Typography>
                }
            />
            <CardContent sx={{ p: 2 }}>
                <Box display="flex"
                    flexDirection={currentUser.mobile ? "column" : "row"}
                    justifyContent="space-between">
                    <Box width={currentUser.mobile ? "100%" : "25ch"}>
                        <Box mb={1}>
                            <FormControl sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                },
                            }}>
                                <InputLabel>Crop</InputLabel>
                                <Select
                                    name="crop"
                                    label="Crop"
                                    value={crop.crop}
                                    onChange={(e) => setCrop('crop', e.target.value)}
                                >
                                    {varieties.map((variety) => (
                                        <MenuItem key={variety.id} value={variety.varietyName}>{variety.varietyName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mb={1}>
                            <DateInput
                                state={crop.startDate}
                                setState={setCrop}
                                variableName="startDate"
                                label="Start Date"
                                required
                            />
                        </Box>
                        <Box mb={1}>
                            <DateInput
                                state={crop.endDate}
                                setState={setCrop}
                                variableName="endDate"
                                label="End Date"
                                required
                            />
                        </Box>
                    </Box>
                    <Card sx={{
                        mt: currentUser.mobile ? 2 : 0,
                        ml: currentUser.mobile ? 0 : 2,
                        bgcolor: 'grey.100',
                        boxShadow: 1,
                        borderRadius: 2,
                        width: currentUser.mobile ? '100%' : '100%',
                        height: '300px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <CardHeader
                            sx={{
                                bgcolor: 'grey.400',
                                p: 1,
                                flexShrink: 0,
                            }}
                            title={
                                <Typography variant="h6">
                                    Storage Crop History
                                </Typography>
                            }
                        />
                        <CardContent sx={{
                            p: 2,
                            overflow: 'hidden',
                            flex: 1,
                            display: 'flex',
                            '&:last-child': {
                                pb: 2
                            }
                        }}>
                            <Box sx={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                flex: 1,
                                '&::-webkit-scrollbar': {
                                    width: '8px'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'grey.400',
                                    borderRadius: '4px'
                                }
                            }} >
                                {cropHistory?.map((crop, index) => {
                                    return (
                                        <Card
                                            key={index}
                                            sx={{
                                                mt: 1,
                                                '&:hover': {
                                                    bgcolor: 'grey.50',
                                                    transform: 'translateX(4px)',
                                                },
                                            }}>
                                            <Box display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                p={1.5}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontWeight: 500,
                                                        color: 'text.primary',
                                                    }}
                                                >
                                                    {crop.crop}: {formatedDate(crop.startDate)} - {formatedDate(crop.endDate)}
                                                </Typography>
                                                <CardActions sx={{ p: 0 }}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => removeAssessCrop(crop.id)}
                                                        sx={{
                                                            color: 'error.light',
                                                            '&:hover': {
                                                                bgcolor: 'error.lighter',
                                                                color: 'error.main',
                                                            },
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </CardActions>
                                            </Box>
                                        </Card>
                                    )
                                })}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addCropHistory}
                        startIcon={<AddIcon />}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 3,
                        }}
                    >Add</Button>
                </CardActions>
            </CardContent>
        </Card >
    </>)
}

export default LocationCropCard;