import React, { useContext, useEffect } from "react";
import {
    TextField, Box, Tooltip, IconButton,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import useStorageAssessmentStore from "../../../stores/storageAssessmentStore";
import SearchableInput from "../../SheetComponents/SearchableInput"
import useFields from "../../../Hooks/useFields";
import UserContext from "../../../Users/UserContext";

function StorageAssessPart({ partType, part, parts, onPartChange, edit }) {
    const { currentUser } = useContext(UserContext);
    const [localPart, setLocalPart] = useFields({
        partNumber: "",
        additionalData: {}
    });
    const [removeLine] = useStorageAssessmentStore(state => [state.removeLine]);

    useEffect(() => {
        if (edit) setLocalPart("replaceObj", part);
    }, [])

    useEffect(() => {
        // Notify parent of changes without updating global state
        onPartChange(partType, part.indexId, localPart);
    }, [localPart, removeLine]);

    const handleRemoveLine = () => {
        removeLine(partType, part.indexId);
        onPartChange(partType, part.indexId, null);
    };

    return (
        <Box ml={3} mt={1} mb={1}>
            <TextField
                name="qty"
                id="qty"
                label="Quantity"
                variant="outlined"
                type="number"
                sx={{ width: "10ch" }}
                value={localPart.qty || ""}
                onChange={setLocalPart}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Box ml={1} style={{ display: 'inline' }}>
                <SearchableInput
                    edit={true}
                    editData={part.partNumber}
                    setState={setLocalPart}
                    searchableArray={parts}
                    label={"Part Number"}
                    variableName={"partNumber"}
                    fullwidth={false} />
            </Box>
            <TextField
                name="description"
                id="description"
                label="Description"
                variant={localPart.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                sx={{ width: currentUser.mobile ? '80%' : '45ch', mt: currentUser.mobile ? 1 : 0, ml: 1 }}
                value={localPart.description || ""}
                onChange={setLocalPart}
                InputProps={{
                    readOnly: localPart.partNumber !== 'SERPART'
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Tooltip title="Remove line">
                <IconButton onClick={handleRemoveLine} sx={{ mt: 1 }}>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default StorageAssessPart;