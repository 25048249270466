import React from "react";
import {
    Dialog, DialogTitle, DialogActions, Button,
} from "@mui/material";
import useSettingsStore from "../../stores/settingsStore";
import { useHistory } from "react-router-dom";

function ChemAppFormPopUp({ open, setOpen, id, disinfectant, sprayBar, }) {
    const history = useHistory();
    const [home] = useSettingsStore((state) => [state.home])

    const redirectUser = function (userChoise) {
        if (userChoise) {
            let type = 'chemApps';
            if (sprayBar) type = 'sprayBar';
            else if (disinfectant) type = 'disinfectant';
            return history.push(`/${type}/form/edit/${id}?source=popup`)
        }
        return history.push(home)
    }
    return (<>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Redirect to Form?</DialogTitle>
            <DialogActions>
                <Button onClick={() => redirectUser(true)}>Yes</Button>
                <Button onClick={() => redirectUser(false)}>No</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default ChemAppFormPopUp;