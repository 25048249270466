import axios from "axios";

let BASE_URL;

if (process.env.NODE_ENV == "production") {
  BASE_URL = "https://sheet5.azurewebsites.net"
} else {
  BASE_URL = "http://localhost:3001"
  //http://192.168.10.219:3001/
  //To debug via android change localhost to ip address
}

/** API Class.
 *
 * Static class tying together methods used to get/send to to the API.
 *
 */

class SheetsApi {
  // the token for interactive with the API will be stored here.
  static token;
  static async request(endpoint, data = {}, method = "get") {
    const backUpToken = localStorage.getItem("token")
    // console.debug("API Call:", endpoint, data, method);
    const url = `${BASE_URL}/${endpoint}`;
    let headers = { Authorization: `Bearer ${SheetsApi.token || backUpToken}` };
    if (data instanceof FormData) {
      // When making an api call with FormData, change the content type
      // This is used with photo uploads and signaures
      headers = {
        ...headers,
        'Content-Type': 'multipart/form-data',
      };
    }
    const params = (method === "get") ? data : {};
    try {
      const response = (await axios({ url, method, data, params, headers })).data
      return response
    } catch (err) {
      console.error(err)
      let message = err.response.data.error;
      message.error = true;
      console.log(message);
      return message;
    }
  }

  // Individual API routes

  static async login(data) {
    data.isLogin = true
    let res = await this.request(`users/login`, data, "post")
    return res
  }
  /** Get the current user. */
  static async getCurrentUser(username) {
    const data = { isLogin: true }
    let res = await this.request(`users/${username}`, data);
    return res
  }
  //gets all users
  static async getInactive() {
    let res = await this.request(`users/inactive`)
    return res.users
  }
  static async getAllUsers() {
    let res = await this.request(`users`)
    return res.users
  }
  //registers a new user
  static async register(data) {
    let res = await this.request(`users/register`, data, "post");
    return res;
  }
  //patches the user account level
  static async updateUser(username) {
    let res = await this.request(`users`, username, `patch`)
    return res
  }

  static async passwordResetRequest(username) {
    let res = await this.request(`users/passwordResetRequest`, username, 'patch')
    return res
  }
  static async passwordReset(data) {
    data.isLogin = true
    let res = await this.request(`users/passwordReset`, data, `patch`)
    return res
  }
  // home page
  static async getSheets(company) {
    let res = await this.request(`home/getSheets`, company)
    return res.sheets
  }
  static async batchUpdateStage(sheets) {
    let res = await this.request(`home/batchUpdateStage`, sheets, 'patch')
    return res
  }
  /** Adds a company . */
  static async addCompany(company) {
    let res = await this.request(`companies`, company, `post`)
    return res
  }
  //gets companies based on search term
  static async getCompaniesByFilter(searchTerm) {
    let res = await this.request(`companies`, searchTerm)
    return res.companies
  }
  //gets all companies
  static async getAllCompanies() {
    let res = await this.request(`companies`);
    return res
  }
  //gets all customers with a filter
  static async getAllCustomersByCompany(company) {
    let res = await this.request(`customers`, company)
    return res.customers
  }
  static async getCustomer(customer) {
    let res = await this.request(`customers/get`, customer)
    return res.customer
  }
  static async getAllCustomers() {
    let res = await this.request(`customers/all`)
    return res.customers
  }
  //gets all customers based on search term
  static async getCustomersByFilter2(data) {
    let res = await this.request(`customers/find`, data)
    return res.customers
  }
  static async updateWatchList(data) {
    let res = await this.request(`customers/watch`, data, 'patch')
    return res
  }
  static async getHistory(data) {
    let res = await this.request(`customers/history`, data)
    return res.history
  }
  static async updateCustomer(data) {
    let res = await this.request(`customers/update`, data, "patch")
    return res
  }
  // locations and assessment
  static async getActiveLocations(customer) {
    let res = await this.request(`locations`, customer)
    return res.locations
  }
  static async getAllLocations(customer) {
    let res = await this.request(`locations/getAll`, customer)
    return res.locations
  }
  static async addLocation(data) {
    let res = await this.request(`locations`, data, "post")
    return res
  }
  static async getLocationName(id) {
    let res = await this.request(`locations/getName`, id, "get")
    return res
  }
  static async getLocationData(locationName) {
    let res = await this.request(`locations/getData`, locationName, "get");
    return res;
  }
  static async updateBasicSettings(locationObj) {
    let res = await this.request(`locations/updateBasicSettings`, locationObj, "patch");
    return res;
  }
  static async updateLocation(locationObj) {
    let res = await this.request(`locations`, locationObj, "patch");
    return res;
  }
  static async getAssessmentCrop(obj) {
    let res = await this.request(`locations/getAssessmentCrop`, obj, "get")
    return res;
  }
  static async addAssessmentCrop(obj) {
    let res = await this.request(`locations/addAssessmentCrop`, obj, "patch")
    return res;
  }
  static async removeAssessmentCrop(obj) {
    let res = await this.request(`locations/removeAssessmentCrop`, obj, "patch")
    return res;
  }
  static async getVarieties() {
    let res = await this.request(`locations/getVarieties`)
    return res;
  }
  static async saveLocationSettings(locationObj) {
    let res = await this.request(`locations/save`, locationObj, "patch")
    return res;
  }
  static async mergeLocation(data) {
    let res = await this.request(`locations/merge`, data, "patch")
    return res
  }
  static async addCustomer(data) {
    let res = await this.request(`customers`, data, "post")
    return res
  }
  // Parts
  static async getAllActiveParts() {
    let res = await this.request(`parts`)
    return res.part
  }
  static async getAllParts() {
    let res = await this.request(`parts/all`)
    return res.part
  }
  static async getBidPartsByCategory(category) {
    let res = await this.request(`parts/bids`, category)
    return res.parts
  }
  //gets parts based on sheet type and ID
  static async getPartsById(data) {
    let res = await this.request(`parts/id`, data)
    return res.parts
  }
  static async getPartsCategories() {
    let res = await this.request(`parts/categories`)
    return res
  }
  static async addPart(data) {
    let res = await this.request(`parts`, data, 'post')
    return res
  }
  static async updatePart(data) {
    let res = await this.request(`parts`, data, 'patch')
    return res
  }
  static async uploadParts(data) {
    let res = await this.request(`parts/upload`, data, 'patch')
    return res
  }
  static async getTechsById(data) {
    let res = await this.request(`technicians/id`, data)
    return res.technician
  }
  static async getByCompanyAndStage(data) {
    let res = await this.request(`sheets`, data)
    return res.sheets
  }
  //sheets
  static async getSheetsByFilter(data) {
    let res = await this.request(`sheets/filter`, data)
    return res
  }
  //adds a service sheet to the db
  static async addServiceSheet(serviceSheet) {
    let res = await this.request(`sheets`, serviceSheet, "post")
    return res
  }
  static async editServiceSheet(serviceSheet) {
    let res = await this.request(`sheets`, serviceSheet, "patch")
    return res
  }
  static async getServiceSheet(id) {
    let res = await this.request(`sheets/edit`, id)
    return res.sheet
  }
  //adds a preSeason  sheet to the db
  static async addPreSeasonSheet(preSeasonSheet) {
    let res = await this.request(`preseason`, preSeasonSheet, "post")
    return res
  }
  static async editPreSeasonSheet(preSeasonSheet) {
    let res = await this.request(`preseason`, preSeasonSheet, "patch")
    return res
  }
  static async getPreSeasonSheet(id) {
    let res = await this.request(`preseason/edit`, id)
    return res.sheet
  }
  //adds a ref sheet to the db
  static async addRefrigerationSheet(refSheet) {
    let res = await this.request(`refrigeration`, refSheet, "post")
    return res
  }
  static async getRefrigerationSheet(id) {
    let res = await this.request(`refrigeration/edit`, id)
    return res.sheet
  }
  static async editRefrigerationSheet(refSheet) {
    let res = await this.request(`refrigeration`, refSheet, "patch")
    return res
  }
  static async getCompressors(id) {
    let res = await this.request(`refrigeration/compressors`, id)
    return res.compressors
  }
  //Sales Order 
  static async addSalesOrder(salesOrder) {
    let res = await this.request(`salesOrder`, salesOrder, 'post')
    return res
  }
  static async getSalesOrder(id) {
    let res = await this.request(`salesOrder/edit`, id)
    return res.sheet
  }
  static async editSalesOrderSheet(salesOrder) {
    let res = await this.request(`salesOrder`, salesOrder, 'patch')
    return res
  }
  //Inspection Sheet
  static async addInspectionSheet(inspectionSheet) {
    let res = await this.request('inspection', inspectionSheet, 'post')
    return res
  }
  static async getInspectionSheet(id) {
    let res = await this.request('inspection/edit', id)
    return res.sheet
  }
  static async getBays(id) {
    let res = await this.request('inspection/bays', id)
    return res.bays
  }
  static async editInspectionSheet(inspectionSheet) {
    let res = await this.request('inspection', inspectionSheet, 'patch')
    return res
  }
  static async getRecentInspection(data) {
    let res = await this.request('inspection/recent', data, 'get')
    return res;
  }
  //labs
  static async getLabDataByDate(data) {
    let res = await this.request('labs', data)
    return res
  }
  static async saveLabData(data) {
    let res = await this.request('labs', data, 'patch');
    return res;
  }
  static async addEntry(data) {
    let res = await this.request('labs', data, 'post');
    return res;
  }
  //chem App Agreements
  static async addChemAppAgreement(chemAppAgreemnet) {
    let res = await this.request('chemApps', chemAppAgreemnet, 'post')
    return res
  }
  static async getActiveChemApps(data) {
    let res = await this.request('chemApps', data);
    return res.sheets
  }
  static async getAgreement(id) {
    let res = await this.request('ChemApps/agreement/edit', id);
    return res
  }
  static async editChemAppAgreement(chemAppAgreement) {
    let res = await this.request('ChemApps/agreement', chemAppAgreement, 'patch');
    return res
  }
  // chem App forms
  static async getChemAppForm(id) {
    let res = await this.request('chemApps/form/edit', id);
    return res.sheet
  }
  static async editChemAppForm(chemAppObj) {
    let res = await this.request('chemApps/form/edit', chemAppObj, "patch");
    return res
  }
  static async getChemicals() {
    let res = await this.request('chemApps/getChemicals');
    return res.chemicalList;
  }
  static async editChemAppAgreementStage(sheet) {
    let res = await this.request('chemApps/agreement/stage', sheet, 'patch');
    return res.sheet
  }
  //bids
  static async addBid(sheet) {
    let res = await this.request('bids', sheet, 'post');
    return res
  }
  static async getBidSheet(id) {
    let res = await this.request('bids/edit', id);
    return res.sheet
  }
  static async updateBid(bid) {
    let res = await this.request('bids/edit', bid, "patch");
    return res
  }
  static async copyBid(id) {
    let res = await this.request('bids/copy', id, "post");
    return res
  }
  //bid refrigeration
  static async addBidRef(sheet) {
    let res = await this.request('bidRef', sheet, "post");
    return res;
  }
  static async getBidRefSheet(id) {
    let res = await this.request('bidRef/edit', id,)
    return res
  }
  static async updateBidRef(bid) {
    let res = await this.request('bidRef/edit', bid, "patch")
    return res;
  }
  //freshPack
  static async addFreshPack(sheet) {
    let res = await this.request('freshPack', sheet, "post");
    return res;
  }
  static async getFreshPackById(id) {
    let res = await this.request('freshPack/edit', id);
    return res;
  }
  static async updateFreshPack(sheet) {
    let res = await this.request('freshPack/edit', sheet, 'patch');
    return res;
  }
  static async getRecentFreshPack(data) {
    let res = await this.request('freshPack/getRecent', data);
    return res;
  }
  //ChemApp service sheet
  static async addChemAppServiceSheet(sheet) {
    let res = await this.request('chemAppService', sheet, 'post');
    return res
  }
  static async getChemAppServiceSheet(id) {
    let res = await this.request('chemAppService/edit', id);
    return res.sheet
  }
  static async updateChemAppServiceSheet(sheet) {
    let res = await this.request('chemAppService/edit', sheet, 'patch');
    return res
  }
  // images
  static async uploadSignature(data) {
    let res = await this.request('images', data, "post");
    return res
  }
  static async uploadImage(data) {
    let res = await this.request('images', data, "post");
    return res
  }
  static async getImages(data) {
    let res = await this.request('images', data)
    return res
  }
  static async getLocalImages(id) {
    let res = await this.request('images/local', id);
    return res.images
  }
  static async deleteImage(data) {
    let res = await this.request('images', data, 'delete');
    return res
  }
  // receipts
  static async updateReceipt(data) {
    let res = await this.request('images/receipt', data, 'patch');
    return res
  }
  static async getFilteredReceipts(data) {
    let res = await this.request('images/receipt', data, 'get');
    return res
  }
  static async changeStage(data) {
    let res = await this.request('images/receipt/stage', data, 'patch');
    return res;
  }
  // settings
  static async saveSettings(data) {
    let res = await this.request('settings', data, 'patch');
    return res;
  }
  static async getSettings() {
    let res = await this.request('settings');
    return res.settings;
  }
  // notifications
  static async getNotifications(data) {
    let res = await this.request('home/getNotifications', data);
    return res.notificationsResponse;
  }
  static async removeNotification(data) {
    let res = await this.request('home/removeNotification', data, 'delete');
    return res.notificationsResponse;
  }
  static async getSalesNotifications(data) {
    let res = await this.request('home/salesNotifications', data);
    return res
  }
  static async getSalesNotificationLength(data) {
    let res = await this.request(`home/salesNotifications/length`, data);
    return res;
  }
  static async removeSalesNotification(data) {
    let res = await this.request('home/removeSalesNotification', data, 'delete');
    return res;
  }
  static async getLogs(data) {
    let res = await this.request('home/getLogs', data);
    return res
  }
  static async postLogMessage(data) {
    let res = await this.request('home/postLogMessage', data, 'post');
    return res;
  }
  static async addNotification(data) {
    let res = await this.request('home/addNotification', data, 'post');
    return res;
  }
  //Calendar
  static async getCal() {
    let res = await this.request('home/getCal');
    return res;
  }
  static async addCal(data) {
    let res = await this.request('home/addCal', data, 'post');
    return res
  }
  static async removeCal(data) {
    let res = await this.request('home/removeCal', data, 'delete');
    return res
  }
  static async getCalToken() {
    let res = await this.request('home/getCalToken')
    return res;
  }
}

export default SheetsApi;
