import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    table: {
        width: '100%',
        marginBottom: 15,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e2e8f0',
        minHeight: 25,
        alignItems: 'center',
    },
    tableHeader: {
        backgroundColor: '#f6e3c3',
        fontWeight: 'bold',
    },
    tableCell: {
        padding: 5,
        fontSize: 10,
    },
    smallCell: {
        flex: 0.1, // 10% width
    },
    partNumberCell: {
        flex: 0.3, // 30% width
    },
    descriptionCell: {
        flex: 0.4, // 40% width
    },
    defaultCell: {
        flex: 0.2, // 20% width for other cells
    },
});

function storageAssessPrintOtherTable({ data }) {

    const getTableHeaders = () => {
        const firstItem = data[0];
        if ('doorType' in firstItem) {
            return ['Qty', 'Door Type', 'Hinge Type', 'Width', 'Height'];
        } else if ('partNumber' in firstItem) {
            return ['Qty', 'Part Number', 'Description', 'Width', 'Height'];
        } else if ('width' in firstItem && !('partNumber' in firstItem)) {
            return ['Qty', 'Width', 'Height'];
        }
        return [];
    };

    const renderTableCell = (item, header) => {
        const mapping = {
            'Qty': item.qty,
            'Door Type': item.doorType,
            'Hinge Type': item.hingeType,
            'Part Number': item.partNumber,
            'Description': item.description,
            'Width': item.width,
            'Height': item.height,
        };
        return mapping[header] || '';
    };

    const getCellStyle = (header) => {
        switch (header) {
            case 'Qty':
            case 'Width':
            case 'Height':
                return [styles.tableCell, styles.smallCell];
            case 'Part Number':
                return [styles.tableCell, styles.partNumberCell];
            case 'Description':
                return [styles.tableCell, styles.descriptionCell];
            default:
                return [styles.tableCell, styles.defaultCell];
        }
    };

    const headers = getTableHeaders();

    return (
        <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
                {headers.map((header, index) => (
                    <Text key={index} style={getCellStyle(header)}>{header}</Text>
                ))}
            </View>
            {data.map((item, rowIndex) => (
                <View key={rowIndex} style={styles.tableRow}>
                    {headers.map((header, cellIndex) => (
                        <Text key={cellIndex} style={getCellStyle(header)}>
                            {renderTableCell(item, header)}
                        </Text>
                    ))}
                </View>
            ))}
        </View>
    );
}

export default storageAssessPrintOtherTable;