import React, { useContext, useEffect, useState } from "react";
import {
    TextField, Box, Tooltip, IconButton, Card, CardContent,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import useStorageAssessmentStore from "../../../stores/storageAssessmentStore";
import SearchableInut from "../../SheetComponents/SearchableInput"
import useFields from "../../../Hooks/useFields";
import UserContext from "../../../Users/UserContext";
function StorageAssessFan({ partType, part, parts, edit }) {
    const [localPart, setLocalPart] = useFields({
        partNumber: "",
        fanPercent: 100,
        additionalData: { cfm: "" }
    });
    const { currentUser } = useContext(UserContext);
    const removeLine = useStorageAssessmentStore(state => state.removeLine);
    const staticPressure = useStorageAssessmentStore(state => state.storageAssessmentHead.staticPressure);
    const [staticPressureValue, setStaticPressureValue] = useState("staticPressure125")
    const calculateTotals = useStorageAssessmentStore(state => state.calculateTotals);
    const updatePart = useStorageAssessmentStore(state => state.updatePart);

    useEffect(() => {
        if (staticPressure === .5) setStaticPressureValue("staticPressure05")
        else if (staticPressure === .75) setStaticPressureValue("staticPressure075")
        else if (staticPressure === 1) setStaticPressureValue("staticPressure1")
        else if (staticPressure === 1.25) setStaticPressureValue("staticPressure125")
        else if (staticPressure === 1.5) setStaticPressureValue("staticPressure15")
        else if (staticPressure === 1.75) setStaticPressureValue("staticPressure175")
        else setStaticPressureValue("staticPressure125")
    }, [staticPressure]);

    useEffect(() => {
        updatePart(partType, part.indexId, localPart);
        if (part.indexId !== undefined) {
            calculateTotals(partType);
            calculateTotals("totalSqFtReq");
        }
    }, [localPart, staticPressureValue]);

    useEffect(() => {
        // Refactor the part to have additionalData key then set local data
        if (edit) {
            let improvedPart = { ...part }
            if (part.partNumber !== 'SERPART') {
                const foundFanData = parts?.find((fan) => fan.partNumber === part.partNumber)
                improvedPart.additionalData = foundFanData?.additionalData;
            } else improvedPart.additionalData = { cfm: part.cfm }
            setLocalPart("replaceObj", improvedPart);
        }
    }, [])

    const handleCfmChange = (e) => {
        if (localPart.partNumber === 'SERPART') {
            setLocalPart("replaceObj", {
                ...localPart,
                additionalData: {
                    ...localPart.additionalData,
                    cfm: e.target.value
                }
            });
        }
    };

    return (
        <Card sx={{
            mt: 1,
            borderRadius: 2,
            display: 'inline-flex',
            width: 'fit-content',
            maxWidth: 'fit-content',
            position: currentUser.mobile ? 'relative' : 'static',
        }}>
            <CardContent sx={{
                '&:last-child': {
                    pb: 2
                },
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: 1
            }}>
                <TextField
                    name="qty"
                    id="qty"
                    label="Quantity"
                    variant="outlined"
                    type="number"
                    sx={{ width: "10ch" }}
                    value={localPart.qty || ""}
                    onChange={setLocalPart}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <Box ml={1} style={{ display: 'inline' }}>
                    <SearchableInut
                        edit={true}
                        editData={part.partNumber}
                        setState={setLocalPart}
                        searchableArray={parts}
                        label={"Part Number"}
                        variableName={"partNumber"}
                        fullwidth={false} />
                </Box>
                <TextField
                    name="description"
                    id="description"
                    label="Description"
                    variant={localPart.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                    sx={{ width: localPart.partNumber === 'SERPART' ? '27ch' : '29ch', ml: 1 }}
                    value={localPart.description || ""}
                    onChange={setLocalPart}
                    InputProps={{
                        readOnly: localPart.partNumber !== 'SERPART'
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                {partType === 'fan' && <>
                    <TextField
                        name="cfm"
                        id="cfm"
                        label="CFM"
                        type="number"
                        variant={localPart.partNumber === 'SERPART' ? 'outlined' : 'standard'}
                        sx={{ width: "14ch", ml: localPart.partNumber === 'SERPART' ? 1 : 0 }}
                        value={localPart.partNumber === 'SERPART'
                            ? (localPart?.additionalData?.cfm || "")
                            : (localPart?.additionalData?.[staticPressureValue] || "")}
                        onChange={localPart.partNumber === 'SERPART' ? handleCfmChange : null}
                        InputProps={{
                            readOnly: localPart.partNumber !== 'SERPART'
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        name="totalCfm"
                        id="totalCfm"
                        label="Total CFM"
                        type="number"
                        variant="standard"
                        sx={{ ml: localPart.partNumber === "SERPART" && 1, width: "14ch" }}
                        value={localPart.partNumber !== "SERPART"
                            ? (localPart?.qty || 0) * ((localPart?.additionalData?.[staticPressureValue] || 0) * ((localPart?.fanPercent || 100) / 100))
                            : (localPart?.qty || 0) * ((localPart?.additionalData?.cfm || 0) * ((localPart?.fanPercent || 100) / 100))}
                        InputProps={{
                            readOnly: true
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        name="fanPercent"
                        id="fanPercent"
                        label="% Fan Speed"
                        type="number"
                        sx={{ ml: 1, width: "12ch" }}
                        value={localPart.fanPercent || ""}
                        onChange={setLocalPart}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            endAdornment: <span>%</span>
                        }}
                    />
                </>}
                <Tooltip title="Remove line">
                    <IconButton
                        onClick={() => removeLine(partType, part.indexId)}
                        sx={currentUser.mobile ? {
                            position: 'absolute',
                            bottom: 20,
                            right: 20
                        } : {
                            mt: 1
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {localPart.partNumber === 'SERPART' &&
                    <Box sx={{ flexBasis: '100%', mt: 1 }}>
                        <TextField
                            name="pitch"
                            id="pitch"
                            label="Pitch"
                            sx={{ width: '10ch', ml: 1 }}
                            value={localPart.pitch || ""}
                            onChange={setLocalPart}
                        />
                        <TextField
                            name="hp"
                            id="hp"
                            label="HP"
                            sx={{ width: '10ch', ml: 1 }}
                            value={localPart.hp || ""}
                            onChange={setLocalPart}
                        />
                        <TextField
                            name="fanSize"
                            id="fanSize"
                            label="Fan Size"
                            sx={{ width: '14ch', ml: 1 }}
                            value={localPart.fanSize || ""}
                            onChange={setLocalPart}
                        />
                    </Box>
                }
            </CardContent>
        </Card >
    );
}

export default StorageAssessFan;