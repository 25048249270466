import React, { useContext } from "react";
import { Typography, Card, CardContent, Box, } from "@mui/material";
import UserContext from "../../Users/UserContext";

function StorageAssessAirflow({ pageData, getVarianceColor }) {
    const { currentUser } = useContext(UserContext);
    return (
        <Card sx={{ borderRadius: 3, boxShadow: 3, height: '100%' }}>
            <CardContent>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Airflow Analysis
                </Typography>
                <Box display={currentUser.mobile ? "block" : "flex"} justifyContent="space-evenly" >
                    <Box>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Plenum Measurements
                        </Typography>
                        <Box sx={{ ml: 2, mb: 2 }}>
                            <Typography variant="body1">
                                Total Sq Ft Required: {pageData.totalSqFtReq || '0'}
                            </Typography>
                            <Typography variant="body1">
                                Total Plenum Sq Ft: {pageData.plenTotalSqFt || '0'}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getVarianceColor(pageData.plenVariance),
                                fontWeight: 'bold'
                            }}>
                                Plenum Variance: {pageData.plenVariance || '0'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Duct Measurements
                        </Typography>
                        <Box sx={{ ml: 2, mb: 2 }}>
                            <Typography variant="body1">
                                Total Sq Ft Required: {pageData.totalSqFtReq || '0'}
                            </Typography>
                            <Typography variant="body1">
                                Total Duct Sq Ft: {pageData.ductTotalSqFt || '0'}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getVarianceColor(pageData.ductVariance),
                                fontWeight: 'bold'
                            }}>
                                Duct Variance: {pageData.ductVariance || '0'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

export default StorageAssessAirflow;