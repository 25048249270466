import React, { useContext } from 'react';
import {
    Collapse, Card, CardHeader, CardContent,
    Typography, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStorageAssessmentStore from '../../../stores/storageAssessmentStore';
import UserContext from '../../../Users/UserContext';
function PartCollapseCard({ title, parts, partType, children }) {
    const [addPart] = useStorageAssessmentStore(state => [state.addPart]);
    const { currentUser } = useContext(UserContext);

    const onAddClick = () => {
        addPart(partType);
    }

    return (
        <Card sx={{
            mb: 2,
            bgcolor: 'background.default',
            boxShadow: 1,
            borderRadius: 2,
            width: 'fit-content',
            minWidth: currentUser.mobile ? 'atuo' : '700px',
        }}>
            <CardHeader
                sx={{
                    bgcolor: 'grey.100',
                    p: 1,
                }}
                title={
                    <Typography variant="h6">
                        {title} ({parts?.length})
                    </Typography>
                }
                action={
                    <IconButton
                        sx={{ mt: 1, mr: 2 }}
                        onClick={onAddClick}
                        size="small"
                        aria-label={`Add ${title}`}
                    >
                        <AddIcon />
                    </IconButton>
                }
            />
            <Collapse in={parts?.length > 0}>
                <CardContent sx={{ p: 2 }}>
                    {children}
                </CardContent>
            </Collapse>
        </Card >
    );
}

export default PartCollapseCard;