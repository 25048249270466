import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Routes from "./Routes-Nav/Routes"
import SheetsApi from './api';
import UserContext from "./Users/UserContext"
import useLocalStorage from './Hooks/useLocalStorage';
import { decodeToken } from "react-jwt"
import { useHistory } from 'react-router-dom';
import Grid from "@mui/material/Grid"
import useSettingsStore from './stores/settingsStore';
import { SnackbarProvider } from 'notistack'
import DownloadCard from './Notifications/DownloadCard';
import DrawerComponent from './Routes-Nav/Navigation/DrawerComponent';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from './Home/SalesDashboard/Calendar/CalendarAuthConfig';

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const theme = createTheme({
    palette: {
      primary: {
        main: '#940000', // red
        alter: '#173c7c' // blue
      },
      secondary: {
        main: '#a8353a', //light red
        alter: '#004e7c', //beta blue
        alter2: '#EAA221' //Yelow gold
      },
      default: {
        main: '#1e2124', //black
        secondary: '#b5bac1' //gray
      },
      background: {
        main: '#C0C0C0', // light gray 
        default: '#f0f0f0' // lighter gray
      },
      success: {
        main: '#b0e0e6'
      },
      error: {
        main: '#e6301c'
      }
    },
  })
  const TOKEN_ID = "token"
  const [token, setToken] = useLocalStorage(TOKEN_ID)
  const [currentUser, setCurrentUser] = useState(null);
  const history = useHistory()
  const [createSettings] = useSettingsStore(state => (
    [state.createSettings]));

  function handleLogout() {
    setToken(null)
    setCurrentUser(null)
    history.push("/login")
  }

  useEffect(function loadUserInfo() {
    async function getCurrentUser() {
      if (token) {
        try {
          let myDecodedToken = decodeToken(token)
          if (myDecodedToken.inactive) {
            console.log("inactive user tried to login.")
            return handleLogout()
          }
          // put the token on the Api class so it can use it to call the API.
          SheetsApi.token = token;
          let theCurrentUser = await SheetsApi.getCurrentUser(myDecodedToken.username);
          theCurrentUser.appSettings = {}
          await createSettings(theCurrentUser);
          // Check if user is on mobile or not
          if (window.innerWidth < 500) {
            theCurrentUser.mobile = true;
          } else {
            theCurrentUser.mobile = false;
          }
          // Check the user's operating system
          const userAgent = navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('android') > -1) {
            theCurrentUser.os = 'android';
          } else if (userAgent.indexOf('windows') > -1) {
            theCurrentUser.os = 'windows';
          } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
            theCurrentUser.os = 'ios';
          } else if (userAgent.indexOf('macintosh') > -1) {
            theCurrentUser.os = 'macintosh';
          } else {
            theCurrentUser.os = 'unknown';
          }
          setCurrentUser(theCurrentUser);
        } catch (err) {
          console.error("App loadUserInfo: problem loading", err);
          setCurrentUser(null);
        }
      } else {
        return handleLogout()
      }
    }
    getCurrentUser();
  }, [token]);

  return (
    <div className="App">
      <SnackbarProvider
        Components={{
          downloadCard: DownloadCard
        }}
        autoHideDuration={9000}
        dense={true}
      >
        <ThemeProvider theme={theme}>
          <UserContext.Provider value={{ currentUser, setCurrentUser }}>
            <MsalProvider instance={msalInstance}>
              <Grid>
                {currentUser &&
                  <div className='hideForPrint' >
                    <DrawerComponent currentUser={currentUser} logout={handleLogout} />
                  </div>
                }
                <Routes setToken={setToken} logout={handleLogout} />
              </Grid>
            </MsalProvider>
          </UserContext.Provider>
        </ThemeProvider>
      </SnackbarProvider>
    </div >
  );
}

export default App;
