import React, { useEffect } from "react";
import {
    Box, TextField, InputAdornment, FormControl,
    InputLabel, Select, MenuItem,
} from "@mui/material";
import useFields from "../../Hooks/useFields";
import useBayStore from "../../stores/bayStore";

function InspectionEtc({ edit }) {
    const INITIAL_STATE = {
        plenumSetPoint: "",
        systemMode: "",
        vfdFan: "",
        osaControl: "",
        osaTemp: "",
        osaHumidity: "",
    }
    const [formData, handleLocal] = useFields(INITIAL_STATE);
    const [inspectionEtc, setInspectionEtc] = useBayStore(state =>
        [state.inspectionEtc, state.setInspectionEtc])

    useEffect(() => {
        if (edit) {
            handleLocal("replaceObj", inspectionEtc)
        }
    }, [])
    const handleChange = function (e) {
        setInspectionEtc(e.target.name, e.target.value);
    }

    return (
        <>
            <Box >
                <Box ml={1} mb={1} style={{ display: 'inline-block' }}>
                    <InputLabel htmlFor="plenumSetPoint">
                        Plenum Set Point
                    </InputLabel>
                    <TextField
                        name="plenumSetPoint"
                        id="plenumSetPoint"
                        variant="outlined"
                        type="number"
                        sx={{ width: "16ch" }}
                        value={formData.plenumSetPoint || ""}
                        onChange={(e) => handleLocal(e)}
                        onBlur={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                °F</InputAdornment>,
                        }}
                    />
                </Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <TextField
                        name="vfdFan"
                        id="vfdFan"
                        label="VFD Fan Speed"
                        varient="outlined"
                        type="text"
                        sx={{ width: "20ch" }}
                        value={formData.vfdFan || ""}
                        onChange={(e) => handleLocal(e)}
                        onBlur={(e) => handleChange(e)}
                    />
                </Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <InputLabel htmlFor="osaControl">
                        OSA Control
                    </InputLabel>
                    <TextField
                        name="osaControl"
                        id="osaControl"
                        vairent="outlined"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.osaControl || ""}
                        onChange={(e) => handleLocal(e)}
                        onBlur={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                °F</InputAdornment>,
                        }}
                    />
                </Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <InputLabel htmlFor="osaTemp">
                        OSA Temp
                    </InputLabel>
                    <TextField
                        name="osaTemp"
                        id="osaTemp"
                        vairent="outlined"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.osaTemp || ""}
                        onChange={(e) => handleLocal(e)}
                        onBlur={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                °F</InputAdornment>,
                        }}
                    />
                </Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <InputLabel htmlFor="osaHumidity">
                        OSA Humidity
                    </InputLabel>
                    <TextField
                        name="osaHumidity"
                        id="osaHumidity"
                        vairent="outlined"
                        type="number"
                        sx={{ width: "12ch" }}
                        value={formData.osaHumidity || ""}
                        onChange={(e) => handleLocal(e)}
                        onBlur={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                %</InputAdornment>,
                        }}
                    />
                </Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <InputLabel htmlFor="co2">
                        CO₂
                    </InputLabel>
                    <TextField
                        name="co2"
                        id="co2"
                        vairent="outlined"
                        type="number"
                        sx={{ width: "16ch" }}
                        value={formData.co2 || ""}
                        onChange={(e) => handleLocal(e)}
                        onBlur={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                ppm</InputAdornment>,
                        }}
                    />
                </Box>
            </Box>
            <Box>
                <Box mb={1} ml={1} style={{ display: 'inline-block' }} >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>
                            System Mode
                        </InputLabel>
                        <Select
                            label="System Mode ex"
                            sx={{ width: '20ch', fontSize: '0.8rem' }}
                            id="systemMode"
                            name="systemMode"
                            value={formData.systemMode || ''}
                            onChange={handleLocal}
                            labelId="systemModeLabel"
                            onBlur={(e) => handleChange(e)}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="recirculation">Recirculation</MenuItem>
                            <MenuItem value="cooling">Cooling</MenuItem>
                            <MenuItem value="refrigeration">Refrigeration</MenuItem>
                            <MenuItem value="standby">Stand By</MenuItem>
                            <MenuItem value="shutdown">Shut Down</MenuItem>
                        </Select>
                    </FormControl>
                </Box>                
            </Box>
        </>
    )
}

export default InspectionEtc